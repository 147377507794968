import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getalltripsheets, deletetripsheet, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import PreparingDownload from '../../core/helper/PreparingDownload';
import LoadsTableView from '../../core/helper/TableViews/LoadsTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../AdminSideSheet.css';
import { 
    getTruckNo_2, getTrailerNo_2, getDriverName, getPickupCompany_2, getDeliveryLocation, getPickupLocation, getPickupCommodity,
    getWaitingPeriod, getTotalWaitingPeriod, getBillableWaitingPeriod, getNetUnit, getRatePerUnit, getLoadSubTotal, getFuelAmount 

} from '../../core/helper/helperFunctions';
import Logo from "../../images/ttms_logo_2.jpg";
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";

export default function ViewLoads() {

    const { admin, token } = isAuthenticated();
    const location = useLocation();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    // For date filters: end date is the current system date
    const [values, setValues] = useState({
        startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        tripSheets: []        
    });

    // Filter state
    const [filters, setFilters] = useState({
      location: '',
      ticketNumber: '',
      customer: '',
      driver: '',
      isLoadVerified: '',
    });
    const [filteredLoads, setFilteredLoads] = useState([]);

    const { startDate, endDate, loading, loadingModal, error, isLoaded, tripSheets } = values;

    const preload = () => {
        setValues({ ...values, error: "", loading: true, isLoaded: "" })
        getalltripsheets(admin._id, {startDate, endDate}, token).then(data1 => {
            if(data1.error) {
                setValues({ ...values, error: data1, loading: "", isLoaded: ""})
                toastifyMessage("error", "Error fetching the loads");
            }else{
                setValues({ 
                    ...values, 
                    tripSheets:data1,
                    error: "", 
                    loading: "", 
                    isLoaded: true});

                if(data1.length===0){
                    toastifyMessage("warning", "No data found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the loads:', err);
            setValues({ ...values, error: "Error loading the loads", loading: "" });
            toastifyMessage("error", 'Error loading the loads');
        });
    }

    const reloadInvoice = () => {
        setValues({ ...values, error: "", loading: true, isLoaded: "" })
        getalltripsheets(admin._id, {startDate, endDate}, token).then(data1 => {
            if(data1.error) {
                setValues({ ...values, error: data1, loading: "", isLoaded: ""})
                toastifyMessage("error", "Error fetching the loads");
            }else{
                setValues({ 
                    ...values, 
                    tripSheets:data1,
                    error: "", 
                    loading: "", 
                    isLoaded: true});
                
                if(data1.length===0){
                    toastifyMessage("warning", "No data found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the loads:', err);
            setValues({ ...values, error: "Error loading the loads", loading: "" });
            toastifyMessage("error", 'Error loading the loads');
        });
    }

    const updateInvoice = (id, data1, effectiveDate) => {
        // Update the tripSheets array in the state
        setValues(prevValues => ({
            ...prevValues,
            tripSheets: prevValues.tripSheets.map(sheet => {
                const shouldUpdate = 
                    (sheet._id === id) ||
                    (sheet.pickUpLocation === data1.pickUpLocation &&
                     sheet.deliveryLocation === data1.deliveryLocation &&
                     sheet.pickUpCompany[0]._id === data1.pickUpCompany._id &&
                     moment(sheet.date).isSameOrAfter(moment(effectiveDate))
                    );
    
                return shouldUpdate ? {
                    ...sheet,
                    ratePerUnit: data1.ratePerUnit || data1.ratePerUnit === 0 ? data1.ratePerUnit : sheet.ratePerUnit,
                    loadSubTotal: data1.loadSubTotal || data1.loadSubTotal === 0 ? data1.loadSubTotal : sheet.loadSubTotal,
                    ifFlatRate: (data1.ifFlatRate?true:false)
                } : sheet;
            })
        }));
    }

    const flipLoadVerificationSheetStatus = (id, status) => {
         // Update the tripSheets array in the state
         setValues(prevValues => ({
            ...prevValues,
            tripSheets: prevValues.tripSheets.map(
                sheet => sheet._id === id ? { ...sheet, isLoadVerified: status } : sheet
            )
        }));
    }

    const flipWaitingWaiverSheetStatus = (id, status) => {
        // Update the tripSheets array in the state
        setValues(prevValues => ({
           ...prevValues,
           tripSheets: prevValues.tripSheets.map(
               sheet => sheet._id === id ? { ...sheet, isWaitingTimeWaivedOff: status } : sheet
           )
       }));
   }

    // Preload the Loads
    useEffect(() => {
        preload();
    }, []);

    // Updates the filtered list whenever the tripsheets are updated
    useEffect(() => {
        const filtered = tripSheets.filter((sheet) => {
            return (
              (!filters.location || ((sheet.pickUpLocation?sheet.pickUpLocation:"").toLowerCase().includes(filters.location.toLowerCase()) || ((sheet.deliveryLocation?sheet.deliveryLocation:"").toLowerCase().includes(filters.location.toLowerCase())))) &&
              (!filters.ticketNumber || ((sheet.ticketNumberAtShipping?sheet.ticketNumberAtShipping:"").toLowerCase().includes(filters.ticketNumber.toLowerCase()) || ((sheet.ticketNumberAtReceiving?sheet.ticketNumberAtReceiving:"").toLowerCase().includes(filters.ticketNumber.toLowerCase())))) &&
              (!filters.customer || ((sheet.pickUpCompany && sheet.pickUpCompany[0].partnerName).toLowerCase().includes(filters.customer.toLowerCase()))) &&
              (!filters.driver || ((sheet.driverNo && (sheet.driverNo[0].firstName + ' ' + sheet.driverNo[0].lastName)).toLowerCase().includes(filters.driver.toLowerCase()))) &&
              (!filters.isLoadVerified || ((sheet.isLoadVerified === false)))
            );
          });
        setFilteredLoads(filtered);
        
    }, [values.tripSheets, filters]);

    // Handle search for loads
    const handleLoadSearch = (event) => {
        const name = event.target.name;
        const value = name === 'isLoadVerified' ? event.target.checked : event.target.value;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
          }));
    };

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        reloadInvoice();
    };

    const updateInvoiceAfterSheetDeletion = (sheetId, data) => {
        setValues({ ...values, tripSheets: tripSheets.map(sheet => (sheet._id === sheetId ? data : sheet ))});
    }

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const systemDate = new Date();
       
        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Trip Sheet`, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`Date: ${moment(systemDate).format('MMMM D, YYYY')}`, 10, 30);
        doc.text(`Report Duration: ${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 10, 35);
        
        // Add the table using autoTable
        doc.autoTable({
            startY: 45,
            head: [[
                'Sr. No.', 'Shipping Date', 'Shipping Location', 'Ticket Number', 'Net Pounds', 
                'Receiving Date', 'Receiving Location', 'Ticket Number', 'Net Pounds',
                'Commodity','Truck #', 'Trailer #', 'Customer', 'Driver', 'Billable Wait Time (mins)',
                'Net Unit', 'Rate Per Unit', 'Load Subtotal', 'Fuel S/C Rate', 'Fuel Amount', 'Status'
            ]],
            body: tripSheets.map((sheet, index) => ([
                index + 1,
                sheet.date ? ((sheet.date).substring(0, 10)) : "",
                getPickupLocation(sheet) || "",
                sheet.ticketNumberAtShipping ? sheet.ticketNumberAtShipping : "",
                (sheet.inBoundWeight || sheet.inBoundWeight === 0) ? sheet.inBoundWeight : "",

                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.receivingDate ? ((sheet.receivingDate).substring(0, 10)) : ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (getDeliveryLocation(sheet) || ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.ticketNumberAtReceiving ? sheet.ticketNumberAtReceiving : ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : ((sheet.outBoundWeight || sheet.outBoundWeight === 0) ? sheet.outBoundWeight : ""),
                getPickupCommodity(sheet, true) || "",
                getTruckNo_2(sheet, true) || "",
                getTrailerNo_2(sheet, true) || "",
                getPickupCompany_2(sheet, true) || "",
                getDriverName(sheet, true) || "",
                sheet.isWaitingTimeWaivedOff ? "0" :(getBillableWaitingPeriod(sheet) || ""),
                getNetUnit(sheet) || "",
                getRatePerUnit(sheet) || "",
                getLoadSubTotal(sheet, true) || "",
                (sheet.fuelRate || sheet.fuelRate === 0) ? `${sheet.fuelRate}%` : "",
                getFuelAmount(sheet, true) || "",
                (sheet.isDeliveryIncomplete ? "Rejected" :
                       (sheet.typeOfLoad && sheet.typeOfLoad === 311) ? "No Load" : 
                       (sheet.typeOfLoad && sheet.typeOfLoad === 321) ? "Yard Work" :
                       "Completed"
                )

            ])),
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });
    
        // Add content below the table
        let finalY = doc.previousAutoTable.finalY + 10;
        const pageHeight = doc.internal.pageSize.getHeight();
    
        if (finalY + 30 > pageHeight) {
            doc.addPage();
            finalY = 20; // Reset finalY for new page
        }
        
        // Add the footer strip
        const stripY = finalY + 35;
        const stripHeight = 5;
    
        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }
    
        const filename = 'Load_sheet.pdf';
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        // Loading animation
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();

        const doc = new jsPDF('landscape');
        let startY = 20;
        let lastPageHeight = 0;
        let hasImage = false;
        let countOfImages = 0;
    
         for (const [index, tripSheet] of tripSheets.entries()) {
            const imagesData = [];
            let imagesCount = 0;
    
            if(isS3IntegrationEnabled){
                // Check for tripPhoto1
                if (tripSheet && tripSheet.photo1Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto1');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for tripPhoto2
                if (tripSheet && tripSheet.photo2Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto2');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }

                // Check for tripPhoto3
                if (tripSheet && tripSheet.photo3Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto3');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for tripPhoto4
                if (tripSheet && tripSheet.photo4Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto4');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }

                // Check for tripPhoto5
                if (tripSheet && tripSheet.photo5Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto5');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for tripPhoto6
                if (tripSheet && tripSheet.photo6Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto6');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            }

            if(isMongoDBImageReadEnabled){
                // Check for tripPhoto1
                if (tripSheet.photo1 && tripSheet.photo1.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo1.contentType};base64,${tripSheet.photo1.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for tripPhoto2
                if (tripSheet.photo2 && tripSheet.photo2.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo2.contentType};base64,${tripSheet.photo2.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }

                // Check for tripPhoto3
                if (tripSheet.photo3 && tripSheet.photo3.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo3.contentType};base64,${tripSheet.photo3.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for tripPhoto4
                if (tripSheet.photo4 && tripSheet.photo4.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo4.contentType};base64,${tripSheet.photo4.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }

                // Check for tripPhoto5
                if (tripSheet.photo5 && tripSheet.photo5.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo5.contentType};base64,${tripSheet.photo5.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for tripPhoto6
                if (tripSheet.photo6 && tripSheet.photo6.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo6.contentType};base64,${tripSheet.photo6.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            }

            if (imagesData.length > 0) {
                hasImage = true;
                countOfImages += imagesData.length;
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Trip Sheet ${index + 1}`, 10, startY);
                startY += 10; 
    
                imagesData.forEach((imageData, imgIndex) => {
                    const imgProps = doc.getImageProperties(isS3IntegrationEnabled ? imageData.url : imageData.data);
                    const imgWidth = 150;
                    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
                    doc.text(imageData.label, 10, startY);
                    doc.addImage((isS3IntegrationEnabled ? imageData.url : imageData.data), 'JPEG', 10, startY + 10, imgWidth, imgHeight);
    
                    startY += imgHeight + 20; 
    
                    if (startY + imgHeight > doc.internal.pageSize.getHeight() - 20) {
                        doc.addPage();
                        startY = 20;
                    }
                });
    
                startY += 20;
                if(startY === 40){
                    startY = 20;
                }
                lastPageHeight = startY;
            }
        };
    
        const pageHeight = doc.internal.pageSize.getHeight();
        const lastPageNumber = doc.internal.getNumberOfPages();
        if ((lastPageHeight < pageHeight - 20) && (lastPageNumber > countOfImages)) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }

        // Close the loading animation before saving the pdf
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });
        
        if(!hasImage){
            toastifyMessage("warning", "No images exist for the loads");
            return;
        }
    
        const filename = 'Load_sheet_photos.pdf';
        doc.save(filename);
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download as PDF
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the load?");
    const [deleteTripSheetId, setDeleteTripSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Trip Sheet using the Admin privilege
    const deleteThisTripSheet = (tripSheetId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletetripsheet(tripSheetId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, loadingModal: "" });
                    toastifyMessage("error", 'Error deleting the trip sheet');
                } else {
                    toastifyMessage("success", "Trip Sheet deleted successfully");
         
                    // To close the modal & reload the values
                    handleCancelDeletion();
                    setValues({ ...values, error: "", loadingModal: "", tripSheets: (tripSheets.filter(sheet => (sheet._id !== tripSheetId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the trip sheet:', err);
                setValues({ ...values, error: "Error deleting the trip sheet", loadingModal: "" });
                toastifyMessage("error", 'Error deleting the trip sheet');
            });
    };

    return (
        <Base title="Loads Dashboard" description="Manage/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="trip-dashboard">
                <div className="trip-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>View Loads</h2>
                    {dateFilterForm()}
                </div>
                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the loads</h4>}
                    {isLoaded && !error && tripSheets.length > 0 && (
                         <>
                            <div className="filters">
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Ticket #:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the ticket #"
                                         name="ticketNumber"
                                         value={filters.ticketNumber}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Location:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the location"
                                         name="location"
                                         value={filters.location}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Customer:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the customer"
                                         name="customer"
                                         value={filters.customer}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Driver:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the driver"
                                         name="driver"
                                         value={filters.driver}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                        <label className="text-light" style={{ marginRight: '10px', marginBottom: '10px' }}>
                                            Show only unverified loads
                                        </label>
                                        <input
                                          type="checkbox"
                                          name="isLoadVerified"
                                          checked={filters.isLoadVerified}
                                          onChange={handleLoadSearch}
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            cursor: 'pointer',
                                          }}
                                        />
                                </div>
                            </div>
                             <div className="trip-sheet-table">
                                 <LoadsTableView tripSheets={filteredLoads} editURL={`/admin/update/partner/tripsheet/`} isAggregated={true} updateSheet={updateInvoice} flipLoadVerificationSheetStatus={flipLoadVerificationSheetStatus} flipWaitingWaiverSheetStatus={flipWaitingWaiverSheetStatus} setDeleteTripSheetId={setDeleteTripSheetId} handleShowDeleteMessage={handleShowDeleteMessage}/>
                             </div>
                        </>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTripSheet} id={deleteTripSheetId} message={deleteMessage} loadingModal={loadingModal}/>
            <ToastifyMessage />
        </Base>
    );
}