import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';

export default function FuelRateHistoryTableView({fuelRates, setDeleteFuelRateId, handleShowDeleteMessage}) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Sr. No.</StyledTableCell>
                  <StyledTableCell align="center">Effective Date</StyledTableCell>
                  <StyledTableCell align="center">Fuel S/C Rate</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fuelRates && fuelRates.map((fuelRate, index) => (
                  <StyledTableRow key={index}>         
                    <StyledTableCell component="th" align="center" scope="row">{index + 1}</StyledTableCell>
                    <StyledTableCell align="center">{(fuelRate.effectiveDate).substring(0, 10)}</StyledTableCell>
                    <StyledTableCell align="center">${fuelRate.fuelRate}</StyledTableCell>
                    <StyledTableCell align="center">
                          <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton aria-label="delete" size="large" color="error" onMouseDown={handleMouseDownPassword}
                                    onClick={()=>{
                                      setDeleteFuelRateId(fuelRate._id);
                                      handleShowDeleteMessage();
                                    }}>
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                          </Stack>
                      </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
    )
}