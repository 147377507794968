import React from 'react'
import ReactLoading from "react-loading";

export default function LoadingScreen(isLoading) {
    if (!isLoading) {
        return null;
    }
    const message = "Loading ....";
    const color = '#FFFFFF';
    const height = 100;
    const width = 50;
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px 0' }}>
            <ReactLoading type="spinningBubbles" color={color} height={height} width={width} />
            <h4 style={{ color }}> {message} </h4>
        </div>
    );
}
