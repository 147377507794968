import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getlogsheetsfordriver, deletelogsheet, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LogSheetTableView from '../../core/helper/LogSheetTableView';
import PreparingDownload from '../../core/helper/PreparingDownload';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import '../AdminSideSheet.css';
import { getTruckNo, getTrailerNo, getDate, getDayOfTheWeek, getBillableTotalHours, getLoadsSubTotal } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";

// Total Amounts
let globalLoadTotal = 0;
let globalNightOutTotal = 0;
let globalSubTotal = 0;
let globalHSTTotal = 0;
let globalInvoiceTotal = 0;
let globalBillableTotalTime = 0;
let globalDeductions = 0;
let globalNumberOfLoads = 0;

export default function ViewDriverLogSheets() {
    const { admin, token } = isAuthenticated();
    const { driverId } = useParams();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    const [values, setValues] = useState({
        startDate: moment().subtract(13, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        logSheets: [],
        firstName: "",
        lastName: "",
        businessName: "",
        businessAddress: "",
        businessTaxNumber: "",
        invoiceNumber: "",
        isReimbursementRequired: false
    });

    const { startDate, endDate, loading, loadingModal, error, isLoaded, logSheets, firstName, lastName, businessName, businessAddress, businessTaxNumber, invoiceNumber, isReimbursementRequired } = values;
    let reimbursementCounter = false;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getlogsheetsfordriver(admin._id, driverId, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
                toastifyMessage("error", 'Error fetching the time cards');
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].reimbursementLocation && data[i].reimbursementDate) {
                        reimbursementCounter = true;
                        break;
                    }
                }
                const updatedValues = {
                    ...values,
                    logSheets: data,
                    loading: "",
                    isLoaded: true,
                    isReimbursementRequired: reimbursementCounter
                };
                if (data[0] && data[0].driverNo[0]) {
                    updatedValues.firstName = data[0].driverNo[0].firstName;
                    updatedValues.lastName = data[0].driverNo[0].lastName;
                    updatedValues.businessName = data[0].driverNo[0].businessName ? data[0].driverNo[0].businessName : "";
                    updatedValues.businessAddress = data[0].driverNo[0].businessAddress ? data[0].driverNo[0].businessAddress : "";
                    updatedValues.businessTaxNumber = data[0].driverNo[0].businessTaxNumber ? data[0].driverNo[0].businessTaxNumber : "";
                    updatedValues.invoiceNumber = (data[0].driverNo[0].firstName).substring(0, 1) +
                                                  (data[0].driverNo[0].lastName).substring(0, 1) + 'I#' +
                                                  (moment(startDate).format('DDMMYYYY')) + 
                                                  (moment(endDate).format('DDMMYYYY'))

                }
                setValues(updatedValues);

                // Invoice calculations
                globalLoadTotal = (data.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut && sheet.ratePerHour) ? getLoadsSubTotal(sheet): 0), 10), 0)).toFixed(2);
                globalNightOutTotal = data.reduce((total, sheet) => total + parseFloat(((sheet.nightOut && sheet.nightOutRate) ? sheet.nightOutRate.toFixed(2) : 0), 10), 0).toFixed(2);
                globalSubTotal = (parseFloat(globalLoadTotal) + parseFloat(globalNightOutTotal)).toFixed(2);
                globalHSTTotal = (parseFloat(globalSubTotal) * 0.13).toFixed(2);
                globalInvoiceTotal = (parseFloat(globalSubTotal) + parseFloat(globalHSTTotal)).toFixed(2);
                globalBillableTotalTime = (data.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut) ? getBillableTotalHours(sheet): 0), 10), 0)).toFixed(2);
                globalDeductions = (data.reduce((total, sheet) => total + parseFloat(((sheet.deductions) ? sheet.deductions: 0), 10), 0)).toFixed(2);
                globalNumberOfLoads = (data.reduce((total, sheet) => total + parseFloat(((sheet.numberOfLoads) ? sheet.numberOfLoads: 0), 10), 0)).toFixed(2);

                if(data.length===0){
                    toastifyMessage("warning", 'No time card found');
                 }
            }
        })
        .catch(err => {
            console.log('Error loading the time cards:', err);
            setValues({ ...values, error: "Error loading the time cards", loading: "" });
            toastifyMessage("error", 'Error loading the time cards');
        });
    };

    const reCalculateInvoice = () => {
        // Invoice calculations
        globalLoadTotal = (logSheets.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut && sheet.ratePerHour) ? getLoadsSubTotal(sheet): 0), 10), 0)).toFixed(2);
        globalNightOutTotal = logSheets.reduce((total, sheet) => total + parseFloat(((sheet.nightOut && sheet.nightOutRate) ? sheet.nightOutRate.toFixed(2) : 0), 10), 0).toFixed(2);
        globalSubTotal = (parseFloat(globalLoadTotal) + parseFloat(globalNightOutTotal)).toFixed(2);
        globalHSTTotal = (parseFloat(globalSubTotal) * 0.13).toFixed(2);
        globalInvoiceTotal = (parseFloat(globalSubTotal) + parseFloat(globalHSTTotal)).toFixed(2);
        globalBillableTotalTime = (logSheets.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut) ? getBillableTotalHours(sheet): 0), 10), 0)).toFixed(2);
        globalDeductions = (logSheets.reduce((total, sheet) => total + parseFloat(((sheet.deductions) ? sheet.deductions: 0), 10), 0)).toFixed(2);
        globalNumberOfLoads = (logSheets.reduce((total, sheet) => total + parseFloat(((sheet.numberOfLoads) ? sheet.numberOfLoads: 0), 10), 0)).toFixed(2);
    }

     // Recalculate invoice totals whenever logSheets state changes
     useEffect(() => {
        reCalculateInvoice();
    }, [logSheets]);

    useEffect(() => {
        preload();
    }, []);

    const flipSheetStatus = (id, status) => {
        // Update the logSheets array in the state
        setValues(prevValues => ({
           ...prevValues,
           logSheets: prevValues.logSheets.map(
               sheet => sheet._id === id ? { ...sheet, isTripCardVerified: status } : sheet
           )
       }));
   }

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the table
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Time Card`, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`Driver: ${firstName} ${lastName}`, 10, 30);
        doc.text(`Report Duration: ${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 10, 35);

        // Define table columns based on LogSheetTableView component
        const tableColumns = [
            'Sr. No.', 'Date', 'Day', 'Truck', 'Trailer', 'Start City', 'Finish City', 'Start Odometer', 'End Odometer', 'Down time', 'Billable Start Time', 'Billable End Time', 'Deductions (in hour)', 'Notes', 'Number of Loads Delivered', 'Night out', 'Reimbursement'
        ];
        if (isReimbursementRequired) {
            tableColumns.push('Reimbursement Date', 'Reimbursement Purpose', 'Reimbursement Location', 'Reimbursement Amount');
        }

        // Define table rows based on logSheets data
        const tableRows = logSheets.map((logSheet, index) => {
            const row = [
                index + 1,
                logSheet.date ? getDate(logSheet.date) : "",
                getDayOfTheWeek(logSheet),
                getTruckNo(logSheet, true),
                getTrailerNo(logSheet, true),
                logSheet.pickUpCity || "",
                logSheet.deliveryCity || "",
                logSheet.startOdometer || "",
                logSheet.endOdometer || "",
                (logSheet.downTime || logSheet.downTime === 0) ? logSheet.downTime : "",
                logSheet.timeIn || "",
                logSheet.timeOut || "",
                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                logSheet.deductionNotes || "-",
                logSheet.numberOfLoads || "-",
                logSheet.nightOut ? (logSheet.nightOutPurpose ? ('Yes\n' + logSheet.nightOutPurpose) : 'Yes'): 'No',
                logSheet.reimbursement ? 'Yes' : 'No'
            ];
            if (isReimbursementRequired) {
                row.push(
                    logSheet.reimbursement && logSheet.reimbursementDate ? getDate(logSheet.reimbursementDate) : "-",
                    logSheet.reimbursement && logSheet.reimbursementPurpose ? logSheet.reimbursementPurpose : "-",
                    logSheet.reimbursement && logSheet.reimbursementLocation ? logSheet.reimbursementLocation : "-",
                    logSheet.reimbursement && (logSheet.reimbursementAmount || logSheet.reimbursementAmount === 0) ? logSheet.reimbursementAmount : "-"
                );
            }

            return row;
        });

        doc.autoTable({
            startY: 45,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        // Add the footer strip
        let finalY = doc.previousAutoTable.finalY + 10;

        const stripY = finalY + 50;
        const stripHeight = 5;

        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }

        const filename = (firstName && lastName) ? ('Time_Card_' + (firstName?firstName:"") + (lastName?lastName:"") + '.pdf') : "Time_card.pdf";
        doc.save(filename);
    };

    const downloadTripCardPDF = () => {
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the table
        // LHS Content
        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text(`Invoice #: ${invoiceNumber}`, 14, 20);
        doc.text(`Invoice Date: ${moment().format('MMMM D, YYYY')}`, 14, 25);
        doc.text(`Company Name: ${businessName} (${firstName} ${lastName})`, 14, 30);
        doc.text(`Address: ${businessAddress}`, 14, 35);
        doc.text(`Invoice Period: ${moment(startDate).format('MMMM D, YYYY')} - ${moment(endDate).format('MMMM D, YYYY')}`, 14, 40);

        // RHS Content
        doc.setFontSize(25);
        doc.setFont('helvetica', 'bold');
        doc.text(`INVOICE`, 245, 20);

        doc.setFontSize(11);
        doc.text(`Bill To: Triple Trans Mill Services Limited`, 180, 30);
        doc.text(`Address: 325 Masters Dr, Woodstock, ON N4T0L2`, 180, 35);

        // Define table columns based on LogSheetTableView component
        const tableColumns = [
            'Date', 'Day', 'Truck #', 'Billable Start Time', 'Billable End Time', 'Deductions (in hour)', 'Billable Total Hours', 'Rate', '# of Loads Delivered', 'Night out Subtotal', 'Subtotal'
        ];

        // Define table rows based on logSheets data
        const tableRows = logSheets.map((logSheet, index) => {
            const row = [
                logSheet.date ? getDate(logSheet.date) : "",
                getDayOfTheWeek(logSheet),
                getTruckNo(logSheet, true),
                logSheet.timeIn || "",
                logSheet.timeOut || "",
                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                getBillableTotalHours(logSheet),
                logSheet.ratePerHour ? ('$' + logSheet.ratePerHour.toFixed(2)) : "-",
                logSheet.numberOfLoads || "-",
                (logSheet.nightOut && logSheet.nightOutRate) ? ('$' + logSheet.nightOutRate.toFixed(2)) : "-",
                getLoadsSubTotal(logSheet, true)
            ];

            return row;
        });

         // Add totals row at the end of the tableRows array
        const totalsRow = [
            "Totals",
            "",
            "",
            "",
            "",
            globalDeductions,
            globalBillableTotalTime,    // Billable Total Hours
            "",
            globalNumberOfLoads,
            '$' + globalNightOutTotal, // Night out Subtotal
            '$' + globalLoadTotal     // Grand Subtotal
        ];
        tableRows.push(totalsRow);

        doc.autoTable({
            startY: 50,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 9,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2,        // Border width
                halign: 'center'
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2,        // Outer border width
            
            // Bold the last row (totals row)
            didParseCell: (data) => {
                const rowsCount = tableRows.length;
                if (data.row.index === rowsCount - 1) {
                    data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = [211, 211, 211];  // Light Gray (RGB)
                    data.cell.styles.textColor = [0, 0, 0];        // Black text color                    
                }
            }
        });

        // Add the footer strip
        let finalY = doc.previousAutoTable.finalY + 20;
    
        if (finalY + 30 > pageHeight) {
            doc.addPage();
            finalY = 20; // Reset finalY for new page
        }
    
        doc.setFontSize(12);
        doc.setFont('times', 'normal');

        doc.text(`Grand Subtotal: $${globalSubTotal}`, doc.internal.pageSize.getWidth()-90, finalY);
        doc.text(`HST (13%): $${globalHSTTotal}`, doc.internal.pageSize.getWidth()-90, finalY + 6);
        doc.setFont('times', 'bold')
        doc.text(`Total: $${globalInvoiceTotal}`, doc.internal.pageSize.getWidth()-90, finalY + 12);

        const stripY = finalY + 30;
        const stripHeight = 5;

        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.text('Please make cheque payable to above mentioned company name.', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
            doc.text(`HST #: ${businessTaxNumber}`, doc.internal.pageSize.getWidth() / 2, stripY + 6, { align: 'center' });
        } else {
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('Please make cheque payable to above mentioned company name.', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
            doc.text(`HST #: ${businessTaxNumber}`, doc.internal.pageSize.getWidth() / 2, stripY + 6, { align: 'center' });
        }

        const filename = (firstName && lastName) ? ('Time_Card__' + (firstName?firstName:"") + (lastName?lastName:"") + '.pdf') : "Time_card.pdf";
        doc.save(filename);
    };

    // Download payroll invoice as excel   
    const exportToExcel = () => {

        const header = [
            'Date', 'Day', 'Truck #', 'Billable Start Time', 'Billable End Time', 'Deductions (in hour)' , 'Billable Total Hours', 'Rate', '# of Loads Delivered', 'Night out Subtotal', 'Subtotal'
        ];
    
        // Define table rows based on logSheets data
        const body = logSheets.map((logSheet, index) => {
            const row = [
                logSheet.date ? getDate(logSheet.date) : "",
                getDayOfTheWeek(logSheet),
                getTruckNo(logSheet, true),
                logSheet.timeIn || "",
                logSheet.timeOut || "",
                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                getBillableTotalHours(logSheet),
                logSheet.ratePerHour ? ('$' + logSheet.ratePerHour.toFixed(2)) : "-",
                logSheet.numberOfLoads || "-",
                (logSheet.nightOut && logSheet.nightOutRate) ? ('$' + logSheet.nightOutRate.toFixed(2)) : "-",
                getLoadsSubTotal(logSheet, true)
            ];
    
            return row;
        });
    
         // Add totals row at the end of the tableRows array
        const totalsRow = [
            "Totals",
            "",
            "",
            "",
            "",
            globalDeductions,
            globalBillableTotalTime,    // Billable Total Hours
            "",
            globalNumberOfLoads,
            '$' + globalNightOutTotal, // Night out Subtotal
            '$' + globalLoadTotal     // Grand Subtotal
        ];
        body.push(totalsRow);
        
      
        // Add the invoice data on LHS
        let additionalRows = [
          ["Invoice #: " + invoiceNumber],
          ["Invoice Date: " + (moment().format('MMMM D, YYYY'))],
          ["Company Name: " + businessName + " (" + firstName + " " + lastName + ")"],
          ["Address: " + businessAddress],
          ["Invoice Period: " + (moment(startDate).format('MMMM D, YYYY')) + " - " + (moment(endDate).format('MMMM D, YYYY'))]
        ];

        // Convert the additional rows to sheet format and insert them at the top
        const ws = XLSX.utils.aoa_to_sheet(additionalRows);

        // Add the invoice data on RHS
        additionalRows = [
            ["INVOICE"],
            ["Bill To: Triple Trans Mill Services Limited"],
            ["Address: 325 Masters Dr, Woodstock, ON N4T0L2"]
          ];
  
        // Convert the invoice data to sheet format and insert them at the top right
        XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `G1` });

        // Insert the table data (header and body) after the additional rows
        XLSX.utils.sheet_add_aoa(ws, [header], { origin: `A8` });
        XLSX.utils.sheet_add_aoa(ws, body, { origin: `A9` });
      
        // Calculate column widths based on content length
        const colWidths = header.map((col, i) => {
          // Initialize with the width of the header
          let max = col.length;
      
          // Check each cell in the column (including header)
          body.forEach(row => {
            const cellValue = row[i] ? row[i].toString() : "";
            if (cellValue.length > max) {
              max = cellValue.length;
            }
          });
      
          // Add extra width to ensure the content is not cut off
          return { wch: max + 2 };
        });
      
        // Set column widths in the worksheet
        ws['!cols'] = colWidths;
      
        // Add invoice totals
        let totalRows = body.length + 1; // +1 for the header
      
        // Add the additional rows in columns I and J
        additionalRows = [
          ["GRAND SUBTOTAL", "$" + globalSubTotal],
          ["HST (13%)", "$" + globalHSTTotal],
          ["TOTAL", "$" + globalInvoiceTotal]
        ];

        XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `I${totalRows + 11}` });
      
        // Add the additional rows in columns I and J
        additionalRows = [
          ["Please make cheque payable to above mentioned company name."],
          ["HST #: " + businessTaxNumber]
        ];

        XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `D${totalRows + 15}` });
      
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "payroll_invoice");
      
        // Generate the Excel file
        const excelFilename = (firstName && lastName) ? `driver_payroll_invoice_${firstName}_${lastName}.xlsx` : "driver_payroll_invoice.xlsx";
        XLSX.writeFile(wb, excelFilename);
      };

    const downloadImagesPDF = async () => {
        // Loading animation
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();

        const doc = new jsPDF('landscape');
        let startY = 20;
        let lastPageHeight = 0;
        let hasImage = false;
        let countOfImages = 0;
    
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Driver: ${firstName} ${lastName}`, doc.internal.pageSize.getWidth() / 2, 15, { align: 'center' });

         for (const [index, logSheet] of logSheets.entries()) {
            const imagesData = [];
            let imagesCount = 0;
    
            if(isS3IntegrationEnabled){
                // Check for logPhoto1
                if (logSheet && logSheet.photo1Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto1');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for logPhoto2
                if (logSheet && logSheet.photo2Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto2');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }

                // Check for logPhoto3
                if (logSheet && logSheet.photo3Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto3');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for logPhoto4
                if (logSheet && logSheet.photo4Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto4');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            }

            if(isMongoDBImageReadEnabled){
                // Check for logPhoto1
                if (logSheet.photo1 && logSheet.photo1.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo1.contentType};base64,${logSheet.photo1.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for logPhoto2
                if (logSheet.photo2 && logSheet.photo2.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo2.contentType};base64,${logSheet.photo2.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }

                // Check for logPhoto3
                if (logSheet.photo3 && logSheet.photo3.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo3.contentType};base64,${logSheet.photo3.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for logPhoto4
                if (logSheet.photo4 && logSheet.photo4.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo4.contentType};base64,${logSheet.photo4.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            }
            
            if (imagesData.length > 0) {
                hasImage = true;
                countOfImages += imagesData.length;
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Time Card ${index + 1}`, 10, startY);
                startY += 10; 
    
                imagesData.forEach((imageData, imgIndex) => {
                    const imgProps = doc.getImageProperties(isS3IntegrationEnabled ? imageData.url : imageData.data);
                    const imgWidth = 150;
                    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
                    doc.text(imageData.label, 10, startY);
                    doc.addImage((isS3IntegrationEnabled ? imageData.url : imageData.data), 'JPEG', 10, startY + 10, imgWidth, imgHeight);
    
                    startY += imgHeight + 20; 
    
                    if (startY + imgHeight > doc.internal.pageSize.getHeight() - 20) {
                        doc.addPage();
                        startY = 20;
                    }
                });
    
                startY += 20;
                if(startY === 40){
                    startY = 20;
                }
                lastPageHeight = startY;
            }
        };
    
        const pageHeight = doc.internal.pageSize.getHeight();
        const lastPageNumber = doc.internal.getNumberOfPages();
        if ((lastPageHeight < pageHeight - 20) && (lastPageNumber > countOfImages)) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }

        // Close the loading animation before saving the pdf
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });

        if(!hasImage){
            toastifyMessage("warning", "No images exist for the time cards");
            return;
        }
    
        const filename = (firstName && lastName) ? ('Time_Card_Photos_' + (firstName?firstName:"") + (lastName?lastName:"") + '.pdf') : "Time_card_photos.pdf";
        doc.save(filename);
    };
    

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Sheet
                </button>
                <button
                    onClick={downloadTripCardPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Payroll as PDF
                </button>
                <button
                    onClick={exportToExcel}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Payroll as XLSX
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the time card?");
    const [deleteLogSheetId, setDeleteLogSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete time Card using the Admin privilege
    const deleteThisLogSheet = (logSheetId) => {
        setValues({ ...values, loadingModal: true, error: ""});
        deletelogsheet(logSheetId, admin._id, token)
        .then(data=> {
            if(data.error){
                setValues({ ...values, error: data, loadingModal: ""})
                toastifyMessage("error", 'Error deleting the time card');
            }
            else{
                toastifyMessage("success", 'Time Card deleted successfully');

                // To close the modal & reload the values
                handleCancelDeletion();
                setValues({ ...values, error: "", loadingModal: "", logSheets: (logSheets.filter(sheet => (sheet._id !== logSheetId)))})
            }
        })
        .catch(err => {
            console.log('Error deleting the time card:', err);
            setValues({ ...values, error: "Error deleting the time card", loadingModal: "" });
            toastifyMessage("error", 'Error deleting the time card');
        });
    }

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Time Card Dashboard" description="View/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="log-dashboard">
                <div className="log-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}> {(isLoaded && logSheets[0] && logSheets[0].driverNo[0]) ? "Driver - " + logSheets[0].driverNo[0].firstName + " " + logSheets[0].driverNo[0].lastName : "Time Cards"} </h2>
                    {dateFilterForm()}
                </div>

                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the time cards</h4>}
                    {isLoaded && !error && logSheets.length > 0 && (
                        <div id="log-sheet-table">
                            <LogSheetTableView logSheets={logSheets} editURL={`/admin/update/driver/logsheet/`} isAggregated={true} isAdmin={true} isDriver={false} isBillingFeatureRequired={true} setDeleteLogSheetId={setDeleteLogSheetId} handleShowDeleteMessage={handleShowDeleteMessage} isReimbursementRequired={isReimbursementRequired} flipSheetStatus={flipSheetStatus}/>
                        </div>
                    )}
                </div>
            </div>
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisLogSheet} id={deleteLogSheetId} message={deleteMessage} loadingModal={loadingModal} />
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    );
}
