import React from 'react'
import { Link } from "react-router-dom"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import Paper from '@mui/material/Paper';
import moment from 'moment';
import "./TableView.css";

export default function TripSheetTableView({tripSheets, editURL, isAggregated, isAdmin, isDriver, setDeleteTripSheetId, handleShowDeleteMessage}) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      // Common Logic for both driver & admin
      // Retrieve Truck Number
      const getTruckNo = (tripSheet) => {
        let truckNumber = "";
        if (tripSheet && tripSheet.truckNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.truckNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.truckNo[0]){
              truckNumber = tripSheet.truckNo[0].truckNumber
            }
          }
          else{
            truckNumber = tripSheet.truckNo.truckNumber
          }
        }
        else if(tripSheet && tripSheet.otherTruckNo){
          truckNumber = tripSheet.otherTruckNo
        }
        return truckNumber;
      }

      // Retrieve Trailer Number
      const getTrailerNo = (tripSheet) => {
        let trailerNumber = "";
        if (tripSheet && tripSheet.trailerNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.trailerNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.trailerNo[0]){
              trailerNumber = tripSheet.trailerNo[0].trailerNumber
            }
          }
          else{
            trailerNumber = tripSheet.trailerNo.trailerNumber
          }
        }
        else if(tripSheet && tripSheet.otherTrailerNo){
          trailerNumber = tripSheet.otherTrailerNo
        }
        return trailerNumber;
      }

       // Retrieve pickup company name
       const getPickupCompany = (tripSheet) => {
        let pickupCompany = "";
        if (tripSheet && tripSheet.pickUpCompany && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.pickUpCompany[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.pickUpCompany[0]){
              pickupCompany = tripSheet.pickUpCompany[0].partnerName
            }
          }
          else{
            pickupCompany = tripSheet.pickUpCompany.partnerName
          }
        }
        else if(tripSheet && tripSheet.otherPickUpCompany){
          pickupCompany = tripSheet.otherPickUpCompany
        }
        return pickupCompany;
      }

      // Retrieve pickup location
      const getPickupLocation = (tripSheet) => {
        let pickUpLocation = "";
        if (tripSheet && tripSheet.pickUpLocation){
          pickUpLocation = tripSheet.pickUpLocation
        }
        else if(tripSheet && tripSheet.otherPickUpLocation){
          pickUpLocation = tripSheet.otherPickUpLocation
        }
        return pickUpLocation;
      }

      // Retrieve delivery location
      const getDeliveryLocation = (tripSheet) => {
        let deliveryLocation = "";
        if (tripSheet && tripSheet.deliveryLocation){
            deliveryLocation = tripSheet.deliveryLocation
        }
        else if(tripSheet && tripSheet.otherDeliveryLocation){
          deliveryLocation = tripSheet.otherDeliveryLocation
        }
        return deliveryLocation;
      }

       // Retrieve pickup company name
       const getPickupCommodity = (tripSheet) => {
        let pickUpCommodity = "";
        if (tripSheet && tripSheet.pickUpCommodity && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.pickUpCommodity[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.pickUpCommodity[0]){
              pickUpCommodity = tripSheet.pickUpCommodity[0].commodityName
            }
          }
          else{
            pickUpCommodity = tripSheet.pickUpCommodity.commodityName
          }
        }
        else if(tripSheet && tripSheet.otherPickUpCommodity){
          pickUpCommodity = tripSheet.otherPickUpCommodity
        }
        return pickUpCommodity;
      }

     // Retrieve Formatted Date
     const getDate = (inputDate) => {
        let sheetDate = "";
        if (inputDate){
          sheetDate = moment((inputDate.substring(0,10)), 'YYYY-MM-DD').format('D MMMM YYYY');
        }
        return sheetDate;
      }

      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      // Show Actions option to the driver only if the sheet is within the current week (last 7 days)
      const ifEditAllowed = (inputDate) => {
        let isOlderThanAWeek = false;
        if (inputDate){
              // Calculate the date 7 days ago from now
              const sevenDaysAgo = moment().subtract(7, 'days');
              console.log(sevenDaysAgo,inputDate)
              // Check if the input date is before the date 7 days ago
              isOlderThanAWeek = moment((inputDate.substring(0,10))).isBefore(sevenDaysAgo);
        }
        return !isOlderThanAWeek;
      }

    return (
      <div className="table-container">
          <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 200px)' }}>
              <Table stickyHeader className="mui-table" size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="sticky-header-cell">Sr. No.</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Pickup Date</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Pickup Scale In</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Pickup Scale Out</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Customer</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Pickup Location</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Ticket #</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Inbound Weight</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Delivery Date</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Delivery Scale In</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Delivery Scale Out</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Drop Location</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Ticket #</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Outbound Weight</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Deductions</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Commodity</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Truck</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Trailer</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Status</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Photos</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tripSheets && tripSheets.map((tripSheet, index) => (
                    <StyledTableRow key={index}>         
                      <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                      <StyledTableCell align="center">{tripSheet.date ? getDate(tripSheet.date) : ""}</StyledTableCell>
                      <StyledTableCell align="center">{tripSheet.pickUpTimeIn ? tripSheet.pickUpTimeIn : ""}</StyledTableCell>
                      <StyledTableCell align="center">{tripSheet.pickUpTimeOut ? tripSheet.pickUpTimeOut : ""}</StyledTableCell>
                      <StyledTableCell align="center">{getPickupCompany(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getPickupLocation(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{tripSheet.ticketNumberAtShipping ? tripSheet.ticketNumberAtShipping : ""}</StyledTableCell>
                      <StyledTableCell align="center">{(tripSheet.inBoundWeight || tripSheet.inBoundWeight===0) ? tripSheet.inBoundWeight : ""}</StyledTableCell>

                      {
                        (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321) 
                        ?
                          <>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                          </>
                        :
                          <>
                            <StyledTableCell align="center">{tripSheet.receivingDate ? getDate(tripSheet.receivingDate) : ""}</StyledTableCell>
                            <StyledTableCell align="center">{tripSheet.deliveryTimeIn ? tripSheet.deliveryTimeIn : ""}</StyledTableCell>
                            <StyledTableCell align="center">{tripSheet.deliveryTimeOut ? tripSheet.deliveryTimeOut : ""}</StyledTableCell>
                            <StyledTableCell align="center">{getDeliveryLocation(tripSheet)}</StyledTableCell>
                            <StyledTableCell align="center">{tripSheet.ticketNumberAtReceiving ? tripSheet.ticketNumberAtReceiving : ""}</StyledTableCell>
                            <StyledTableCell align="center">{(tripSheet.outBoundWeight || tripSheet.outBoundWeight===0) ? tripSheet.outBoundWeight : ""}</StyledTableCell>
                          </>
                      }

                      <StyledTableCell align="center">{(tripSheet.deductions || tripSheet.deductions===0) ? tripSheet.deductions : ""}</StyledTableCell>
                      <StyledTableCell align="center">{getPickupCommodity(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getTruckNo(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getTrailerNo(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">
                        <>
                            {tripSheet && tripSheet.isDeliveryIncomplete ? (
                              <span className="badge bg-danger" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}>
                                Rejected
                              </span>
                            ) : tripSheet.typeOfLoad && tripSheet.typeOfLoad === 311 ? (
                              <span className="badge bg-info" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}>
                                No Load
                              </span>
                            ) : tripSheet.typeOfLoad && tripSheet.typeOfLoad === 321 ? (
                              <span className="badge bg-info" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}>
                                Yard Work
                              </span>
                            ) : (
                              <span className="badge bg-success" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}>
                                Completed
                              </span>
                            )}
                        </>
                      </StyledTableCell>

                          

                      <StyledTableCell align="center">
                          <Stack direction="row" alignItems="center" spacing={1}>
                              { (tripSheet.photo1Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo1Name} caption={"Trip Sheet"} type={'tripphoto1'} />
                              }
                              { ((tripSheet.photo1 && tripSheet.photo1.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto1'} />
                              }
                              { (tripSheet.photo2Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo2Name} caption={"Trip Sheet"} type={'tripphoto2'} />
                              }
                              { ((tripSheet.photo2 && tripSheet.photo2.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto2'} />
                              }
                              { (tripSheet.photo3Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo3Name} caption={"Trip Sheet"} type={'tripphoto3'} />
                              }
                              { ((tripSheet.photo3 && tripSheet.photo3.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto3'} />
                              }
                              { (tripSheet.photo4Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo4Name} caption={"Trip Sheet"} type={'tripphoto4'} />
                              }
                              { ((tripSheet.photo4 && tripSheet.photo4.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto4'} />
                              }
                              { (tripSheet.photo5Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo5Name} caption={"Trip Sheet"} type={'tripphoto5'} />
                              }
                              { ((tripSheet.photo5 && tripSheet.photo5.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto5'} />
                              }
                              { (tripSheet.photo6Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo6Name} caption={"Trip Sheet"} type={'tripphoto6'} />
                              }
                              { ((tripSheet.photo6 && tripSheet.photo6.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto6'} />
                              }                              
                          </Stack>
                      </StyledTableCell>
                            
                      <StyledTableCell align="center">
                        {
                          isAdmin &&
                          <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton aria-label="delete" size="large" color="error" onMouseDown={handleMouseDownPassword}
                                  onClick={()=>{
                                  setDeleteTripSheetId(tripSheet._id);
                                  handleShowDeleteMessage();
                                  }}
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              
                                <Link to={editURL+`${tripSheet._id}`}>
                                    <IconButton aria-label="edit" size="large" color="success" onMouseDown={handleMouseDownPassword}>
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </Link>
                          </Stack>
                        }
                        {
                          isDriver && (( !tripSheet.isLoadVerified && ifEditAllowed(tripSheet.receivingDate)) ?
                          <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton aria-label="delete" size="large" color="error" onMouseDown={handleMouseDownPassword}
                                  onClick={()=>{
                                  setDeleteTripSheetId(tripSheet._id);
                                  handleShowDeleteMessage();
                                  }}
                                >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              
                                <Link to={editURL+`${tripSheet._id}`}>
                                    <IconButton aria-label="edit" size="large" color="success" onMouseDown={handleMouseDownPassword}>
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </Link>
                          </Stack>
                          :
                          <span className="badge bg-danger" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}> Not Editable </span>
                        )}
                          
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </div>
    )
}