import React, { useState, useEffect } from 'react';
import Base from "../core/Base";
import { changePassword, isAuthenticated } from "../auth/helper/adminIndex";
import { Link, Redirect } from "react-router-dom";
import { Modal, Button, Container, Row, Col} from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import ToastifyMessage from '../core/helper/ToastifyMessage';
import {toastifyMessage} from '../core/helper/Toast';
import { toast } from 'react-toastify';
import LightLoaderScreen from '../core/helper/LightLoaderScreen';

export default function AdminDashboard() {
    const { admin, token } = isAuthenticated();

    // Reset Password Fields
    const [values, setValues] = useState({
        password: "",
        newPassword: "",
        cnfNewPassword: "",
        loading: "",
        loadingModal: "",
        error: ""
     });

     const { password, newPassword, cnfNewPassword, loading, loadingModal, error } = values;

     const performRedirect = () => {  
        if (!admin) {
            return (
                <Redirect to="/" />
            )
        }
    }

     // For Reset Password Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value})
    }

    const toastifyMessage = (event, errorMessage) => {
        if (event==="warning"){
            toast.warning(errorMessage, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
        }
        else if(event==="error"){
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
        }
        else if(event==="success"){
            toast.success(errorMessage, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
        }
        else {
            toast.info(errorMessage, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loadingModal: true});

        // Field validations
        const requiredFields = [password, newPassword, cnfNewPassword ];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All fields are mandatory', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'All fields are mandatory');
            return;
        }

        if (requiredFields.some(field => (field.length<6))) {
            setValues({ ...values, error: 'Password should be atleast 6 characters', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'Password should be atleast 6 characters');
            return;
        }

        // Password validation
        if(newPassword !== cnfNewPassword) {
            setValues({ ...values, error: 'Passwords do not match', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'Passwords do not match');
            return;
          }

           // Password validation
        if(newPassword === password) {
            setValues({ ...values, error: 'New & existing password cannot be same', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'New & existing password cannot be same');
            return;
          }

        // Reset password
        changePassword({password, newPassword}, admin._id, token)
        .then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", loadingModal: ""});
                toastifyMessage("error", (data.error?data.error:"Error resetting the password"));
            } else {
                setValues({
                    ...values,
                    password: "",
                    newPassword: "",
                    cnfNewPassword: "",
                    loading: "",
                    loadingModal: "",
                    error: ""
                })
                // To close the modal
                handleClose();

                toastifyMessage("success", "Password updated successfully");
                }
            })
            .catch(err => {
                console.log('Error resetting the password:', err);
                setValues({ ...values, error: "Error resetting the password", loading: "", loadingModal: ""});
                toastifyMessage("error", 'Error resetting the password');
              });
      };

    const ResetPasswordForm = () => (
        <>
            <form className="mt-2">
                <div className="form-group">
                    <label className="text-dark"> Old Password <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("password")}
                        type="password"
                        value={password}
                        name="password"
                        placeholder="Old Password"
                        autoFocus
                    />
                </div>
                <div className="form-group">
                    <label className="text-dark"> New Password <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("newPassword")}
                        type="password"
                        value={newPassword}
                        name="newPassword"
                        placeholder="New Password"
                        autoFocus
                    />
                </div>
                <div className="form-group">
                    <label className="text-dark"> Confirm Password <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("cnfNewPassword")}
                        type="text"
                        value={cnfNewPassword}
                        name="cnfNewPassword"
                        placeholder="Confirm Password"
                        autoFocus
                    />
                </div>
                <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                    Reset Password
                </button>
            </form>
            {LightLoaderScreen(loadingModal)}
        </>
    )
  
    const resetPasswordModal = () => (
      <Modal show={show} onHide={handleClose}
             size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      Reset Password
                  </Modal.Title>
              </Modal.Header>
              
              <Modal.Body>
                  {ResetPasswordForm()}
              </Modal.Body>
    
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                      Close
                  </Button>
              </Modal.Footer>
  
          </Modal>
    )

    const adminLeftSide = () => {
        return (
            <div className="card bg-dark text-white">
                <h4 className="card-header">Admin Navigation</h4>
                <ul className="list-group list-group-flush">
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-trucks">
                            Trucks
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/truck">Add Truck</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/trucks">Manage Trucks</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-trailers">
                            Trailers
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/trailer">Add Trailer</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/trailers">Manage Trailers</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-cards">
                            Locations
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/location">Add Location</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/locations">Manage Locations</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-cards">
                            Fuel Cards
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/card">Add Fuel Card</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/cards">Manage Fuel Cards</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-commodities">
                            Commodities
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/commodity">Add Commodity</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/commodities">Manage Commodities</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-drivers">
                            Utils
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/view/audit">View Audit</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/update/nightoutrate">Update Nightout Rate</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-drivers">
                            Sheets
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/tripsheet">Add Load</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/create/timecard">Add Time Card</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/create/fuelsheet">Add Fuel Sheet</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/view/loads">View Loads</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/view/timecards">View Time Cards</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/view/fuelsheets">View Fuel Sheets</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-admins">
                            Admins
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/admin">Add Admin</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/admins">Manage Admins</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-drivers">
                            Drivers
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/driver">Add Driver</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/drivers">Manage Drivers</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown as="li" className="list-group-item bg-dark p-0">
                        <Dropdown.Toggle variant="dark" id="dropdown-partners">
                            Customers
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/admin/create/partner">Add Customer</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/admin/partners">Manage Customers</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ul>
            </div>
        );
    };

    const adminRightSide = () => {
        return (
            <div className="card" style={{ backgroundColor: '#5cb85c', color: 'white' }}>
                <h4 className="card-header" style={{ backgroundColor: '#4cae4c' }}>Admin Info</h4>
                <ul className="list-group">
                    <li className="list-group-item" style={{ backgroundColor: '#5cb85c' }}>
                        <span className="badge bg-success mr-2">
                            Username:
                        </span> {admin && admin.username ? admin.username : ""}
                    </li>
                    <li className="list-group-item" style={{ backgroundColor: '#5cb85c' }}>
                        <span 
                            className="badge bg-danger mr-2"
                        >
                            Admin Area
                        </span>
                    </li>                  
                    <li className="list-group-item" style={{ backgroundColor: '#5cb85c' }}>
                        <span 
                            className="badge bg-dark mr-2"
                            onClick={
                                () => {handleShow()} 
                            }>
                            Reset Password
                        </span>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <Base 
        title="Admin Dashboard" 
        description="Manage/View/Edit" 
        className="container-fluid py-0 px-4" 
        style={{ backgroundColor: '#5cb85c' }}>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-3">
                    {adminLeftSide()}
                </div>
                <div className="col-12 col-sm-6 col-md-9">
                    {adminRightSide()}
                </div>
            </div>
            {resetPasswordModal()}
            {performRedirect()}
            <ToastifyMessage/> 
        </Base>
    );
}