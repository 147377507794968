import React from 'react'

export default function LightLoaderScreen(isLoading) {
    if (!isLoading) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px 0' }}>
            <div className="lightLoader"></div>
        </div>
    );
}
