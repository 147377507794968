import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import LoaderScreen from '../../core/helper/LoaderScreen';
import LightLoaderScreen from '../../core/helper/LightLoaderScreen';
import { Modal, Button, Container, Row, Col} from 'react-bootstrap';
import { updatetripsheet, flipVerificationStatusForTheTripSheet } from '../../admin/helper/adminapicall';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import moment from 'moment';
import "./TableView.css";

export default function InvoiceTableView({tripSheets, editURL, isAggregated, isAdmin, showPhotos, updateSheet, flipSheetStatus, reloadSheet}) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      // Retrieve Truck Number
      // Retrieve Truck Number
      const getTruckNo = (tripSheet) => {
        let truckNumber = "";
        if (tripSheet && tripSheet.truckNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.truckNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.truckNo[0]){
              truckNumber = tripSheet.truckNo[0].truckNumber
            }
          }
          else{
            truckNumber = tripSheet.truckNo.truckNumber
          }
        }
        else if(tripSheet && tripSheet.otherTruckNo){
          truckNumber = tripSheet.otherTruckNo
        }
        return truckNumber;
      }

      // Retrieve Trailer Number
      const getTrailerNo = (tripSheet) => {
        let trailerNumber = "";
        if (tripSheet && tripSheet.trailerNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.trailerNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.trailerNo[0]){
              trailerNumber = tripSheet.trailerNo[0].trailerNumber
            }
          }
          else{
            trailerNumber = tripSheet.trailerNo.trailerNumber
          }
        }
        else if(tripSheet && tripSheet.otherTrailerNo){
          trailerNumber = tripSheet.otherTrailerNo
        }
        return trailerNumber;
      }

       // Retrieve pickup company name
       const getPickupCompany = (tripSheet) => {
        let pickupCompany = "";
        if (tripSheet && tripSheet.pickUpCompany && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.pickUpCompany[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.pickUpCompany[0]){
              pickupCompany = tripSheet.pickUpCompany[0].partnerName
            }
          }
          else{
            pickupCompany = tripSheet.pickUpCompany.partnerName
          }
        }
        else if(tripSheet && tripSheet.otherPickUpCompany){
          pickupCompany = tripSheet.otherPickUpCompany
        }
        return pickupCompany;
      }

       // Retrieve pickup commodity name
       const getPickupCommodity = (tripSheet) => {
        let pickUpCommodity = "";
        if (tripSheet && tripSheet.pickUpCommodity && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.pickUpCommodity[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.pickUpCommodity[0]){
              pickUpCommodity = tripSheet.pickUpCommodity[0].commodityName
            }
          }
          else{
            pickUpCommodity = tripSheet.pickUpCommodity.commodityName
          }
        }
        else if(tripSheet && tripSheet.otherPickUpCommodity){
          pickUpCommodity = tripSheet.otherPickUpCommodity
        }
        return pickUpCommodity;
      }

      // Retrieve pickup location
      const getPickupLocation = (tripSheet) => {
        let pickUpLocation = "";
        if (tripSheet && tripSheet.pickUpLocation){
          pickUpLocation = tripSheet.pickUpLocation
        }
        else if(tripSheet && tripSheet.otherPickUpLocation){
          pickUpLocation = tripSheet.otherPickUpLocation
        }
        return pickUpLocation;
      }

      // Retrieve delivery location
      const getDeliveryLocation = (tripSheet) => {
        let deliveryLocation = "";
        if (tripSheet && tripSheet.deliveryLocation){
            deliveryLocation = tripSheet.deliveryLocation
        }
        else if(tripSheet && tripSheet.otherDeliveryLocation){
          deliveryLocation = tripSheet.otherDeliveryLocation
        }
        return deliveryLocation;
      }

      // Retrieve Driver Name
      const getDriverName = (tripSheet) => {
        let driverName = "";
        if (tripSheet && tripSheet.driverNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.driverNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.driverNo[0]){
              let firstName = tripSheet.driverNo[0].firstName || "";
              let lastName = tripSheet.driverNo[0].lastName || "";
              driverName = firstName + " " + lastName;
            }
          }
          else{
            let firstName = tripSheet.driverNo.firstName || "";
              let lastName = tripSheet.driverNo.lastName || "";
              driverName = firstName + " " + lastName;
          }
        }
        return driverName;
      }

    // Retrieve Formatted Date
    const getDate = (inputDate) => {
      let sheetDate = "";
      if (inputDate){
        sheetDate = moment((inputDate.substring(0,10)), 'YYYY-MM-DD').format('D MMMM YYYY');
      }
      return sheetDate;
    }

    // Retrieve Billable waiting period
    const getBillableWaitingPeriod = (tripSheet) => {
      // Waive off the waiting time if 'isWaitingTimeWaivedOff' is set to true
      if(tripSheet && tripSheet.isWaitingTimeWaivedOff)
          return 0;

      let pickupWaitingTime = 0;
      let deliveryWaitingTime = 0;
      let waitingTime = 0;
      let startTime = 0;
      let endTime = 0;
      let duration = 0;
    
      // Pickup
      if(!tripSheet.pickUpTimeIn || !tripSheet.pickUpTimeOut){
          pickupWaitingTime = 0;
      }
      else{
        startTime = moment(tripSheet.pickUpTimeIn, 'HH:mm');
        endTime = moment(tripSheet.pickUpTimeOut, 'HH:mm');
        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
        }
        duration = moment.duration(endTime.diff(startTime));
        pickupWaitingTime = duration.asMinutes();
        
        // Waive off the waiting time if less than 1 hour
        if(pickupWaitingTime <= 60)
            pickupWaitingTime = 0;
        else{
          pickupWaitingTime = pickupWaitingTime - 60;
          // Calculate the nearest lower quarter of an hour - 15 minutes
          pickupWaitingTime = Math.floor(pickupWaitingTime / 15) * 15;
        }
      }
    
      // Delivery
      if(!tripSheet.deliveryTimeIn || !tripSheet.deliveryTimeOut){
        deliveryWaitingTime = 0;
      }
      else{
        startTime = moment(tripSheet.deliveryTimeIn, 'HH:mm');
        endTime = moment(tripSheet.deliveryTimeOut, 'HH:mm');
        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
        }
        duration = moment.duration(endTime.diff(startTime));
        deliveryWaitingTime = duration.asMinutes();

        // Waive off the waiting time if less than 1 hour
        if(deliveryWaitingTime <= 60)
            deliveryWaitingTime = 0;
        else{
          deliveryWaitingTime = deliveryWaitingTime - 60;
          // Calculate the nearest lower quarter of an hour - 15 minutes
          deliveryWaitingTime = Math.floor(deliveryWaitingTime / 15) * 15;
        }
      }
      return (pickupWaitingTime + deliveryWaitingTime).toFixed(0);
    }

    // Retrive Net units
    const getNetUnit = (tripSheet) => {
      let netUnit = "";
      if(tripSheet.typeOfLoad && (tripSheet.typeOfLoad === 311 || tripSheet.typeOfLoad === 321)){
            if(tripSheet.inBoundWeight)
                netUnit = (tripSheet.inBoundWeight/2000).toFixed(2);
      }
      else if(tripSheet.outBoundWeight){
            netUnit = (tripSheet.outBoundWeight/2000).toFixed(2);
      }
      return netUnit;
    }

    // Retrieve Rate Per Unit
    const getRatePerUnit = (tripSheet) => {
      let ratePerUnit = tripSheet.ifFlatRate ? "FLAT RATE" : ((tripSheet.ratePerUnit || tripSheet.ratePerUnit===0) ? `$${tripSheet.ratePerUnit}` : "")
      return ratePerUnit;
    }

    // Retrieve Load subtotal
    const getLoadSubTotal = (tripSheet, currencyRequired=false) => {
      let loadSubTotal = "";
      if(!tripSheet.ifFlatRate){
        if(tripSheet.ratePerUnit && tripSheet.inBoundWeight && (tripSheet.outBoundWeight || (tripSheet.typeOfLoad && (tripSheet.typeOfLoad === 311 || tripSheet.typeOfLoad === 321)))){
                loadSubTotal = (getNetUnit(tripSheet) * tripSheet.ratePerUnit).toFixed(2);
                if(currencyRequired)
                      loadSubTotal = "$" + loadSubTotal;
          }
        }
        else if(tripSheet.ifFlatRate){
            if(tripSheet.loadSubTotal){
                loadSubTotal = (tripSheet.loadSubTotal).toFixed(2);
                if(currencyRequired)
                    loadSubTotal = "$" + loadSubTotal;
            }
        }
        return loadSubTotal;
    }

    // Retrieve Fuel Amount
    const getFuelAmount = (tripSheet, currencyRequired=false) => {
          let fuelAmount = "";
          if(getLoadSubTotal(tripSheet) && tripSheet.fuelRate){
                fuelAmount = (getLoadSubTotal(tripSheet) * tripSheet.fuelRate / 100).toFixed(2);
                if(currencyRequired)
                    fuelAmount = "$" + fuelAmount;
          }
          return fuelAmount;
    }

      const {admin, token} = isAuthenticated();
      const [values, setValues] = useState({
        tripSheetId: "",
        ratePerUnit: "",
        loadSubTotal: "",
        ifFlatRate: false,
        effectiveDate: moment().format('YYYY-MM-DD'),
        error: false,
        updatedLoad: "",
        loading: false,
        loadingModal: false,
        didRedirect: false,
        formData: new FormData()
    });
    
    const { tripSheetId, ratePerUnit, loadSubTotal, ifFlatRate, effectiveDate, error, updatedLoad, loading, loadingModal, didRedirect, formData } = values


      // For Trip Sheet Modal
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      const handleChange = name => event => {
            const value = name === 'ifFlatRate' ? event.target.checked : event.target.value;
            if(name === 'ifFlatRate'){
                formData.set(name, value);
                setValues({ ...values, [name]: value, ratePerUnit: "", loadSubTotal: ""});
                // Clear the saved values
                formData.delete('ratePerUnit');
                formData.delete('loadSubTotal');
            }
            else{
                formData.set(name, value);
                setValues({ ...values, [name]: value });
            }
      };

      const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loadingModal: true, updatedLoad: "" });

        if(ifFlatRate){
          if(!loadSubTotal){
            setValues({ ...values, error: 'Enter valid load subtotal', loadingModal: "", updatedLoad: '' });
            toastifyMessage("warning", 'Enter valid load subtotal');
            return;
          }
        }

        else if(!ifFlatRate){
          if(!ratePerUnit){
            setValues({ ...values, error: 'Enter valid rate per unit', loadingModal: "", updatedLoad: '' });
            toastifyMessage("warning", 'Enter valid rate per unit');
            return;
          }
        }

        // Set the default value for date if not changed in the form
        if (!formData.get('effectiveDate')) {
          formData.set('effectiveDate', effectiveDate)
        }

        // Set flag for updating the trip rates in all other trip sheets
        formData.set('updateTripSheets', true)


        updatetripsheet(tripSheetId, formData, admin._id, token)
          .then(data => {
              if (data.error) {
                  setValues({ ...values, error: data.error, loadingModal: ""});
              } else {
                  setValues({
                      ...values,
                      ...data,
                      loadingModal: "",
                      error: "",
                      updatedLoad: "Trip rates Updated successfully",
                      formData: new FormData()
                  });
                  updateSheet(tripSheetId, data, effectiveDate);
                  handleClose();
                  toastifyMessage("success", 'Trip rates updated successfully');
              }
          })
          .catch(err => {
            console.log('Error updating the trip rates:', err);
            setValues({ ...values, error: "Error updating the trip rates", loadingModal: "" });
            toastifyMessage("error", 'Error updating the trip rates');
        });

      };

    const updateTripSheetForm = () => (
      <form className="mt-1">
        <div className="form-group d-flex align-items-center">
              <label className="text-dark" style={{ marginRight: '10px', marginBottom: '0' }}>
                Flat Rate
              </label>
              <input
                type="checkbox"
                checked={ifFlatRate}
                onChange={handleChange("ifFlatRate")}
                style={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
              />
        </div>
        <div className="form-group">
              <label className="text-dark"> Effective Date <span className="text-warning">*</span></label>
              <input
                  className="form-control"
                  onChange={handleChange("effectiveDate")}
                  type="date"
                  value={effectiveDate}
                  name="effectiveDate"
              />
          </div>
          {!ifFlatRate && (
            <div className="form-group">
                <label className="text-dark"> Rate Per Unit <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("ratePerUnit")}
                    type="number"
                    value={ratePerUnit}
                    name="ratePerUnit"
                    placeholder="Rate Per Unit"
                />
            </div>
          )}
          {ifFlatRate && (
            <div className="form-group">
                <label className="text-dark"> Load Subtotal <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("loadSubTotal")}
                    type="number"
                    value={loadSubTotal}
                    name="loadSubTotal"
                    placeholder="Load subtotal"
                />
            </div>
          )}
          <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
              Update Trip Rates 
          </button>
      </form>
  )

  const updateTripSheetModal = () => (
    <Modal show={show} onHide={handleClose}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Trip Rates
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                {updateTripSheetForm()}
            </Modal.Body>
  
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
            
            {LightLoaderScreen(loadingModal)}
        </Modal>
  ) 

  const onFlipVerificationStatus = (tripsheetid) => {
    setValues({ ...values, error: "", loading: true, updatedLoad: "" });

    flipVerificationStatusForTheTripSheet(tripsheetid, admin._id, token)
      .then(data => {
          if (data.error) {
              setValues({ ...values, error: data.error, loading: ""});
          } else {
              setValues({
                  ...values,
                  ...data,
                  loading: "",
                  error: "",
                  updatedLoad: "Verfication status updated successfully for the load"
              });

              // Flip the value in the invoice
              flipSheetStatus(tripsheetid, data.isLoadVerified);
              toastifyMessage("success", 'Verfication status updated successfully for the load');
          }
      })
      .catch(err => {
        console.log('Error updating the status of the trip sheet:', err);
        setValues({ ...values, error: "Error updating the status of the trip sheet", loading: "" });
        toastifyMessage("error", 'Error updating the status of the trip sheet');
    });

};

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


    return (
      <>
        <div className="table-container">
          <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 200px)' }}>
              <Table stickyHeader className="mui-table" size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="sticky-header-cell">Sr. No.</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Shipping Date</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Shipping Location</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Ticket Number</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Net Pounds</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Receiving Date</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Receiving Location</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Ticket Number</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Net Pounds</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Commodity</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Truck Number</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Trailer Number</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Customer</StyledTableCell>
                    {
                      isAdmin &&
                      <>
                        <StyledTableCell className="sticky-header-cell" align="center">Driver</StyledTableCell>
                      </>
                    }
                    <StyledTableCell className="sticky-header-cell" align="center">Billable Wait Time (mins)</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Net Unit</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Rate Per Unit</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Load Subtotal</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Fuel S/C Rate</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Fuel Amount</StyledTableCell>
                    {
                      showPhotos &&
                      <>
                        <StyledTableCell className="sticky-header-cell" align="center">Photos</StyledTableCell>
                      </>
                    }
                    {
                      isAdmin &&
                      <>
                        <StyledTableCell className="sticky-header-cell" align="center">Actions</StyledTableCell>
                      </>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tripSheets && tripSheets.map((tripSheet, index) => (
                    <StyledTableRow key={index}>         
                      <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                      <StyledTableCell align="center">{tripSheet.date ? getDate(tripSheet.date) : ""}</StyledTableCell>
                      <StyledTableCell align="center">{getPickupLocation(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{tripSheet.ticketNumberAtShipping ? tripSheet.ticketNumberAtShipping : ""}</StyledTableCell>
                      <StyledTableCell align="center">{(tripSheet.inBoundWeight || tripSheet.inBoundWeight===0 ) ? tripSheet.inBoundWeight : ""}</StyledTableCell>

                      {
                        (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321) 
                        ?
                          <>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                            <StyledTableCell align="center">{"-"}</StyledTableCell>
                          </>
                        :
                          <>
                            <StyledTableCell align="center">{tripSheet.receivingDate ? getDate(tripSheet.receivingDate) : ""}</StyledTableCell>
                            <StyledTableCell align="center">{getDeliveryLocation(tripSheet)}</StyledTableCell>
                            <StyledTableCell align="center">{tripSheet.ticketNumberAtReceiving ? tripSheet.ticketNumberAtReceiving : ""}</StyledTableCell>
                            <StyledTableCell align="center">{(tripSheet.outBoundWeight || tripSheet.outBoundWeight===0) ? tripSheet.outBoundWeight : ""}</StyledTableCell>
                          </>
                      }

                      <StyledTableCell align="center">{getPickupCommodity(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getTruckNo(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getTrailerNo(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getPickupCompany(tripSheet)}</StyledTableCell>
                      {
                        isAdmin &&
                        <>
                          <StyledTableCell align="center">{getDriverName(tripSheet)}</StyledTableCell>
                        </>
                      }

                      <StyledTableCell align="center">{getBillableWaitingPeriod(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getNetUnit(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getRatePerUnit(tripSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getLoadSubTotal(tripSheet, true)}</StyledTableCell>
                      <StyledTableCell align="center">{(tripSheet.fuelRate || tripSheet.fuelRate===0) ? `${tripSheet.fuelRate}%` : ""}</StyledTableCell>
                      <StyledTableCell align="center">{getFuelAmount(tripSheet, true)}</StyledTableCell>
                      {
                        showPhotos &&
                        <>
                          <StyledTableCell align="center">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              { (tripSheet.photo1Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo1Name} caption={"Trip Sheet"} type={'tripphoto1'} />
                              }
                              { ((tripSheet.photo1 && tripSheet.photo1.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto1'} />
                              }
                              { (tripSheet.photo2Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo2Name} caption={"Trip Sheet"} type={'tripphoto2'} />
                              }
                              { ((tripSheet.photo2 && tripSheet.photo2.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto2'} />
                              }
                              { (tripSheet.photo3Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo3Name} caption={"Trip Sheet"} type={'tripphoto3'} />
                              }
                              { ((tripSheet.photo3 && tripSheet.photo3.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto3'} />
                              }
                              { (tripSheet.photo4Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo4Name} caption={"Trip Sheet"} type={'tripphoto4'} />
                              }
                              { ((tripSheet.photo4 && tripSheet.photo4.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto4'} />
                              }
                              { (tripSheet.photo5Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo5Name} caption={"Trip Sheet"} type={'tripphoto5'} />
                              }
                              { ((tripSheet.photo5 && tripSheet.photo5.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto5'} />
                              }
                              { (tripSheet.photo6Name) &&
                                  <ImageCardS3 id={tripSheet._id} photoID={tripSheet.photo6Name} caption={"Trip Sheet"} type={'tripphoto6'} />
                              }
                              { ((tripSheet.photo6 && tripSheet.photo6.data)) &&
                                  <ImageCard id={tripSheet._id} caption={"Trip Sheet"} type={'tripphoto6'} />
                              }                              
                            </Stack>
                          </StyledTableCell>
                          </>
                      }

                      {
                        isAdmin &&
                        <>
                          <StyledTableCell align="center">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                    <IconButton aria-label="add" size="large" color="success" onMouseDown={handleMouseDownPassword} 
                                    onClick={() => {
                                      setValues({
                                        ...values, 
                                        ifFlatRate: tripSheet.ifFlatRate,
                                        ratePerUnit: tripSheet.ratePerUnit,
                                        loadSubTotal: tripSheet.loadSubTotal,
                                        formData: new FormData(), 
                                        tripSheetId: tripSheet._id
                                      })
                                      handleShow()
                                      }}>
                                              <AddIcon fontSize="inherit" />
                                    </IconButton>
                                    
                                    <Link to={editURL+`${tripSheet._id}`}>
                                        <IconButton aria-label="edit" size="large" color="success" onMouseDown={handleMouseDownPassword}>
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Link>

                                    <IconButton size="medium" color="success" onMouseDown={handleMouseDownPassword}
                                    onClick={() => {
                                      onFlipVerificationStatus(tripSheet._id);
                                      }}>
                                              { tripSheet && tripSheet.isLoadVerified ? 
                                                  <span className="badge bg-success" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}> Load verified </span> :
                                                  <span className="badge bg-danger" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}> Verify Load </span>
                                              }
                                    </IconButton>
                              </Stack>
                          </StyledTableCell>
                        </>
                      }
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {updateTripSheetModal()}
          {LoaderScreen(loading)}
          <ToastifyMessage/> 
          </>
    ) 
} 