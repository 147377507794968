import React, {useState} from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import {Link} from "react-router-dom";
import {createtruck} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import {PROVINCES} from '../../core/Constants';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import { toast } from 'react-toastify';

export default function AddTruck() {

    const {admin, token} = isAuthenticated()
    const [values, setValues] = useState({
        truckNumber: "",
        vehicleIdentificationNumber: "",
        plateNumber: "",
        province: "",
        error: false,
        createdTruck: "",
        loading: false,
        didRedirect: false
    });
    
    const { truckNumber, vehicleIdentificationNumber, plateNumber, province, error, createdTruck, loading, didRedirect } = values

    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, createdTruck: "", loading: true})

        // Field validations
        const requiredFields = [truckNumber, vehicleIdentificationNumber, plateNumber, province];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", createdTruck: ""});
            toast.warning('All the fields marked with * are mandatory', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
            return;
        }

        createtruck(admin._id, token, {truckNumber, vehicleIdentificationNumber, plateNumber, province})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", createdTruck: "" })
                    toast.warning('All the fields marked with * are mandatory', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }
                else{
                    setValues({
                        ...values,
                        truckNumber: "",
                        vehicleIdentificationNumber: "",
                        plateNumber: "",
                        province: "",                        
                        loading: "",
                        error: "",
                        createdTruck: "Truck onboarded succesfully"
                    });
                    toast.success('Truck onboarded succesfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }
            })
            .catch(err => {
                console.log('Error onboarding the truck:', err);
                setValues({ ...values, error: "Error onboarding the truck", loading: "" });
                toastifyMessage("error", 'Error onboarding the truck');
              });

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: createdTruck ? "" : "none" }}
        >
            <h4>{createdTruck}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );


    const createTruckForm = () => (
        <>
            <form className="mt-4">
                <div className="form-group">
                    <label className="text-light"> Truck Number <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("truckNumber")}
                        type="text"
                        value={truckNumber}
                        name="truckNumber"
                        placeholder="1001"
                        autoFocus
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="text-light"> Vehicle Identification Number <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("vehicleIdentificationNumber")}
                        type="text"
                        value={vehicleIdentificationNumber}
                        name="vehicleIdentificationNumber"
                        placeholder="YV1MS672892447094"
                    />
                </div>
                <div className="form-group">
                    <label className="text-light"> Plate Number <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("plateNumber")}
                        type="text"
                        value={plateNumber}
                        name="plateNumber"
                        placeholder="ON1MS678"
                    />
                </div>
                <div className="form-group">
                    <label className="text-light"> Province <span className="text-warning">*</span></label>
                    <select
                        onChange={handleChange('province')}
                        className="form-control"
                        placeholder="province"
                    >
                        <option value="" selected={province===""}>Select the Province</option>
                        {PROVINCES &&
                            PROVINCES.map((province, index) => (
                                <option key={index} value={province}>
                                    {province}
                                </option>
                            ))}
                    </select>
                </div>
                        
                <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                    Add Truck 
                </button>
            </form>
            {LoadingScreen(loading)}
        </>
    )

    return (
        <Base
            title="Truck Dashboard"
            description="Add a new Truck"
            className="container bg-info p-4"
        >
            {returnToAdminDashboard()}

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                    {createTruckForm()}
                    {successMessage()}
                    {warningMessage()}
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    )
}