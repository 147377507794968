import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { disAuthenticated } from '../../auth/helper/driverIndex';
import { getincompletelogsheet } from '../helper/driverapicall';
import Base from '../../core/Base';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

const MainLog = () => {
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(true);
    const [logsheetId, setLogsheetId] = useState('');

    const { driver, token } = disAuthenticated();

    const preload = () => {
        getincompletelogsheet(driver._id, token).then(incompleteLogSheet => {
            if (incompleteLogSheet.isIncompleteFlag) {
                setFlag(true);
                setLogsheetId(incompleteLogSheet._id);
            } else {
                setFlag(false);
            }
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching incomplete log sheet:', error);
            setLoading(false);
            toastifyMessage("error", 'Error fetching incomplete log sheet');
        });
    }

    useEffect(() => {
        preload();
    }, []);

    return (
        <Base 
            title="Time Card Routing" 
            description="Select the “Start Time Card” button while picking up the load and “End Time Card” button while dropping the load off" 
            className="container-fluid p-4" 
            style={{ backgroundColor: '#5cb85c' }}>
            <div style={{ minHeight: '100vh', color: 'white' }}>
                <div className="container mt-5">
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        ) : (
                        <div className="row justify-content-center">
                            <div className="col-md-6 d-flex justify-content-around">
                                <Link to={`/driver/create/logsheet`} className={`btn btn-primary btn-lg rounded ${flag ? 'btn-secondary disabled' : ''}`} aria-disabled={flag}>
                                    Start Time Card
                                </Link>
                                <Link to={`/driver/update/logsheet/${logsheetId}`} className={`btn btn-primary btn-lg rounded ${!flag ? 'btn-secondary disabled' : ''}`} aria-disabled={!flag}>
                                    End Time Card
                                </Link>
                            </div>
                            </div>
                        )}
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    );
};

export default MainLog;