import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { gettrailers, deletetrailer } from '../helper/adminapicall';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function ManageTrailers() {
    const { admin, token } = isAuthenticated();
    const [trailers, setTrailers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState("");
    const [error, setError] = useState('');

    // Trailer Search
    const [trailerSearch, setTrailerSearch] = useState("");
    const [filteredTrailers, setFilteredTrailers] = useState([]);

    useEffect(() => {
        preload();
    }, []);

    const preload = () => {
        setLoading(true);
        setError('');
        gettrailers(admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error fetching the trailers. Please try again later');
                } else {
                    setTrailers(data);
                    setFilteredTrailers(data);
                    if(data.length===0){
                        toastifyMessage("warning", 'No trailer found');
                    }
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching the trailer:', err);
                setError('Error fetching the trailer');
                setLoading(false);
                toastifyMessage("error", 'Error fetching the trailer');
            });
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the trailer?");
    const [deleteTrailerId, setDeleteTrailerId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false)
    const handleShowDeleteMessage = () => setShowDeleteMessage(true)

    const deleteThisTrailer = (trailerId) => {
        setLoadingModal(true);
        deletetrailer(trailerId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error deleting the trailer');
                } else {
                    toastifyMessage("success", 'Trailer deleted successfully');
                   
                     // To close the modal
                     handleCancelDeletion();
                     setLoadingModal("");
                
                     // Reload the values
                     setTrailers(trailers.filter(trailer => (trailer._id !== trailerId)));
                     setFilteredTrailers(trailers.filter(trailer => (trailer._id !== trailerId)));
                }
            })
            .catch(err => {
                console.log('Error deleting the trailer:', err);
                setError('Error deleting the trailer');
                setLoading(false);
                toastifyMessage("error", 'Error deleting the trailer');
            });
    };

    // For View Trailer Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [trailerData, setTrailerData] = useState(null);

    const viewTrailerModal = () => (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Trailer Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Number:</b> {trailerData && trailerData.trailerNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>VIN:</b> {trailerData && trailerData.vehicleIdentificationNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Plate:</b> {trailerData && trailerData.plateNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Province:</b> {trailerData && trailerData.province}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const handleTrailerSearch = (event) => {
        setTrailerSearch(event.target.value);
        const searchTerm = (event.target.value).toLowerCase();
        if(searchTerm==="" || !searchTerm){
            setFilteredTrailers(trailers);
            return;
        }
        const filtered = trailers.filter(trailer =>
            (trailer.trailerNumber).toLowerCase().includes(searchTerm)
        );
        setFilteredTrailers(filtered);
    };

    return (
        <Base title="Trailer Dashboard" description="Manage/Edit/Delete trailers">
            <Link className="btn btn-info rounded mb-3" to="/admin/dashboard">
                Admin Home
            </Link>
            <div className="container">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>
                    Trailers
                </h2>
                {loading ? (
                    <LoadingScreen show={loading} />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for a trailer"
                                value={trailerSearch}
                                onChange={handleTrailerSearch}
                            />
                        </div>
                        <div>
                            {filteredTrailers.map((trailer, index) => (
                                <div key={index} className="card text-center mb-3">
                                    <div className="card-body">
                                        <h3 className="card-title text-left" style={{ fontFamily: 'Englebert', color: 'black' }}>
                                            {trailer.trailerNumber}
                                        </h3>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-success rounded mb-2"
                                                    to={`/admin/update/trailer/${trailer._id}`}
                                                >
                                                    Update
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeleteTrailerId(trailer._id)
                                                            handleShowDeleteMessage()
                                                        }    
                                                    }
                                                    className="btn btn-danger rounded mb-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => {
                                                        setTrailerData(trailer);
                                                        handleShow();
                                                    }}
                                                    className="btn btn-info rounded mb-2"
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {viewTrailerModal()}
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTrailer} id={deleteTrailerId} message={deleteMessage} loadingModal={loadingModal}  />
            <ToastifyMessage/> 
        </Base>
    );
}
