import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { Link, useParams } from "react-router-dom";
import { getmerchants, gettrucks, gettrailers, getcommodities, getlocations, gettripsheet, updatetripsheet, droptripsheetphoto } from '../helper/adminapicall';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function EditTripSheetForPartner() {

    const { tripsheetId } = useParams();
    const {admin, token} = isAuthenticated();

    const [values, setValues] = useState({
        truckNo: "",
        otherTruckNo: "",
        truckNum: "",
        trailerNo: "",
        otherTrailerNo: "",
        trailerNum: "",
        pickUpLocation: "",
        otherPickUpLocation: "",
        pickUpCompany: "",
        otherPickUpCompany: "",
        pickUpCommodity: "",
        otherPickUpCommodity: "",
        pickUpTimeIn: "",
        pickUpTimeOut: "",
        inBoundWeight: "",
        ticketNumberAtShipping: "",
        deliveryLocation: "",
        otherDeliveryLocation: "",
        deliveryCommodity: "",
        otherDeliveryCommodity: "",
        deliveryTimeIn: "",
        deliveryTimeOut: "",
        outBoundWeight: "",
        ticketNumberAtReceiving: "",
        deductions: "",
        date: "",
        receivingDate: "",
        isDeliveryIncomplete: false,
        netUnit: "",
        ratePerUnit: "",
        loadSubTotal: "",
        fuelAmt: "",
        fuelRate: "",
        waitRate: "",
        partners: "",
        trucks: "",
        trailers: "",
        commodities: "",
        locations: "",
        photo1: "",
        photo2: "",
        photo3: "",
        photo4: "",
        photo5: "",
        photo6: "",
        photo1Name: "",
        photo2Name: "",
        photo3Name: "",
        photo4Name: "",
        photo5Name: "",
        photo6Name: "",
        loading: false,
        loadingModal: "",
        isLoaded: "",
        error: "",
        UpdateTripSheet: "",
        partnerPickup: "",
        commodityPickup: "",
        commodityDrop: "",
        formData: "",
    });

    const { truckNum, truckNo, otherTruckNo, trailerNum, trailerNo, otherTrailerNo, partnerPickup, commodityPickup, commodityDrop, pickUpLocation, otherPickUpLocation,
        pickUpCompany, otherPickUpCompany, pickUpCommodity, otherPickUpCommodity, pickUpTimeIn, pickUpTimeOut, inBoundWeight, ticketNumberAtShipping,
        deliveryLocation, otherDeliveryLocation, deliveryCommodity, otherDeliveryCommodity, deliveryTimeIn, deliveryTimeOut,
        outBoundWeight, ticketNumberAtReceiving, deductions, date, receivingDate, isDeliveryIncomplete, netUnit, ratePerUnit, loadSubTotal, fuelAmt, fuelRate, waitRate, partners, commodities, locations, trucks,
        trailers, UpdateTripSheet, photo1, photo2, photo3, photo4, photo5, photo6, photo1Name, photo2Name, photo3Name, photo4Name, photo5Name, photo6Name, loading, loadingModal, isLoaded, error, formData } = values;

    // Pickup location search
    const [pickupLocationSearch, setPickupLocationSearch] = useState("");
    const [filteredPickupLocations, setFilteredPickupLocations] = useState([]);

    // Delivery location search
    const [deliveryLocationSearch, setDeliveryLocationSearch] = useState("");
    const [filteredDeliveryLocations, setFilteredDeliveryLocations] = useState([]);

    const ref1 = React.useRef();
    const ref2 = React.useRef();
    const ref3 = React.useRef();
    const ref4 = React.useRef();
    const ref5 = React.useRef();
    const ref6 = React.useRef();    

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getmerchants(admin._id, token).then(data1 => {
            if (data1.error) {
                setValues({ ...values, error: data1,  loading: "", isLoaded: ""});
            } else {
                gettrucks(admin._id, token).then(data2 => {
                    if (data2.error) {
                        setValues({ ...values, error: data2,  loading: "", isLoaded: ""});
                    } else {
                        gettrailers(admin._id, token).then(data3 => {
                            if (data3.error) {
                                setValues({ ...values, error: data3,  loading: "", isLoaded: ""});
                            } else {
                                getcommodities(admin._id, token).then(data4 => {
                                    if (data4.error) {
                                        setValues({ ...values, error: data4,  loading: "", isLoaded: ""});
                                    } else {
                                        gettripsheet(tripsheetId, admin._id, token).then(data5 => {
                                            if (data5.error) {
                                                setValues({ ...values, error: data5,  loading: "", isLoaded: ""});
                                            } else {
                                                getlocations(admin._id, token).then(data6 => {
                                                    if (data6.error) {
                                                        setValues({ ...values, error: data6.error });
                                                    } else{
                                                        const {
                                                            truckNo,
                                                            trailerNo,
                                                            pickUpLocation,
                                                            pickUpCompany,
                                                            pickUpCommodity,
                                                            pickUpTimeIn,
                                                            pickUpTimeOut,
                                                            inBoundWeight,
                                                            ticketNumberAtShipping,
                                                            otherTruckNo,
                                                            otherTrailerNo,
                                                            otherPickUpLocation,
                                                            otherPickUpCompany,
                                                            otherPickUpCommodity,
                                                            otherDeliveryLocation,
                                                            otherDeliveryCommodity,
                                                            deliveryLocation,
                                                            deliveryCommodity,
                                                            deliveryTimeIn,
                                                            deliveryTimeOut,
                                                            outBoundWeight,
                                                            ticketNumberAtReceiving,
                                                            deductions,
                                                            date,
                                                            receivingDate,
                                                            isDeliveryIncomplete,
                                                            ratePerUnit, loadSubTotal, fuelRate, waitRate,
                                                            photo1, photo2, photo3, photo4, photo5, photo6,
                                                            photo1Name, photo2Name, photo3Name, photo4Name, photo5Name, photo6Name
                                                        } = data5;
        
                                                        setValues({
                                                            ...values,
                                                            partners: data1?data1:[],
                                                            trucks: data2?data2:[],
                                                            trailers: data3?data3:[],
                                                            commodities: data4?data4:[],
                                                            locations: data6?data6:[],
                                                            truckNum: truckNo ? truckNo.truckNumber : '',
                                                            trailerNum: trailerNo ? trailerNo.trailerNumber : '',
                                                            partnerPickup: pickUpCompany ? pickUpCompany.partnerName : '',
                                                            commodityPickup: pickUpCommodity ? pickUpCommodity.commodityName : '',
                                                            commodityDrop: deliveryCommodity ? deliveryCommodity.commodityName : '',
                                                           
                                                            otherTruckNo: (otherTruckNo ? otherTruckNo : ""),
                                                            truckNo: (truckNo ? truckNo.truckNumber : (otherTruckNo ? "other" : "")),
                                                            otherTrailerNo: (otherTrailerNo ? otherTrailerNo : ""),
                                                            trailerNo: (trailerNo ? trailerNo.trailerNumber : (otherTrailerNo ? "other" : "")),
                                                            otherPickUpCompany: (otherPickUpCompany ? otherPickUpCompany : ""),
                                                            pickUpCompany: (pickUpCompany ? pickUpCompany.partnerName : (otherPickUpCompany ? "other" : "")),
                                                            otherPickUpCommodity: (otherPickUpCommodity ? otherPickUpCommodity : ""),
                                                            pickUpCommodity: (pickUpCommodity ? pickUpCommodity.commodityName : (otherPickUpCommodity ? "other" : "")),
                                                            otherDeliveryCommodity: (otherDeliveryCommodity ? otherDeliveryCommodity : ""),
                                                            deliveryCommodity: (deliveryCommodity ? deliveryCommodity.commodityName : (otherDeliveryCommodity ? "other" : "")),
        
                                                            otherPickUpLocation: (otherPickUpLocation ? otherPickUpLocation : ""),
                                                            pickUpLocation: (pickUpLocation ? pickUpLocation : (otherPickUpLocation ? otherPickUpLocation : "")),
                                                            otherDeliveryLocation: (otherDeliveryLocation ? otherDeliveryLocation : ""),
                                                            deliveryLocation: (deliveryLocation ? deliveryLocation : (otherDeliveryLocation ? otherDeliveryLocation : "")),
                                                            
                                                            pickUpTimeIn,
                                                            pickUpTimeOut,
                                                            inBoundWeight,
                                                            ticketNumberAtShipping,
                                                            deliveryTimeIn,
                                                            deliveryTimeOut,
                                                            outBoundWeight,
                                                            ticketNumberAtReceiving,
                                                            deductions,
        
                                                            ratePerUnit, loadSubTotal, fuelRate, waitRate,
                                                            date: (date ? date.substring(0, 10) : ""),
                                                            receivingDate: (receivingDate ? receivingDate.substring(0, 10) : ""),
                                                            isDeliveryIncomplete,
                                                            photo1,
                                                            photo2,
                                                            photo3,
                                                            photo4,
                                                            photo5,
                                                            photo6,
                                                            photo1Name,
                                                            photo2Name,
                                                            photo3Name,
                                                            photo4Name,
                                                            photo5Name,
                                                            photo6Name,
                                                            formData: new FormData(),
                                                            loading: "", 
                                                            isLoaded: true,
                                                        });
        
                                                        setPickupLocationSearch(pickUpLocation ? pickUpLocation : (otherPickUpLocation ? otherPickUpLocation : ""));
                                                        setDeliveryLocationSearch(deliveryLocation ? deliveryLocation : (otherDeliveryLocation ? otherDeliveryLocation : ""));
                                                    }
                                                })
                                                .catch(err => {
                                                    console.log('Error preloading the trip sheet:', err);
                                                    setValues({ ...values, error: "Error preloading the trip sheet", loading: "" });
                                                    toastifyMessage("error", 'Error preloading the trip sheet');
                                                });
                                                
                                            }
                                        })
                                        .catch(err => {
                                            console.log('Error preloading the trip sheet:', err);
                                            setValues({ ...values, error: "Error preloading the trip sheet", loading: "" });
                                            toastifyMessage("error", 'Error preloading the trip sheet');
                                        });
                                    }
                                })
                                .catch(err => {
                                    console.log('Error preloading the trip sheet:', err);
                                    setValues({ ...values, error: "Error preloading the trip sheet", loading: "" });
                                    toastifyMessage("error", 'Error preloading the trip sheet');
                                });
                            }
                        })
                        .catch(err => {
                            console.log('Error preloading the trip sheet:', err);
                            setValues({ ...values, error: "Error preloading the trip sheet", loading: "" });
                            toastifyMessage("error", 'Error preloading the trip sheet');
                        });
                    }
                })
                .catch(err => {
                    console.log('Error preloading the trip sheet:', err);
                    setValues({ ...values, error: "Error preloading the trip sheet", loading: "" });
                    toastifyMessage("error", 'Error preloading the trip sheet');
                });
            }
        })
        .catch(err => {
            console.log('Error preloading the trip sheet:', err);
            setValues({ ...values, error: "Error preloading the trip sheet", loading: "" });
            toastifyMessage("error", 'Error preloading the trip sheet');
        });
    };


    useEffect(() => {
        preload();
    }, []);

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: UpdateTripSheet ? "" : "none" }}
        >
            <h4>{UpdateTripSheet}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );

    // Location Search    
    const handlePickupLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setPickupLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPickupLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectPickupLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, pickUpLocation: "" }));
            formData.delete("pickUpLocation");
        }
    };

    const selectPickupLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, pickUpLocation: location.location }));
        setPickupLocationSearch(location.location);
        formData.set("pickUpLocation", location.location);
        setFilteredPickupLocations([]);
    };

    const handleDeliveryLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setDeliveryLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDeliveryLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectDeliveryLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, deliveryLocation: "" }));
            formData.delete("deliveryLocation");
        }
    };

    const selectDeliveryLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, deliveryLocation: location.location }));
        setDeliveryLocationSearch(location.location);
        formData.set("deliveryLocation", location.location);
        setFilteredDeliveryLocations([]);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true, UpdateTripSheet: "" });

        if(truckNo === "other" && (otherTruckNo === "")){ 
            setValues({ ...values, error: 'Enter valid truck number', loading: "", UpdateTripSheet: '' });
            toastifyMessage("warning", 'Enter valid truck number');
            return;
        }

        if(trailerNo === "other" && (otherTrailerNo === "")){ 
            setValues({ ...values, error: 'Enter valid trailer number', loading: "", UpdateTripSheet: '' });
            toastifyMessage("warning", 'Enter valid trailer number');
            return;
        }

        if(pickUpCompany === "other" && (otherPickUpCompany === "")){ 
            setValues({ ...values, error: 'Enter valid customer', loading: "", UpdateTripSheet: '' });
            toastifyMessage("warning", 'Enter valid customer');
            return;
        }

        if(pickUpCommodity === "other" && (otherPickUpCommodity === "")){ 
            setValues({ ...values, error: 'Enter valid commodity name', loading: "", UpdateTripSheet: '' });
            toastifyMessage("warning", 'Enter valid commodity name');
            return;
        }

        if(deliveryCommodity === "other" && (otherDeliveryCommodity === "")){ 
            setValues({ ...values, error: 'Enter valid commodity name', loading: "", UpdateTripSheet: '' });
            toastifyMessage("warning", 'Enter valid commodity name');
            return;
        }

         // Not including check for delivery commodity because that should be same as pickup commodity
         const requiredFieldsToCompleteSheet = [truckNo, trailerNo, pickUpLocation, deliveryLocation, pickUpCompany, pickUpCommodity, pickUpTimeIn, pickUpTimeOut,
            deliveryTimeIn, deliveryTimeOut, date, receivingDate, ((inBoundWeight || inBoundWeight===0)?inBoundWeight.toString():inBoundWeight), ((outBoundWeight || outBoundWeight===0)?outBoundWeight.toString():outBoundWeight),
            ticketNumberAtShipping, ticketNumberAtReceiving];

        // If all the required fields are entered, mark the sheet as completed
        const allRequiredFieldsHaveValue = requiredFieldsToCompleteSheet.every(field => field !== '' && field !== null && field !== undefined);
        if(allRequiredFieldsHaveValue){
            formData.set("isIncompleteFlag", false);
        }

        updatetripsheet(tripsheetId, formData, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: ""});
                    toastifyMessage("error", 'Error while updating the Trip Sheet');
                } else {
                    setValues({
                        ...values,
                        ...data,
                        date : (date ? date.substring(0, 10) : ""),
                        loading: "",
                        error: "",
                        photo1: (data && data.photo1 && data.photo1.data) ? data.photo1 : "",
                        photo2: (data && data.photo2 && data.photo2.data) ? data.photo2 : "",
                        photo3: (data && data.photo3 && data.photo3.data) ? data.photo3 : "",
                        photo4: (data && data.photo4 && data.photo4.data) ? data.photo4 : "",
                        photo5: (data && data.photo5 && data.photo5.data) ? data.photo5 : "",
                        photo6: (data && data.photo6 && data.photo6.data) ? data.photo6 : "",
                        photo1Name: (data && data.photo1Name) ? data.photo1Name : "",
                        photo2Name: (data && data.photo2Name) ? data.photo2Name : "",
                        photo3Name: (data && data.photo3Name) ? data.photo3Name : "",
                        photo4Name: (data && data.photo4Name) ? data.photo4Name : "",
                        photo5Name: (data && data.photo5Name) ? data.photo5Name : "",
                        photo6Name: (data && data.photo6Name) ? data.photo6Name : "",
                        UpdateTripSheet: "Trip sheet Updated successfully",
                        formData: new FormData()
                    });
                    ref1.current.value = "";
                    ref2.current.value = "";
                    ref3.current.value = "";
                    ref4.current.value = "";
                    ref5.current.value = "";
                    ref6.current.value = "";
                    toastifyMessage("success", 'Trip Sheet updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the trip sheet:', err);
                setValues({ ...values, error: "Error updating the trip sheet", loading: "" });
                toastifyMessage("error", 'Error updating the trip sheet');
            });
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the photo?");
    const [deletePhotoSheetId, setDeletePhotoSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    const dropPhoto = (type) => {
        setValues({...values, error: "", loading: true, loadingModal: true, UpdateTripSheet: ""})

        droptripsheetphoto(tripsheetId, {type}, admin._id, token)
        .then(data => {
          if(data.error){
                setValues({...values, error:data.error, loading: "", loadingModal: "", UpdateTripSheet: ""})
                toastifyMessage("error", "Error deleting the photo");
          }else{
              setValues({
                  ...values,
                  ...data,
                  date : (date ? date.substring(0, 10) : ""),
                  loading: false,
                  loadingModal: false,
                  error: "",
                  photo1Name: (data && data.photo1Name) ? data.photo1Name : "",
                  photo2Name: (data && data.photo2Name) ? data.photo2Name : "",
                  photo3Name: (data && data.photo3Name) ? data.photo3Name : "",
                  photo4Name: (data && data.photo4Name) ? data.photo4Name : "",
                  photo5Name: (data && data.photo5Name) ? data.photo5Name : "",
                  photo6Name: (data && data.photo6Name) ? data.photo6Name : "",
                  UpdateTripSheet: "Photo deleted successfully",
              })
              ref1.current.value = "";
              ref2.current.value = "";
              ref3.current.value = "";
              ref4.current.value = "";
              ref5.current.value = "";
              ref6.current.value = "";
              toastifyMessage("success", "Photo deleted successfully");
              handleCancelDeletion();  
          }
      })
      .catch(err => {
        console.log('Error deleting the photo:', err);
        setValues({ ...values, error: "Error deleting the photo", loadingModal: "" });
        toastifyMessage("error", 'Error deleting the photo');
        });  
    }

    const handleChange = name => event => {
        const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4") || (name === "photo5") || (name === "photo6")) ? event.target.files[0] : event.target.value;
 
        if(((name === "trailerNo") || (name === "truckNo") || (name === "pickUpCompany") || (name === "pickUpCommodity") || (name === "deliveryCommodity")) && ((value !== "other"))){
            if(name==="trailerNo"){
                setValues({ ...values, [name]: value, otherTrailerNo: "" })
                formData.set(name, value)
                if(formData.get("otherTrailerNo") || formData.get("otherTrailerNo")==="" ){
                    formData.delete("otherTrailerNo")
                }
            }

            if(name==="truckNo"){
                setValues({ ...values, [name]: value, otherTruckNo: "" })
                formData.set(name, value)
                if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
                    formData.delete("otherTruckNo")
                }
            }
            
            if(name==="pickUpCompany"){
                let p = partners.find(partner => partner._id === value);
                let fr = fuelRate?fuelRate:"";
                if(p && p.fuelRate){
                    fr = p.fuelRate;
                }

                let wr = waitRate?waitRate:"";
                if(p && p.waitRate){
                    wr = p.waitRate;
                }
                setValues({ ...values, [name]: value, otherPickUpCompany:"", fuelRate: fr, waitRate: wr});
                // Set Fuel Rate for the sheet
                if(fr){
                    formData.set('fuelRate', fr);
                }

                // Set Fuel Rate for the sheet
                if(wr){
                    formData.set('waitRate', wr);
                }

                // Set pickup company
                setValues({ ...values, [name]: value, otherPickUpCompany:""});
                formData.set(name, value)
                if(formData.get("otherPickUpCompany") || formData.get("otherPickUpCompany")===""){
                    formData.delete("otherPickUpCompany")
                }
            }

            if(name==="pickUpCommodity"){
                setValues({ ...values, [name]: value, otherPickUpCommodity: "" })
                formData.set(name, value)
                if(formData.get("otherPickUpCommodity") || formData.get("otherPickUpCommodity")===""){
                    formData.delete("otherPickUpCommodity")
                }
            }

            if(name==="deliveryCommodity"){
                setValues({ ...values, [name]: value, otherDeliveryCommodity: "" })
                formData.set(name, value)
                if(formData.get("otherDeliveryCommodity") || formData.get("otherDeliveryCommodity")===""){
                    formData.delete("otherDeliveryCommodity")
                }
                if(value===""){
                    formData.delete("deliveryCommodity")
                }
            }
        }
        else{
            setValues({ ...values, [name]: value })
            formData.set(name, value)
        }
    };

    const updateTripSheetForm = () => (
        <form className="mt-4" >

            <div className="form-group">
                <label className="text-light"> Load Rejected <span className="text-warning">*</span></label>
                <select
                  onChange={handleChange("isDeliveryIncomplete")}
                  className="form-control"
                  value={isDeliveryIncomplete}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
            </div>

            <div className="form-group">
                <label className="text-light"> Truck <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("truckNo")}
                    className="form-control"
                >
                    {trucks &&
                        trucks.map((truck, index) => (
                            <option key={index} value={truck._id} selected={truck.truckNumber===truckNum}>
                                {truck.truckNumber}
                            </option>
                        ))}
                        <option value="other" selected={truckNo==="other" || truckNo===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck Number"
                    value={otherTruckNo} />
            </div>

            <div className="form-group">
                <label className="text-light"> Trailer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("trailerNo")}
                    className="form-control"
                >
                   {trailers &&
                        trailers.map((trailer, index) => (
                            <option key={index} value={trailer._id} selected={trailer.trailerNumber===trailerNum}>
                                {trailer.trailerNumber}
                            </option>
                        ))}
                        <option value="other" selected={trailerNo==="other" || trailerNo===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( trailerNo && (trailerNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Trailer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTrailerNo')}
                    className="form-control"
                    placeholder="Trailer Number"
                    value={otherTrailerNo} />
            </div>

            <div className="form-group">
                <label className="text-light">Customer<span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("pickUpCompany")}
                    className="form-control"
                >
                    {partners &&
                        partners.map((partner, index) => (
                            <option key={index} value={partner._id} selected={partner.partnerName===partnerPickup}>
                                {partner.partnerName}
                            </option>
                        ))}
                </select>
            </div>

            <div className="form-group">
                <label className="text-light">Pickup Location <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a location"
                    value={pickupLocationSearch}
                    onChange={handlePickupLocationSearch}
                />
                {pickupLocationSearch && (
                    <ul className="list-group mt-2">
                        {filteredPickupLocations.map((location, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => selectPickupLocation(location)}
                            >
                                {location.location}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <div className="form-group">
                <label className="text-light">End Location <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a location"
                    value={deliveryLocationSearch}
                    onChange={handleDeliveryLocationSearch}
                />
                {deliveryLocationSearch && (
                    <ul className="list-group mt-2">
                        {filteredDeliveryLocations.map((location, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => selectDeliveryLocation(location)}
                            >
                                {location.location}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <div className="form-group">
                <label className="text-light">Pick Up Commodity <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("pickUpCommodity")}
                    className="form-control"
                >
                     {commodities &&
                        commodities.map((commodity, index) => (
                            <option key={index} value={commodity._id} selected={commodity.commodityName===commodityPickup}>
                                {commodity.commodityName}
                            </option>
                        ))}
                        <option value="other" selected={pickUpCommodity==="other" || pickUpCommodity===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( pickUpCommodity && (pickUpCommodity === "other")) ? "" : "none" }}>
                <label className="text-light">Other Commodity <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherPickUpCommodity')}
                    className="form-control"
                    placeholder="Commodity"
                    value={otherPickUpCommodity} />
            </div>

            <div className="form-group">
                <label className="text-light">Delivery Commodity </label>
                <select
                    onChange={handleChange("deliveryCommodity")}
                    className="form-control"
                >
                    <option value="" selected={deliveryCommodity===""}>Select the Commodity</option>
                    {commodities &&
                        commodities.map((commodity, index) => (
                            <option key={index} value={commodity._id} selected={commodity.commodityName===commodityDrop}>
                                {commodity.commodityName}
                            </option>
                        ))}
                        <option value="other" selected={deliveryCommodity==="other" || deliveryCommodity===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( deliveryCommodity && (deliveryCommodity === "other")) ? "" : "none" }}>
                <label className="text-light">Other Commodity <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherDeliveryCommodity')}
                    className="form-control"
                    placeholder="Commodity"
                    value={otherDeliveryCommodity} />
            </div>

            <div className="form-group">
                <label className="text-light">Pick up Time In <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("pickUpTimeIn")}
                    type="time"
                    className="form-control"
                    name={pickUpTimeIn}
                    value={pickUpTimeIn}
                    placeholder={pickUpTimeIn}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Pick Up Time Out <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("pickUpTimeOut")}
                    type="time"
                    className="form-control"
                    value={pickUpTimeOut}
                    name={pickUpTimeOut}
                    placeholder={pickUpTimeOut}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Pickup Date <span className="text-warning">*</span></label>
                <input
                    type="date"
                    className="form-control"
                    onChange={handleChange("date")}
                    value={((date).substring(0,10))}
                    name={date}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Delivery Time In <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("deliveryTimeIn")}
                    type="time"
                    className="form-control"
                    name={deliveryTimeIn}
                    value={deliveryTimeIn}
                    placeholder={deliveryTimeIn}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Delivery Time Out <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("deliveryTimeOut")}
                    type="time"
                    className="form-control"
                    value={deliveryTimeOut}
                    name={deliveryTimeOut}
                    placeholder={deliveryTimeOut}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Delivery Date <span className="text-warning">*</span></label>
                <input
                    type="date"
                    className="form-control"
                    onChange={handleChange("receivingDate")}
                    value={((receivingDate).substring(0,10))}
                    name={receivingDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Inbound Weight (in lbs) <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("inBoundWeight")}
                    type="number"
                    className="form-control"
                    value={inBoundWeight}
                    name={inBoundWeight}
                    placeholder={inBoundWeight}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Outbound Weight (in lbs) <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("outBoundWeight")}
                    type="number"
                    className="form-control"
                    value={outBoundWeight}
                    name={outBoundWeight}
                    placeholder={outBoundWeight}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Ticket Number At Shipping <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("ticketNumberAtShipping")}
                    type="text"
                    className="form-control"
                    value={ticketNumberAtShipping}
                    name={ticketNumberAtShipping}
                    placeholder={ticketNumberAtShipping}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Ticket Number At Delivery <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("ticketNumberAtReceiving")}
                    type="text"
                    className="form-control"
                    value={ticketNumberAtReceiving}
                    name={ticketNumberAtReceiving}
                    placeholder={ticketNumberAtReceiving}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Deductions </label>
                <input
                    onChange={handleChange("deductions")}
                    type="text"
                    className="form-control"
                    value={deductions}
                    name={deductions}
                    placeholder={deductions}
                />
            </div>
            <div className="form-group">
              <label className="text-light"> Rate Per Unit </label>
              <input
                  className="form-control"
                  onChange={handleChange("ratePerUnit")}
                  type="number"
                  value={ratePerUnit}
                  name="ratePerUnit"
                  placeholder={ratePerUnit}
              />
            </div>
            <div className="form-group">
              <label className="text-light"> Load Subtotal </label>
              <input
                  className="form-control"
                  onChange={handleChange("loadSubTotal")}
                  type="number"
                  value={loadSubTotal}
                  name="loadSubTotal"
                  placeholder={loadSubTotal}
              />
            </div>
            <div className="form-group">
              <label className="text-light"> Fuel S/C Rate </label>
              <input
                  className="form-control"
                  onChange={handleChange("fuelRate")}
                  type="number"
                  value={fuelRate}
                  name="fuelRate"
                  placeholder={fuelRate}
              />
            </div>

            { ((photo1Name || (photo1 && photo1.data)) || (photo2Name || (photo2 && photo2.data)) || (photo3Name ||(photo3 && photo3.data)) || (photo4Name || (photo4 && photo4.data)) || (photo5Name ||(photo5 && photo5.data)) || (photo6Name || (photo6 && photo6.data))) &&
                <div className="form-group">
                    <label className="text-light"> Trip Photos <span className="text-warning"> &nbsp;&nbsp;&nbsp; </span></label>
                    
                    { (photo1Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={tripsheetId} photoID={photo1Name} caption={"Trip Sheet"} type={'tripphoto1'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo1")}
                                        type="file"
                                        name="photo1"
                                        accept="image/*"
                                        placeholder="Choose an image for the log sheet"
                                        ref={ref1}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo1Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }

                    { (photo1 && photo1.data) &&
                        <ImageCard id={tripsheetId} caption={"Trip Sheet"} type={'tripphoto1'} />
                    }

                    { (photo2Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={tripsheetId} photoID={photo2Name} caption={"Trip Sheet"} type={'tripphoto2'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo2")}
                                        type="file"
                                        name="photo2"
                                        accept="image/*"
                                        placeholder="Choose an image for the log sheet"
                                        ref={ref2}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo2Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }

                    { (photo2 && photo2.data) &&
                        <ImageCard id={tripsheetId} caption={"Trip Sheet"} type={'tripphoto2'} />
                    }

                    { (photo3Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={tripsheetId} photoID={photo3Name} caption={"Trip Sheet"} type={'tripphoto3'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo3")}
                                        type="file"
                                        name="photo3"
                                        accept="image/*"
                                        placeholder="Choose an image for the log sheet"
                                        ref={ref3}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo3Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }

                    { (photo3 && photo3.data) &&
                        <ImageCard id={tripsheetId} caption={"Trip Sheet"} type={'tripphoto3'} />
                    } 

                    { (photo4Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={tripsheetId} photoID={photo4Name} caption={"Trip Sheet"} type={'tripphoto4'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo4")}
                                        type="file"
                                        name="photo4"
                                        accept="image/*"
                                        placeholder="Choose an image for the log sheet"
                                        ref={ref4}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo4Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }  

                    { (photo4 && photo4.data) &&
                        <ImageCard id={tripsheetId} caption={"Trip Sheet"} type={'tripphoto4'} />
                    }  

                    { (photo5Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={tripsheetId} photoID={photo5Name} caption={"Trip Sheet"} type={'tripphoto5'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo5")}
                                        type="file"
                                        name="photo5"
                                        accept="image/*"
                                        placeholder="Choose an image for the log sheet"
                                        ref={ref5}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo5Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    } 

                    { (photo5 && photo5.data) &&
                        <ImageCard id={tripsheetId} caption={"Trip Sheet"} type={'tripphoto5'} />
                    }

                    { (photo6Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={tripsheetId} photoID={photo6Name} caption={"Trip Sheet"} type={'tripphoto6'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo6")}
                                        type="file"
                                        name="photo6"
                                        accept="image/*"
                                        placeholder="Choose an image for the log sheet"
                                        ref={ref6}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo6Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    } 

                    { (photo6 && photo6.data) &&
                        <ImageCard id={tripsheetId} caption={"Trip Sheet"} type={'tripphoto6'} />
                    }                

                </div>
            }

            {
                (!photo1Name || !photo2Name || !photo3Name || !photo4Name || !photo5Name || !photo6Name)
                &&
                <span className="text-white"> Add Trip Photos &nbsp;&nbsp;&nbsp;</span>
            }

            {
                !photo1Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo1")}
                            type="file"
                            name="photo1"
                            accept="image/*"
                            placeholder="Choose an image for the trip sheet"
                            ref={ref1}
                        />
                    </label>
                </div>
            }

            {
                !photo2Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo2")}
                            type="file"
                            name="photo2"
                            accept="image/*"
                            placeholder="Choose an image for the trip sheet"
                            ref={ref2}
                        />
                    </label>
                </div>
            }

            {
                !photo3Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo3")}
                            type="file"
                            name="photo3"
                            accept="image/*"
                            placeholder="Choose an image for the trip sheet"
                            ref={ref3}
                        />
                    </label>
                </div>
            }
            
            {
                !photo4Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo4")}
                            type="file"
                            name="photo4"
                            accept="image/*"
                            placeholder="Choose an image for the trip sheet"
                            ref={ref4}
                        />
                    </label>
                </div>
            }

            {
                !photo5Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo5")}
                            type="file"
                            name="photo5"
                            accept="image/*"
                            placeholder="Choose an image for the trip sheet"
                            ref={ref5}
                        />
                    </label>
                </div>
            }

            {
                !photo6Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo6")}
                            type="file"
                            name="photo6"
                            accept="image/*"
                            placeholder="Choose an image for the trip sheet"
                            ref={ref6}
                        />
                    </label>
                </div>
            }


            <button type="submit" onClick={onSubmit} className="btn btn-success mb-4 mt-3">
                Submit Load
            </button>

        </form>
    );

    return (
        <Base
            title="Trip Sheet Dashboard!"
            description="Edit the trip sheet"
            className="container bg-success p-4"
        >
            <Link to="/admin/dashboard" className="btn btn-md btn-dark mb-3">
                Admin Home
            </Link>

            <div className="row bg-dark text-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1 ">
                    {isLoaded && updateTripSheetForm()}
                    {successMessage()}
                    {warningMessage()}
                    {LoadingScreen(loading)}
                </div>
            </div>
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={dropPhoto} id={deletePhotoSheetId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/>
        </Base>
    );
}