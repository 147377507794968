import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getauditlogs } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import AuditLogTableView from '../../core/helper/TableViews/AuditLogTableView';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toastifyMessage } from '../../core/helper/Toast';
import moment from 'moment';
import '../AdminSideSheet.css';

export default function ViewAuditLogs() {
    const { admin, token } = isAuthenticated();

    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        auditLogs: []
    });

    const { startDate, endDate, error, loading, loadingModal, isLoaded, auditLogs } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getauditlogs(admin._id, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
                toastifyMessage("error", "Error fetching the audit logs");
            } else {
                setValues({ ...values, auditLogs: data, error: "", loading: "", isLoaded: true });
                if (data.length === 0) {
                    toastifyMessage("warning", "No audit logs found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the audit logs:', err);
            setValues({ ...values, error: "Error loading the audit logs", loading: "" });
            toastifyMessage("error", 'Error loading the audit logs');
        });
    };

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
            </div>
        </div>
    );

    return (
        <Base title="Audit Dashboard" description="Manage/View">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="audit-dashboard">
                <div className="audit-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}> {"Audit Logs"} </h2>
                    {dateFilterForm()}
                </div>

                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the audit logs</h4>}
                    {isLoaded && !error && auditLogs.length > 0 && (
                        <div id="audit-table">
                            <AuditLogTableView auditLogs={auditLogs} />
                        </div>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    )
}
