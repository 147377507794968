import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getCustomerTripRateHistory, deletelocationmetric } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import TripRateHistoryTableView from '../../core/helper/TableViews/TripRateHistoryTableView';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toastifyMessage } from '../../core/helper/Toast';
import moment from 'moment';
import '../AdminSideSheet.css';

export default function ManageTripRates() {
    const { admin, token } = isAuthenticated();
    const { partnerId } = useParams();
    let customerName = ""

    // Extracting URL parameters
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    customerName = query.get('customer');

    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        tripRates: []
    });

    // Filter state
    const [filters, setFilters] = useState({
        location: ''
      });
      const [filteredTripRates, setFilteredTripRates] = useState([]);

    const { startDate, endDate, error, loading, loadingModal, isLoaded, tripRates } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getCustomerTripRateHistory(partnerId, {startDate, endDate}, admin._id, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
                toastifyMessage("error", "Error fetching the trip rates history for the customer");
            } else {
                setValues({ ...values, tripRates: data, error: "", loading: "", isLoaded: true });
                if (data.length === 0) {
                    toastifyMessage("warning", "Trip rate history not found");
                }
            }
        })
        .catch(err => {
            console.log('Error fetching the trip rates history for the customer:', err);
            setValues({ ...values, error: "Error fetching the trip rates history for the customer", loading: "" });
            toastifyMessage("error", 'Error fetching the trip rates history for the customer');
        });
    };

    useEffect(() => {
        preload();
    }, []);

    // Updates the filtered list whenever the tripsheets are updated
    useEffect(() => {
        const filtered = tripRates.filter((rate) => {
            return (
              (!filters.location || ((rate.location1?rate.location1:"").toLowerCase().includes(filters.location.toLowerCase()) || ((rate.location2?rate.location2:"").toLowerCase().includes(filters.location.toLowerCase()))))
            );
          });
        setFilteredTripRates(filtered);
        
    }, [values.tripRates, filters]);

    // Handle search for loads
    const handleSearch = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
          }));
    };

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
            </div>
        </div>
    );

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the trip rate?");
    const [deleteTripRateId, setDeleteTripRateId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Trip Rate
    const deleteThisTripRate = (tripRateId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletelocationmetric(tripRateId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, loadingModal: "" });
                    toastifyMessage("error", 'Error deleting the trip rate');
                } else {
                    toastifyMessage("success", "Trip rate deleted successfully");
         
                    // To close the modal & reload the values
                    handleCancelDeletion();
                    setValues({ ...values, error: "", loadingModal: "", tripRates: (tripRates.filter(rate => (rate._id !== tripRateId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the trip rate:', err);
                setValues({ ...values, error: "Error deleting the trip rate", loadingModal: "" });
                toastifyMessage("error", 'Error deleting the trip rate');
            });
    };
    

    return (
        <Base title="Trip Rate History Dashboard" description="Manage/View">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="audit-dashboard">
                <div className="audit-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}> {customerName ? customerName.toUpperCase() :"Trip Rate"} </h2>
                    {dateFilterForm()}
                </div>

                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the trip rate history</h4>}
                    {isLoaded && !error && tripRates.length > 0 && (
                        <>
                            <div className="filters">
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Location:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the rate history using the location"
                                         name="location"
                                         value={filters.location}
                                         onChange={handleSearch}
                                     />
                                </div>
                            </div>
                            <div id="audit-table">
                                <TripRateHistoryTableView tripRates={filteredTripRates} setDeleteTripRateId={setDeleteTripRateId} handleShowDeleteMessage={handleShowDeleteMessage} />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTripRate} id={deleteTripRateId} message={deleteMessage} loadingModal={loadingModal}/>
            <ToastifyMessage />
        </Base>
    )
}