import React, {useState, useEffect} from 'react'
import Base from "../../core/Base"
import { Link } from "react-router-dom"
import {createmerchant } from "../helper/adminapicall"
import {isAuthenticated} from "../../auth/helper/adminIndex" 
import LoadingScreen from '../../core/helper/LoadingScreen'
import ToastifyMessage from '../../core/helper/ToastifyMessage'
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddPartner() {

    const {admin, token} = isAuthenticated()

    const [values, setValues] = useState({
        partnerName: "",
        city: "",
        state: "",
        country: "",
        streetAddress: "",
        pincode: "",
        contact: "",
        altcontact: "",
        logo: "",
        email: "",
        fuelRate: "",
        waitRate: "",
        username: "",
        password: "",
        cnfpassword: "",
        loading: false,
        error: "",
        CreatedPartner: "",
        getaRedirect: false,
        formData: ""
    });

    const ref1 = React.useRef();

    const { partnerName, city, state, country, streetAddress, pincode, contact, altcontact, logo, email, fuelRate, waitRate, username, password, cnfpassword, loading, 
      error, CreatedPartner, getaRedirect, formData } = values;

      useEffect(() => {
        setValues({...values, formData: new FormData()});
    }, [] )

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{display: CreatedPartner ? "" : "none"}}
        >
            <h4>{CreatedPartner} onboarded successfully </h4>
        </div>
    )

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{display: error ? "" : "none"}}
        >
            <h4>{error} </h4>
        </div>
    )

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: "", loading: true, CreatedPartner: ""})

        // Password validation
        if (password !== cnfpassword) {
          setValues({ ...values, error: 'Passwords do not match', CreatedPartner: "" });
          toastifyMessage("warning", "Passwords do not match");
          return;
        }
        
        //Field validation
        const requiredFields = [partnerName, city, state, country, streetAddress, pincode, contact, email, username, password, cnfpassword];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", CreatedPartner: ""});
               toastifyMessage("warning", "All the fields marked with * are mandatory");
            return;
        }

        createmerchant(formData, admin._id, token)
        .then(data => {
            if(data.error){
                setValues({...values, error:data.error, CreatedPartner: "", loading: false})
                  toastifyMessage("error", "Error onboarding the customer");
            }else{
                setValues({
                    ...values,
                    partnerName: "",
                    city: "",
                    state: "",
                    country: "",
                    streetAddress: "",
                    pincode: "",
                    contact: "",
                    altcontact: "",
                    fuelRate: "",
                    waitRate: "",
                    logo: "",
                    email: "",
                    username: "",
                    password: "",
                    cnfpassword: "",
                    loading: false,
                    error: "",
                    CreatedPartner: data.partnerName
                });
                ref1.current.value = "";
                toastifyMessage("success", "Customer onboarded successfully");
            }
        }

        )
        .catch(err => {
          console.log('Error onboarding the customer:', err);
          setValues({ ...values, error: "Error onboarding the customer", loading: "" });
          toastifyMessage("error", 'Error onboarding the customer');
        });
    }

    const handleChange = name => event => {
        const value = name ==="logo" ? event.target.files[0] : event.target.value
        formData.set(name, value)
        setValues({...values, [name]:value})
        if(value==="" && formData.get(name)==="" && name!=="logo"){
          formData.delete(name);
      }
    };

    const onboardPartnerForm = () => (
        <form className="mt-4">
          <div className="form-group">
          <label className="text-light"> Customer Name <span className="text-warning">*</span> </label>
            <input
              onChange={handleChange("partnerName")}
              name="partnerName"
              className="form-control"
              placeholder="Company Name"
              value={partnerName}
            />
          </div>
          <div className="form-group">
          <label className="text-light"> Street Address <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("streetAddress")}
              name="streetAddress"
              className="form-control"
              placeholder="Street Address"
              value={streetAddress}
            />
          </div>
          
          <div className="form-group">
          <label className="text-light"> City <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("city")}
              name="city"
              className="form-control"
              placeholder="City"
              value={city}
            />
          </div>
          <div className="form-group">
          <label className="text-light"> Province <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("state")}
              name="state"
              className="form-control"
              placeholder="Province"
              value={state}
            />
          </div>
          <div className="form-group">
          <label className="text-light"> Country <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("country")}
              name="country"
              className="form-control"
              placeholder="Country"
              value={country}
            />
          </div>
                    
          <div className="form-group">
          <label className="text-light"> Postal Code <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("pincode")}
              name="pincode"
              className="form-control"
              placeholder="Postal Code"
              value={pincode}
            />
          </div>
          
          <div className="form-group">
          <label className="text-light"> Contact <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("contact")}
              name="contact"
              className="form-control"
              placeholder="Contact"
              value={contact}
              type="number"
            />
          </div>

          <div className="form-group">
          <label className="text-light"> Alternate Contact </label>
            <input
              onChange={handleChange("altcontact")}
              name="altcontact"
              className="form-control"
              placeholder="Alternate Contact"
              value={altcontact}
              type="number"
            />
          </div>

          <div className="form-group">
            <label className="text-light"> Fuel S/C Rate (in %) </label>
            <input
              onChange={handleChange("fuelRate")}
              name="fuelRate"
              className="form-control"
              placeholder="Fuel Rate"
              value={fuelRate}
              type="number"
            />
          </div>

          <div className="form-group">
            <label className="text-light"> Waiting Rate (in $) </label>
            <input
              onChange={handleChange("waitRate")}
              name="waitRate"
              className="form-control"
              placeholder="Waiting Rate"
              value={waitRate}
              type="number"
            />
          </div>

          <div className="form-group">
          <label className="text-light"> Username <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("username")}
              name="username"
              className="form-control"
              placeholder="Username"
              value={username}
            />
          </div>

          <div className="form-group">
          <label className="text-light"> Email <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("email")}
              name="email"
              className="form-control"
              placeholder="Email"
              value={email}
            />
          </div>

          <div className="form-group">
          <label className="text-light"> Password <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("password")}
              name="password"
              className="form-control"
              placeholder="Password"
              value={password}
              type="password"
            />
          </div>

          <div className="form-group">
          <label className="text-light"> Confirm Password <span className="text-warning">*</span></label>
            <input
              onChange={handleChange("cnfpassword")}
              name="cnfpassword"
              className="form-control"
              placeholder="Confirm Password"
              value={cnfpassword}
              type="password"
            />
          </div>
          
          <span className="text-white">Logo</span>
          <div className="form-group">
            <label className="btn btn-block btn-success">
              <input
                onChange={handleChange("logo")}
                type="file"
                name="logo"
                accept="image"
                placeholder="Choose a file"
                ref={ref1}
              />
            </label>
          </div>
          
          <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4">
            Onboard Partner
          </button>
        </form>
      );
    
    
    
    
    
    return(
        <Base
        title="Customer Dashboard"
        description="Onboard a new customer company"
        className="container bg-success p-4"
        >
        
        <Link to="/admin/dashboard" className="btn brn-md btn-dark mb-3">
            Admin Home
        </Link>

        <div className="row bg-dark test-white rounded center">
            <div className="col-md-8 offset-md-1 col-sm-8 offset-sm-2 col-10 offset-1 ">
                {onboardPartnerForm()}
                {successMessage()}
                {warningMessage()}
                {LoadingScreen(loading)}
                <ToastifyMessage/>
            </div>
        </div>

        </Base>
    )
}