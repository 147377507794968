import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import Base from "../core/Base";
import { API } from "../backend"
import LoadingScreen from '../core/helper/LoadingScreen';
import ToastifyMessage from '../core/helper/ToastifyMessage';
import {toastifyMessage} from '../core/helper/Toast';

export default function DriverReset() {
    const [contact, setContact] = useState("");
    const [licenceNo, setLicenceNo] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [driverId, setDriverId] = useState(null);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const history = useHistory();

    const verifyDriver = () => {

        // Field validations
        const requiredFields = [contact, licenceNo];
        if (requiredFields.some(field => !field)) {
               toastifyMessage("warning", "Fields marked with * are mandatory");
            return;
        }

        setLoading(true);
        fetch(`${API}/driver/verify`, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                contact,
                licenceNo
            })
        }).then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.error) {
                    toastifyMessage("error", data.error);
                    setMessage(data.error);
                } else {
                    setDriverId(data.driverId);
                    setMessage("Driver verified. Please enter your new password.");
                }
            })
            .catch(err => {
                console.log('Error verifying the driver details:', err);
                setLoading(false);
                toastifyMessage("error", 'Error logging in as driver');
              });
    };

    const resetPassword = () => {

        // Field validations
        const requiredFields = [newPassword, confirmPassword];
        if (requiredFields.some(field => !field)) {
               toastifyMessage("warning", "Fields marked with * are mandatory");
            return;
        }
        
        if (newPassword !== confirmPassword) {
            setMessage("Passwords do not match");
            return;
        }
        setLoading(true);
        fetch(`${API}/driver/resetpassword`, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                driverId,
                newPassword
            })
        }).then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.error) {
                    toastifyMessage("error", data.error);
                    setMessage(data.error);
                } else {
                    setMessage("Password reset successfully. You can now log in with your new password.");
                    setNewPassword("");
                    setConfirmPassword("");
                }
            })
            .catch(err => {
                console.log('Error resetting the password:', err);
                setLoading(false);
                toastifyMessage("error", 'Error resetting the password');
              });
    };

    return (
        <Base
            title="Reset Password"
            description=""
            className="container bg-info p-4"
        >
            <div className="row bg-white rounded">
                <div className="col-sm-8 col-10">
                    {driverId ? (
                        <form>
                            <div className="form-group">
                                <p className="text-dark mt-3 ml-3">Enter New Password <span className="text-info">*</span></p>
                                <input
                                    type="password"
                                    className="form-control my-3 ml-3"
                                    onChange={(event) => setNewPassword(event.target.value)}
                                    value={newPassword}
                                    required
                                    placeholder="New Password"
                                />
                                <p className="text-dark mt-3 ml-3">Confirm New Password <span className="text-info">*</span></p>
                                <input
                                    type="password"
                                    className="form-control my-3 ml-3"
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    value={confirmPassword}
                                    required
                                    placeholder="Confirm Password"
                                />
                                <button
                                    type="button"
                                    onClick={resetPassword}
                                    className="btn btn-outline-info ml-3"
                                >
                                    Reset Password
                                </button>
                            </div>
                        </form>
                    ) : (
                        <form>
                            <div className="form-group">
                                <p className="text-dark mt-3 ml-3">Enter Contact Number <span className="text-info">*</span></p>
                                <input
                                    type="number"
                                    className="form-control my-3 ml-3"
                                    onChange={(event) => setContact(event.target.value)}
                                    value={contact}
                                    required
                                    placeholder="Contact Number"
                                />
                                <p className="text-dark mt-3 ml-3">Enter License Number <span className="text-info">*</span></p>
                                <input
                                    type="text"
                                    className="form-control my-3 ml-3"
                                    onChange={(event) => setLicenceNo(event.target.value)}
                                    value={licenceNo}
                                    required
                                    placeholder="License Number"
                                />
                                <button
                                    type="button"
                                    onClick={verifyDriver}
                                    className="btn btn-outline-info ml-3"
                                >
                                    Verify
                                </button>
                            </div>
                        </form>
                    )}
                    <h6 className="text-dark ml-3">
                        Don't have an account? &nbsp;
                        <Link to="/">
                            <span className="text-info">Contact Admin</span>
                        </Link>
                    </h6>
                    {LoadingScreen(loading)}
                    {message && <div className="alert alert-info mt-3">{message}</div>}
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    );
}
