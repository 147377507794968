import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ModalImage from "react-modal-image";
import { API } from '../../backend';
import profile_picture from "../../images/driver_profile.jpeg";
import photoIconImage from "../../images/photoIconImage.png";
import driverIconImage from "../../images/driverIconImage.png";
import licenceIconImage from "../../images/licenceIconImage.png";
import tripIconImage from "../../images/tripIconImage.png";
import fuelIconImage from "../../images/fuelIconImage.png";
import './ImageCard.css';

const imageTypes = {
    driverPhoto: { path: 'driverPhoto', icon: driverIconImage },
    licencePhoto: { path: 'driverLicencePhoto', icon: licenceIconImage },
    fuelPhoto1: { path: 'fuelPhoto1', icon: fuelIconImage },
    fuelPhoto2: { path: 'fuelPhoto2', icon: fuelIconImage },
    photo1: { path: 'logPhoto1', icon: tripIconImage },
    photo2: { path: 'logPhoto2', icon: tripIconImage },
    photo3: { path: 'logPhoto3', icon: tripIconImage },
    photo4: { path: 'logPhoto4', icon: tripIconImage },
    tripphoto1: { path: 'tripPhoto1', icon: tripIconImage },
    tripphoto2: { path: 'tripPhoto2', icon: tripIconImage },
    tripphoto3: { path: 'tripPhoto3', icon: tripIconImage },
    tripphoto4: { path: 'tripPhoto4', icon: tripIconImage },
    tripphoto5: { path: 'tripPhoto5', icon: tripIconImage },
    tripphoto6: { path: 'tripPhoto6', icon: tripIconImage },
    logo: { path: 'clientLogo', icon: driverIconImage }
};

export default function ImageCardS3({id, photoID, caption, type}) {

    const { path, icon } = imageTypes[type] || {};

    // URLs for the image
    const imageurl = id && path ? `${API}/${path}/${id}` : "";
    const [signedUrl, setSignedUrl] = useState('');

    // Method to fetch the URL
    const fetchSignedUrl = async () => {
      try {
        const response = await axios.get(imageurl);
        setSignedUrl(response.data);
      } catch (error) {
          console.error('Error fetching the image from AWS:', error);
      }
    };

  useEffect(() => {
  }, []);

  return (
    <span onClick={fetchSignedUrl}>
        <ModalImage
          small={icon || photoIconImage}
          large={signedUrl}
          hideDownload={false}
          showRotate={true}
          alt={caption}
          className='ImageCard'
        />
      </span>
  );
}
