import React, { useState, useEffect } from 'react'
import Base from "../../core/Base"
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { Link, Redirect } from "react-router-dom"
import { createlogsheet, gettrucks, gettrailers, getmerchants, getdrivers } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddLogSheetAdmin() {

  const { admin, token } = isAuthenticated();

  const [values, setValues] = useState({
    driverNo: "",
    truckNo: "",
    otherTruckNo: "",
    trailerNo: "",
    otherTrailerNo: "",
    date: moment().format('YYYY-MM-DD'),
    timeIn: "",
    timeOut: "",
    downTime: 0,
    startOdometer: "",
    endOdometer: "",
    reimbursement: false,
    nightOut: false,
    nightOutPurpose: "",
    numberOfLoads: "",
    pickUpCity: "",
    deliveryCity: "",
    ratePerHour: "",
    deductions: "",
    deductionNotes: "",
    reimbursementDate: "",
    reimbursementPurpose: "",
    reimbursementLocation: "",
    reimbursementAmount: "",
    photo1: "",
    photo2: "",
    photo3: "",
    photo4: "",
    pickup: "",
    partners: "",
    drivers: "",
    trucks: "",
    trailers: "",
    loading: false,
    isLoaded: "",
    error: "",
    CreatedLogSheet: "",
    formData: "",
    didRedirect: false
  });

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();

  const { driverNo, truckNo, otherTruckNo, trailerNo, otherTrailerNo, date, timeIn, timeOut, downTime, pickUpCity, deliveryCity, startOdometer, endOdometer, numberOfLoads, photo1, photo2, photo3, photo4, drivers, partners, trucks, trailers,
    reimbursement, nightOut, nightOutPurpose, ratePerHour, deductions, deductionNotes, reimbursementDate, reimbursementPurpose, reimbursementLocation, reimbursementAmount, loading, isLoaded, error, CreatedLogSheet, formData, didRedirect } = values;

  //Method to preload Trucks, Trailers and Partners
  const preload = () => {
    setValues({ ...values, loading: true, isLoaded: ""})
    getmerchants(admin._id, token).then(data1 => {
      if (data1.error) {
        setValues({ ...values, error: data1.error,  loading: "", isLoaded: ""});
      } else {
        gettrucks(admin._id, token).then(data2 => {
          if (data2.error) {
            setValues({ ...values, error: data2.error,  loading: "", isLoaded: ""});
          } else {
            gettrailers(admin._id, token).then(data3 => {
              if (data3.error) {
                setValues({ ...values, error: data3.error,  loading: "", isLoaded: ""});
              } else {
                getdrivers(admin._id, token).then(data4 => {
                  if (data4.error) {
                    setValues({ ...values, error: data4.error,  loading: "", isLoaded: ""});
                  } else {
                    setValues({ ...values, 
                      partners: data1, trucks: data2, trailers: data3, drivers: data4,
                      loading: "", isLoaded: true, formData: new FormData() });
                  }
                })
                .catch(err => {
                  console.log('Error loading the time card form:', err);
                  setValues({ ...values, error: "Error loading the time card form", loading: "" });
                  toastifyMessage("error", 'Error loading the time card form');
                });
              }
            })
            .catch(err => {
              console.log('Error loading the time card form:', err);
              setValues({ ...values, error: "Error loading the time card form", loading: "" });
              toastifyMessage("error", 'Error loading the time card form');
            });
          }
        })
        .catch(err => {
          console.log('Error loading the time card form:', err);
          setValues({ ...values, error: "Error loading the time card form", loading: "" });
          toastifyMessage("error", 'Error loading the time card form');
        });
      }
    })
    .catch(err => {
      console.log('Error loading the time card form:', err);
      setValues({ ...values, error: "Error loading the time card form", loading: "" });
      toastifyMessage("error", 'Error loading the time card form');
    });
  }

  useEffect(() => {
    preload();
  }, [])



  const successMessage = () => (
    <div className="alert alert-success mt-3"
      style={{ display: CreatedLogSheet ? "" : "none" }}
    >
      <h4>{CreatedLogSheet}</h4>
    </div>
  )

  const warningMessage = () => (
    <div className="alert alert-danger mt-3"
      style={{ display: error ? "" : "none" }}
    >
      <h4> {error} </h4>
    </div>
  )
      
  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true, CreatedLogSheet: "" });

    // Field Validation
    const requiredFields = [driverNo, truckNo, trailerNo, date, timeIn, timeOut, ((startOdometer || startOdometer===0)?startOdometer.toString():startOdometer),
      ((endOdometer || endOdometer===0)?endOdometer.toString():endOdometer), ratePerHour,  pickUpCity, deliveryCity];
    const isReimbursementFilled = reimbursementDate && reimbursementPurpose && reimbursementLocation && reimbursementAmount;
    if (
        requiredFields.some(field => !field) ||
        (reimbursement && (reimbursement===true || reimbursement==="true") && !isReimbursementFilled) ||
        reimbursement === '' ||
        nightOut === '') {
        setValues({ ...values, error: 'All the fields marked with * are mandatory', loading:"",  CreatedLogSheet:"" });
        toastifyMessage("warning", 'All the fields marked with * are mandatory');
        return;
    }
    
    if(truckNo === "other" && (otherTruckNo === "")){ 
      setValues({ ...values, error: 'Enter valid truck number', loading: "", CreatedLogSheet: '' });
      toastifyMessage("warning", 'Enter valid truck number');
      return;
    }

    if(trailerNo === "other" && (otherTrailerNo === "")){ 
      setValues({ ...values, error: 'Enter valid trailer number', loading: "", CreatedLogSheet: '' });
      toastifyMessage("warning", 'Enter valid trailer number');
      return;
    }

    // Set the default fields in the form
    if (!formData.get('date')) {
      formData.set('date', date)
    }

    if (!formData.get('ratePerHour')) {
      formData.set('ratePerHour', ratePerHour)
    }

    if (!formData.get('isIncompleteFlag')) {
      formData.set('isIncompleteFlag', false)
    }

    if (!formData.get('reimbursement')) {
      formData.set('reimbursement', reimbursement)
    }

    if (!formData.get('nightOut')) {
      formData.set('nightOut', nightOut)
    }

    createlogsheet(admin._id, formData, token)
      .then(data => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: "", CreatedLogSheet: "" })
          toastifyMessage("error", "Error while creating the time card");
        } else {
          setValues({
            ...values,
            driverNo: "",
            truckNo: "",
            otherTruckNo: "",
            trailerNo: "",
            otherTrailerNo: "",
            date: "",
            timeIn: "",
            timeOut: "",
            downTime: "",
            startOdometer: "",
            endOdometer: "",
            reimbursement: false,
            nightOut: false,
            nightOutPurpose: "",
            numberOfLoads: "",
            ratePerHour: "",
            deductions: "",
            deductionNotes: "",
            pickUpCity: "",
            deliveryCity: "",
            reimbursementDate: "",
            reimbursementPurpose: "",
            reimbursementLocation: "",
            reimbursementAmount: "",
            photo1: "",
            photo2: "",
            photo3: "",
            photo4: "",
            pickup: "",
            loading: "",
            error: "",
            CreatedLogSheet: "Time card created successfully",
            formData: new FormData()
          })

          ref1.current.value = "";
          ref2.current.value = "";
          ref3.current.value = "";
          ref4.current.value = "";
          toastifyMessage("success", 'Time card updated successfully');
        }
        
      })
      .catch(err => {
        console.log('Error creating the time card:', err);
        setValues({ ...values, error: "Error creating the time card", loading: "", CreatedLogSheet: "" });
        toastifyMessage("error", 'Error creating the time card');
    });
  }

  const handleChange = name => event => {
    const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4")) ? event.target.files[0] : event.target.value
    
    if ((name === "reimbursement") && (value === "false" || value === false)) {
      setValues({
        ...values,
        reimbursementDate: "",
        reimbursementPurpose: "",
        reimbursementLocation: "",
        reimbursementAmount: "",
        [name]: value
      })
      formData.delete("reimbursementDate")
      formData.delete("reimbursementPurpose")
      formData.delete("reimbursementLocation")
      formData.delete("reimbursementAmount")
      formData.set(name, value)
    }

    else if ((name === "nightOut") && (value === "false" || value === false)) {
      setValues({
        ...values,
        nightOutPurpose: "",
        [name]: value
      })
      formData.delete("nightOutPurpose")
      formData.set(name, value)
    }

    else if(((name === "trailerNo") || (name === "truckNo")) && (value !== "other")){
      if(name==="trailerNo"){
          setValues({ ...values, [name]: value, otherTrailerNo: "" })
          formData.set(name, value)
          if(formData.get("otherTrailerNo") || formData.get("otherTrailerNo")===""){
              formData.delete("otherTrailerNo")
          }
      }

      if(name==="truckNo"){
          setValues({ ...values, [name]: value, otherTruckNo: "" })
          formData.set(name, value)
          if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
              formData.delete("otherTruckNo")
          }
      }
  }

  else if(name === "driverNo"){
      setValues({ ...values, [name]: value, ratePerHour: drivers.find(driver => driver._id === value)?.ratePerHour ?? ""})
      formData.set(name, value)
  }
  
  else{
      setValues({ ...values, [name]: value })
      formData.set(name, value)
  }
  };


  const createLogSheetForm = () => (
    <form className="mt-4" style={{ display: (trucks != "" || trailers != "" || partners != "" || drivers != "") ? "" : "none" }}>

      <div className="form-group">
        <label className="text-light"> Driver <span className="text-warning">*</span></label>
        <select
          onChange={handleChange("driverNo")}
          className="form-control"
          placeholder="driverNo"
        >
          <option value="" selected={driverNo===""}>Select the Driver</option>
          {drivers && drivers.map((driver, index) => {
            return (
              <option key={index} value={driver._id}>
                {driver.firstName + ' ' + driver.lastName }
              </option>
            )
          })}
        </select>
      </div>

      <div className="form-group">
        <label className="text-light"> Truck <span className="text-warning">*</span></label>
        <select
          onChange={handleChange("truckNo")}
          className="form-control"
          placeholder="Truck"
        >
          <option value="" selected={truckNo===""}>Select the Truck</option>
          {trucks && trucks.map((truck, index) => {
            return (
              <option key={index} value={truck._id}>
                {truck.truckNumber}
              </option>
            )
          })}
          <option value="other" selected={truckNo==="other"}>Other</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck Number"
                    value={otherTruckNo} />
      </div>

      <div className="form-group">
        <label className="text-light"> Trailer <span className="text-warning">*</span></label>
        <select
          onChange={handleChange("trailerNo")}
          className="form-control"
          placeholder="Trailer"
        >
          <option value="" selected={trailerNo===""}>Select the Trailer</option>
          {trailers && trailers.map((trailer, index) => {
            return (
              <option key={index} value={trailer._id}>
                {trailer.trailerNumber}
              </option>
            )
          })}
          <option value="other" selected={trailerNo==="other"}>Other</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( trailerNo && (trailerNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Trailer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTrailerNo')}
                    className="form-control"
                    placeholder="Trailer Number"
                    value={otherTrailerNo} />
      </div>

      <div className="form-group">
        <label className="text-light"> Start City <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("pickUpCity")}
          name="pickUpCity"
          className="form-control"
          placeholder="Pickup City"
          value={pickUpCity}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Finish City <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("deliveryCity")}
          name="deliveryCity"
          className="form-control"
          placeholder="Delivery City"
          value={deliveryCity}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Date <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("date")}
          type="date"
          name="date"
          className="form-control"
          value={date}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Time In  <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("timeIn")}
          type="time"
          name="timeIn"
          className="form-control"
          value={timeIn}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Time Out  <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("timeOut")}
          type="time"
          name="timeOut"
          className="form-control"
          value={timeOut}
        />
      </div>

      <div className="form-group">
          <label className="text-light">Down Time (in minutes) <span className="text-warning">*</span></label>
          <input
              type="number"
              className="form-control"
              onChange={handleChange("downTime")}
              value={downTime}
              name="downTime"
              placeholder="Down Time"
          />
      </div>

      <div className="form-group">
                <label className="text-light">Deductions (in hours) </label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("deductions")}
                    value={deductions}
                    name={deductions}
                    placeholder={deductions}
                />
      </div>

      <div className="form-group">
          <label className="text-light"> Deduction Notes </label>
          <textarea
                      onChange={handleChange("deductionNotes")}
                      name={deductionNotes}
                      className="form-control"
                      type="string"
                      placeholder="Notes"
                      value={deductionNotes}
          />
      </div>

      <div className="form-group">
        <label className="text-light"> Start Odometer <span className="text-warning">*</span></label>
        <input
          type="number"
          onChange={handleChange("startOdometer")}
          name="startOdometer"
          className="form-control"
          placeholder="Start Odometer"
          value={startOdometer}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> End Odometer <span className="text-warning">*</span></label>
        <input
          type="number"
          onChange={handleChange("endOdometer")}
          name="endOdometer"
          className="form-control"
          placeholder="End Odometer"
          value={endOdometer}
        />
      </div>

      <div className="form-group">
                <label className="text-light"> Number of Loads </label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("numberOfLoads")}
                    value={numberOfLoads}
                    name="numberOfLoads"
                    placeholder="Number of Loads"
                />
        </div>

      <div className="form-group">
        <label className="text-light"> Night out </label>
        <select
          onChange={handleChange("nightOut")}
          className="form-control"
        >
          <option value={false} selected={nightOut===false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( nightOut && (nightOut === true || nightOut === "true")) ? "" : "none" }}>
        <label className="text-light"> Night out Purpose </label>
        <textarea
          onChange={handleChange("nightOutPurpose")}
          name="nightOutPurpose"
          className="form-control"
          placeholder="Purpose of Night out"
          value={nightOutPurpose}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Reimbursement </label>
        <select
          onChange={handleChange("reimbursement")}
          className="form-control"
        >
          <option value={false} selected={reimbursement===false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Date <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("reimbursementDate")}
          type="date"
          name="reimbursementDate"
          className="form-control"
          value={reimbursementDate}
        />
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Location <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("reimbursementLocation")}
          name="reimbursementLocation"
          className="form-control"
          placeholder="Ex. Brampton, ON"
          value={reimbursementLocation}
        />
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Purpose <span className="text-warning">*</span></label>
        <textarea
          onChange={handleChange("reimbursementPurpose")}
          name="reimbursementPurpose"
          className="form-control"
          placeholder="Purpose of reimbursement"
          value={reimbursementPurpose}
        />
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Amount <span className="text-warning">*</span></label>
        <input
          type="number"
          onChange={handleChange("reimbursementAmount")}
          name="reimbursementAmount"
          className="form-control"
          placeholder="Ex. 150"
          value={reimbursementAmount}
        />
      </div>

      <span className="text-white"> Trip Photos </span>
      <div className="form-group">
        <label className="btn btn-block btn-success rounded">
          <input
            onChange={handleChange("photo1")}
            type="file"
            name="photo1"
            accept="image/*"
            placeholder="Choose an Image for the Details"
            ref={ref1}
          />
        </label>
      </div>
      <div className="form-group">
        <label className="btn btn-block btn-success rounded">
          <input
            onChange={handleChange("photo2")}
            type="file"
            name="photo2"
            accept="image/*"
            placeholder="Choose an Image for the Details"
            ref={ref2}
          />
        </label>
      </div>
      <div className="form-group">
        <label className="btn btn-block btn-success rounded">
          <input
            onChange={handleChange("photo3")}
            type="file"
            name="photo3"
            accept="image/*"
            placeholder="Choose an Image for the Details"
            ref={ref3}
          />
        </label>
      </div>
      <div className="form-group">
        <label className="btn btn-block btn-success rounded">
          <input
            onChange={handleChange("photo4")}
            type="file"
            name="photo4"
            accept="image/*"
            placeholder="Choose an Image for the Details"
            ref={ref4}
          />
        </label>
      </div>

      <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4 mt-3">
        Submit Time Card
      </button>
    </form>
  );

  return (
    <Base
      title="Time Card dashboard"
      description="Add time card"
      className="container bg-success p-4"
    >

      <Link to="/admin/dashboard" className="btn brn-md btn-dark mb-3">
        Admin Home
      </Link>

      <div className="row bg-dark test-white rounded center">
        <div className="col-md-8 offset-md-1 col-10 offset-1 ">
          {isLoaded && createLogSheetForm()}
          {successMessage()}
          {warningMessage()}
          {LoadingScreen(loading)}
          <ToastifyMessage/> 
        </div>
      </div>

    </Base>
  )
}