import React from 'react'
import "../styles.css"
import Base from "./Base"
import truck_logo from "../images/ttms_truck.png"
import logo from "../images/ttms_logo_1.png"

export default function Home() {
    return (
        <Base title="Triple Trans Mill Services Limited" description="Logistics Management System" className="container-fluid py-1 px-4" imageURL={logo} isHomePage={true}>
            <div className="row justify-content-center">
                <div className="col-lg-0">
                    <img className="mb-5" src={truck_logo} alt="Triple Trans Mill Services Ltd." title="Triple Trans Mill Services Ltd."></img>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-12 col-md-1 col-lg-2">
                </div>
            </div>
        </Base>
    )
}
