import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { disAuthenticated } from "../../auth/helper/driverIndex";
import { gettripsheetsfordriver, getdriver, deletetripsheet } from '../helper/driverapicall';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import TripSheetTableView from '../../core/helper/TripSheetTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../DriverSideSheet.css';
import { getTruckNo_2, getTrailerNo_2, getPickupCompany_2, getDeliveryLocation, getPickupLocation, getPickupCommodity, getDate } from '../../core/helper/helperFunctions';

export default function ManageTripSheet() {
    const { driver, token } = disAuthenticated();
    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        tripSheets: [],
        firstName: driver && driver.firstName ? driver.firstName : "",
        lastName: driver && driver.lastName ? driver.lastName : ""
    });

    const { startDate, endDate, loading, loadingModal, error, isLoaded, tripSheets, firstName, lastName } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        gettripsheetsfordriver(driver._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
            } else {
                setValues({ ...values, tripSheets: data, loading: "", isLoaded: true})
                if(data.length===0){
                    toastifyMessage("warning", 'No trip sheet found');
              }
            }
        })
        .catch(err => {
            console.log('Error loading the trip sheets', err);
            setValues({ ...values, error: "Error loading the trip sheets", loading: "" });
            toastifyMessage("error", 'Error loading the trip sheets');
          });      
    };

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Trip Sheet`, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`Driver: ${firstName} ${lastName}`, 10, 30);
        doc.text(`Report Duration: ${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 10, 35);

        const tableColumns = ["Sr. No.", "Pickup Date", "Pickup Scale In", "Pickup Scale Out", "Customer", "Pickup Location", "Ticket #", "Inbound Weight", "Delivery Date", "Delivery Scale In", "Delivery Scale Out", "Drop Location", "Ticket #", "Outbound Weight", "Deductions", "Commodity", "Truck", "Trailer", "Status"];

        const tableRows = tripSheets.map((tripSheet, index) => {
            const row = [
                index + 1,
                tripSheet.date ? getDate(tripSheet.date) : "",
                tripSheet.pickUpTimeIn || "",
                tripSheet.pickUpTimeOut || "",
                getPickupCompany_2(tripSheet, true) || "",
                getPickupLocation(tripSheet) || "",
                tripSheet.ticketNumberAtShipping || "",
                (tripSheet.inBoundWeight || tripSheet.inBoundWeight===0) ? tripSheet.inBoundWeight : "",
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.receivingDate ? getDate(tripSheet.receivingDate) : ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.deliveryTimeIn || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.deliveryTimeOut || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (getDeliveryLocation(tripSheet) || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.ticketNumberAtReceiving || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : ((tripSheet.outBoundWeight || tripSheet.outBoundWeight===0) ? tripSheet.outBoundWeight : ""),
                (tripSheet.deductions || tripSheet.deductions===0) ? tripSheet.deductions : "",
                getPickupCommodity(tripSheet, true) || "",
                getTruckNo_2(tripSheet, true) || "",
                getTrailerNo_2(tripSheet, true) || "",
                tripSheet.isDeliveryIncomplete ?
                    "Rejected" :  
                    (
                        (tripSheet.typeOfLoad && tripSheet.typeOfLoad === 311) ? "No Load" :
                        (tripSheet.typeOfLoad && tripSheet.typeOfLoad === 321) ? "Yard Work" :
                        "Completed"
                    )
            ];
            return row;
        });

        doc.autoTable({
            startY: 45,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        // Add the footer strip
        let finalY = doc.previousAutoTable.finalY + 10;

        const stripY = finalY + 50;
        const stripHeight = 5;

        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }

        const filename = (firstName && lastName) ? ('trip_sheets_' + (firstName?firstName:"") + (lastName?lastName:"") + '.pdf') : "trip_sheets.pdf";
        doc.save(filename);
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download
                </button>
            </div>
        </div>
    );

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the trip sheet?");
    const [deleteTripSheetId, setDeleteTripSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Trip Sheet using the Admin privilege
    const deleteThisTripSheet = (tripSheetId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletetripsheet(tripSheetId, driver._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, loadingModal: "" });
                    toastifyMessage("error", 'Error deleting the trip sheet');
                } else {
                    toastifyMessage("success", "Trip Sheet deleted successfully");
         
                    // To close the modal & reload the values
                    handleCancelDeletion();
                    setValues({ ...values, error: "", loadingModal: "", tripSheets: (tripSheets.filter(sheet => (sheet._id !== tripSheetId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the trip sheet:', err);
                setValues({ ...values, error: "Error deleting the trip sheet", loadingModal: "" });
                toastifyMessage("error", 'Error deleting the trip sheet');
            });  
    };

    return (
        <Base title="Trip Sheet Dashboard" description="Manage/Edit the trip sheets">
            <div className="trip-dashboard">
                <div className="trip-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5">Trip Sheets</h2>
                </div>
                <div>{dateFilterForm()}</div>
                <div id="trip-sheet-table">
                    <TripSheetTableView tripSheets={tripSheets} editURL={`/driver/update/tripsheet/`} isAggregated={true} isAdmin={false} isDriver={true} setDeleteTripSheetId={setDeleteTripSheetId} handleShowDeleteMessage={handleShowDeleteMessage} />
                </div>
            </div>
            {LoadingScreen(loading)}
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTripSheet} id={deleteTripSheetId} message={deleteMessage} loadingModal={loadingModal}/>
            <ToastifyMessage />
        </Base>
    );
};

