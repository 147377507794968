import { API } from "../../backend"
import axios from 'axios';

// Admin
// Onboard an Admin
export const createadmin = (adminid, token, admin) => {
    return fetch (`${API}/admin/create/${adminid}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(admin)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// get all admins
export const getadmins = (adminid, token) => {
    return fetch(`${API}/admin/admins/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete an admin
export const deleteadmin = (deletedAdminId, adminId, token) => {
    return fetch(`${API}/admin/admin/${deletedAdminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get an admin
export const getadmin = (showAdminId, adminId, token) => {
    return fetch(`${API}/admin/admin/${showAdminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update an admin
export const updateadmin = (updatedAdminId, token, adminId, updatedAdmin) => {
    return fetch(`${API}/admin/admin/${updatedAdminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(updatedAdmin)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

// Location Metrics
// Add a Location Metric
export const createlocationmetric = (adminid, token, locationmetric) => {
    return fetch (`${API}/admin/locationmetric/create/${adminid}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(locationmetric)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// get all location metrics
export const getlocationmetrics = (adminid, token) => {
    return fetch(`${API}/admin/locationmetrics/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete a location metric
export const deletelocationmetric = (locationMetricId, adminId, token) => {
    return fetch(`${API}/admin/locationmetric/${locationMetricId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a location metric
export const getlocationmetric = (locationMetricId, adminId, token) => {
    return fetch(`${API}/admin/locationmetric/${locationMetricId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a location

export const updatelocationmetric = (locationMetricId, token, adminId, locationMetric) => {
    return fetch(`${API}/admin/locationmetric/${locationMetricId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(locationMetric)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };


// Truck
// Add a Truck
export const createtruck = (adminid, token, truck) => {
    return fetch (`${API}/admin/truck/create/${adminid}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(truck)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// get all trucks
export const gettrucks = (adminid, token) => {
    return fetch(`${API}/admin/trucks/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete a truck
export const deletetruck = (truckId, adminId, token) => {
    return fetch(`${API}/admin/truck/${truckId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a truck
export const gettruck = (truckId, adminId, token) => {
    return fetch(`${API}/admin/truck/${truckId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a truck

export const updatetruck = (truckId, token, adminId, truck) => {
    return fetch(`${API}/admin/truck/${truckId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(truck)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };


// Trailer
// Add a trailer
export const createtrailer = (adminid, token, trailer) => {
    return fetch (`${API}/admin/trailer/create/${adminid}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(trailer)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// get all trailers
export const gettrailers = (adminid, token) => {
    return fetch(`${API}/admin/trailers/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete a trailer
export const deletetrailer = (trailerId, adminId, token) => {
    return fetch(`${API}/admin/trailer/${trailerId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a trailer
export const gettrailer = (trailerId, adminId, token) => {
    return fetch(`${API}/admin/trailer/${trailerId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a trailer

export const updatetrailer = (trailerId, token, adminId, trailer) => {
    return fetch(`${API}/admin/trailer/${trailerId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(trailer)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };


  // Card
  // Add a new card
  export const createcard = (adminid, token, card) => {
    return fetch (`${API}/admin/card/create/${adminid}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(card)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// get all cards
export const getcards = (adminid, token) => {
    return fetch(`${API}/admin/cards/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete a card
export const deletecard = (cardId, adminId, token) => {
    return fetch(`${API}/admin/card/${cardId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a card
export const getcard = (cardId, adminId, token) => {
    return fetch(`${API}/admin/card/${cardId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a card

export const updatecard = (cardId, token, adminId, card) => {
    return fetch(`${API}/admin/card/${cardId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(card)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

// Commodities
// Add a commodity
export const createcommodity = (adminid, token, commodity) => {
    return fetch (`${API}/admin/commodity/create/${adminid}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(commodity)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// get all commodities
export const getcommodities = (adminid, token) => {
    return fetch(`${API}/admin/commodities/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete a commodity
export const deletecommodity = (commodityId, adminId, token) => {
    return fetch(`${API}/admin/commodity/${commodityId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a commodity
export const getcommodity = (commodityId, adminId, token) => {
    return fetch(`${API}/admin/commodity/${commodityId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a commodity

export const updatecommodity = (commodityId, token, adminId, commodity) => {
    return fetch(`${API}/admin/commodity/${commodityId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(commodity)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };



// Driver Call

// Add a Driver
 export const createdriver = (driver, adminId, token) => {
    return fetch (`${API}/admin/driversignup/${adminId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
       },
    body: driver
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// get all drivers
export const getdrivers = (adminId, token) => {
    return fetch(`${API}/admin/drivers/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// delete a driver
export const deletedriver = (driverId,token,adminId) => {
    return fetch(`${API}/admin/driver/${driverId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a driver
export const getdriver = (driverId, adminId, token) => {
    return fetch(`${API}/admin/driver/${driverId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a driver

export const updatedriver = (driverId, driver, adminId, token) => {
    return fetch(`${API}/admin/driver/${driverId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         },
      body: driver
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };
  
// Drop driver photo
export const dropdriverphoto = (driverId, driver, adminId, token) => {
    return fetch (`${API}/admin/driver/drop/photo/${driverId}/${adminId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(driver)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// Reset rate per hour
export const resetRatePerHour = (driverId, newRatePerHour, adminId, token) => {
    return fetch(`${API}/admin/driver/update/tripcard/rateperhour/${driverId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(newRatePerHour)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

  // Reset rate per hour
export const resetNightoutRate = (newNightOutRate, adminId, token) => {
    return fetch(`${API}/admin/driver/update/tripcard/nightOutRate/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(newNightOutRate)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

  
// Partner Calls

// Reset fuel rate for customer
export const resetFuelRate = (partnerId, newFuelRate, adminId, token) => {
    return fetch(`${API}/admin/partner/update/tripsheet/fuelrate/${partnerId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(newFuelRate)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
};

// Add a Partner
 export const createmerchant = (partner, adminId, token) => {
    return fetch (`${API}/admin/partnersignup/${adminId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
       },
    body: partner
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// get all partners
export const getmerchants = (adminId, token) => {
    return fetch(`${API}/admin/partners/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// delete a partner
export const deletemerchant = (partnerId,adminId,token) => {
    return fetch(`${API}/admin/partner/${partnerId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a partner
export const getmerchant = (partnerId,adminId,token) => {
    return fetch(`${API}/admin/partner/${partnerId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a partner

export const updatemerchant = (partnerId, partner, adminId, token) => {
    return fetch(`${API}/admin/partner/${partnerId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         },
      body: partner
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

// Drop customer logo
export const droppartnerlogo = (partnerId, partner, adminid, token) => {
    return fetch (`${API}/admin/partner/drop/photo/${partnerId}/${adminid}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(partner)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

  // Admin privileges for Fuel Log Sheet

  // Add a fuel log sheet
export const createfuellogsheet = (adminId, fuelLogDetails, token) => {
    return fetch(`${API}/admin/fuelLogSheet/create/${adminId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: fuelLogDetails
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

  // View all fuel log sheets for an individual drivr
  export const getFuelLogSheetsForDriver = (adminId, driverId, filterDate, token) => {
    return fetch(`${API}/admin/driver/fuelLogSheet/${driverId}/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

  // View all fuel sheets between 2 dates
  export const getallfuelsheets = (adminId, filterDate, token) => {
    return fetch(`${API}/admin/fuelSheets/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
    };

  // get a fuel log sheet
    export const getfuellogsheet = (fuelLogSheetId, adminId, token) => {
    return fetch(`${API}/admin/fuelLogSheet/${fuelLogSheetId}/${adminId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Update a fuel log sheet
export const updatefuellogsheet = (fuelLogSheetId, fuelLogDetails, adminId, token) => {
    return fetch(`${API}/admin/fuelLogSheet/update/${adminId}/${fuelLogSheetId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: fuelLogDetails
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// delete a fuel log sheet
export const deletefuellogsheet = (fuelLogSheetId, adminId, token) => {
    return fetch(`${API}/admin/fuelLogSheet/delete/${adminId}/${fuelLogSheetId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}

// Drop fuel sheet photo
export const dropfuelsheetphoto = (fuelLogSheetId, fuelLogDetails, adminId, token) => {
    return fetch (`${API}/admin/fuelLogSheet/drop/photo/${fuelLogSheetId}/${adminId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(fuelLogDetails)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

   // Admin privileges for Log Sheets
   // Create log sheet
   export const createlogsheet = (adminid, loadDetails, token) => {
       return fetch(`${API}/admin/logsheet/create/${adminid}`, {
           method: "POST",
           headers: {
               Accept: "application/json",
               Authorization: `Bearer ${token}`
           },
           body: loadDetails   
       })
           .then(response => {
               return response.json();
           })
           .catch(err => console.log(err));
   };   
   // View all log sheets for an individual driver
   export const getlogsheetsfordriver = (adminId, driverId, filterDate, token) => {
    return fetch(`${API}/admin/driver/logSheet/${driverId}/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

   // View all log sheets between 2 dates
   export const getalltripcards = (adminId, filterDate, token) => {
    return fetch(`${API}/admin/tripcards/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
    };

    // View all unverified log sheets between 2 dates
   export const getallunverifiedtripcards = (adminId, filterDate, token) => {
    return fetch(`${API}/admin/unverified/timecards/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
    };

   // get a log sheet
   export const getlogsheet = (logSheetId, adminId, token) => {
    return fetch(`${API}/admin/logSheet/${logSheetId}/${adminId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Update a log sheet
export const updatelogsheet = (logSheetId, logDetails, adminId, token) => {
    return fetch(`${API}/admin/logSheet/update/${adminId}/${logSheetId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: logDetails
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// delete a log sheet
export const deletelogsheet = (logSheetId, adminId, token) => {
    return fetch(`${API}/admin/logSheet/delete/${adminId}/${logSheetId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}

// Flip the verification status of the trip card
export const flipVerificationStatusForTheTripCard = (logSheetId, adminId, token) => {
    return fetch(`${API}/admin/logSheet/flip/status/${adminId}/${logSheetId}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         }   
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

// Drop log sheet photo
export const droplogsheetphoto = (logSheetId, logDetails, adminId, token) => {
    return fetch (`${API}/admin/logSheet/drop/photo/${logSheetId}/${adminId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(logDetails)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

   // Admin privileges for Trip Sheets

   // Add a trip sheet
   export const createtripsheet = (adminId, loadDetails, token) => {
       return fetch(`${API}/admin/tripsheet/create/${adminId}`, {
           method: "POST",
           headers: {
               Accept: "application/json",
               Authorization: `Bearer ${token}`
           },
           body: loadDetails
       
       })
           .then(response => {
               return response.json();
           })
           .catch(err => console.log(err));
   };

   // View all trip sheets for an individual driver
   export const gettripsheetsfordriver = (adminId, driverId, filterDate, token) => {
    return fetch(`${API}/admin/driver/tripSheet/${driverId}/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
    };

   // View all trip sheets for a client
   export const gettripsheetsforclient = (adminId, partnerId, filterDate, token) => {
    return fetch(`${API}/admin/partner/tripSheet/${partnerId}/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
    };

    // View all trip sheets between 2 dates
   export const getalltripsheets = (adminId, filterDate, token) => {
    return fetch(`${API}/admin/tripSheets/${adminId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
    };

    // get a trip sheet
    export const gettripsheet = (tripSheetId, adminId, token) => {
        return fetch(`${API}/admin/tripSheet/${tripSheetId}/${adminId}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                return response.json();
            })
            .catch(err => console.log(err));
    };
    
    // Update a trip sheet
    export const updatetripsheet = (tripSheetId, tripSheetDetails, adminId, token) => {
        return fetch(`${API}/admin/tripSheet/update/${adminId}/${tripSheetId}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`
            },
            body: tripSheetDetails
        })
            .then(response => {
                return response.json();
            })
            .catch(err => console.log(err));
    };
    
    // delete a trip sheet
    export const deletetripsheet = (tripSheetId, adminId, token) => {
        return fetch(`${API}/admin/tripSheet/delete/${adminId}/${tripSheetId}`,{
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`
             }
          
          })
          .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
    }

    // Flip the verification status of the trip sheet
    export const flipVerificationStatusForTheTripSheet = (tripSheetId, adminId, token) => {
        return fetch(`${API}/admin/tripSheet/flip/loadstatus/${adminId}/${tripSheetId}`,{
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
             }   
          })
          .then(response => {
              return response.json();
          })
          .catch(err => console.log(err));
      };

      // Flip the waiting time waiver status of the trip sheet
    export const flipWaitingTimeWaiverStatusForTheTripSheet = (tripSheetId, adminId, token) => {
        return fetch(`${API}/admin/tripSheet/flip/waitingwaiverstatus/${adminId}/${tripSheetId}`,{
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
             }   
          })
          .then(response => {
              return response.json();
          })
          .catch(err => console.log(err));
      };
    
    // Drop trip sheet photo
    export const droptripsheetphoto = (tripSheetId, tripSheetDetails, adminId, token) => {
        return fetch (`${API}/admin/tripsheet/drop/photo/${tripSheetId}/${adminId}`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
             Authorization: `Bearer ${token}`
           },
        body: JSON.stringify(tripSheetDetails)
       
        })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
    };

    // Not required
  // Category
const createcategory = (adminid, token, category) => {
    return fetch (`${API}/category/create/${adminid}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(category)

    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};
export default createcategory;


// get all categories
export const getcategories = () => {
    return fetch(`${API}/categories`,{
        method: "GET"
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete a category
export const deletecategory = (categoryId, adminId, token) => {
    return fetch(`${API}/category/${categoryId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }

      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}



// get a category
export const getcategory = (categoryId) => {
    return fetch(`${API}/category/${categoryId}`,{
        method: "GET"
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a category

export const updatecategory = (categoryId, token, adminId, category) => {
    return fetch(`${API}/category/${categoryId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
           "Content-Type": "application/json",
           Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(category)

      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };


// Location
// Add a Location
export const createlocation = (adminid, token, location) => {
    return fetch (`${API}/admin/location/create/${adminid}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(location)
    
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// get all locations
export const getlocations = (adminid, token) => {
    return fetch(`${API}/admin/locations/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};



// delete a location
export const deletelocation = (locationId, adminId, token) => {
    return fetch(`${API}/admin/location/${locationId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}
  


// get a location
export const getlocation = (locationId, adminId, token) => {
    return fetch(`${API}/admin/location/${locationId}/${adminId}`,{
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


// update a location

export const updatelocation = (locationId, token, adminId, location) => {
    return fetch(`${API}/admin/location/${locationId}/${adminId}`,{
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
      body: JSON.stringify(location)
      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

  // Fetch image from S3
  export const fetchImageFromS3 = async (sheetid, path) => {
    const imageurl = sheetid && path ? `${API}/${path}/${sheetid}` : "";
    try {
        const response = await axios.get(imageurl);
        return response.data;
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};

// get Audit logs
export const getauditlogs = (adminid, token) => {
    return fetch(`${API}/admin/audit/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// delete a fuel rate
export const deleteFuelRate = (fuelRateMetricId, adminId, token) => {
    return fetch(`${API}/admin/fuelrate/${fuelRateMetricId}/${adminId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
           Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}

// get Fuel S/C rate history for a partner
export const getCustomerFuelRateHistory = (partnerId, adminid, token) => {
    return fetch(`${API}/admin/fuelrate/history/${partnerId}/${adminid}`,{
        method: "GET",
        headers: {
           Authorization: `Bearer ${token}`
         }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// get Trip rate history for a partner
export const getCustomerTripRateHistory = (partnerId, filterDate, adminid, token) => {
    return fetch(`${API}/admin/triprate/history/${partnerId}/${adminid}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};