import React, { useState } from "react"
import Base from "../core/Base"
import { Link, Redirect } from "react-router-dom"
import { signin, authenticate, isAuthenticated } from "../auth/helper/adminIndex"
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import LoadingScreen from '../core/helper/LoadingScreen';
import ToastifyMessage from '../core/helper/ToastifyMessage';
import {toastifyMessage} from '../core/helper/Toast';
import { toast } from 'react-toastify';

const AdminSignin = () => {

    const [values, setValues] = useState({
        username: "",
        password: "",
        showPassword: false,
        error: "",
        loading: false,
        didRedirect: false
    });

    const { username, password, showPassword, error, loading, didRedirect } = values
    const { admin } = isAuthenticated();


    // Password for handling the state change
    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value })
    }

    // Method for showing or hidng the password
    const handleShowHidePassword = () => {
        setValues({ ...values, showPassword: !showPassword })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = event => {
        event.preventDefault()
        setValues({...values, error: false, loading: true})

        // Field validations
        const requiredFields = [username, password];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'Username & Password are mandatory', loading: false});
            toast.warning('Username & Password are mandatory', {
               position: "top-right",
               autoClose: 3000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light"
               });
            return;
        }

        signin({username, password})
        .then(data => {
            if(data.error){
                setValues({...values, error: data.error, loading: false})
                toast.error('Login Failed', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                    });
            } else{
                authenticate(data, () => {
                    setValues({...values,didRedirect: true})
                    })
                }
            })
            .catch(err => {
                console.log('Error in admin signin:', err);
                setValues({ ...values, error: "Error in admin signin", loading: "" });
                toastifyMessage("error", 'Error in admin signin');
              });
    };

    const onReset = event => {
        event.preventDefault()
        setValues({
            ...values,
            username: "",
            password: "",
            error: "",
            success: false
        });
    };


    const performRedirect = () => {
        if (didRedirect) {
            if (admin) {
                return (
                    <Redirect to="/admin/dashboard" />
                )
            }

        }
        if (isAuthenticated) {
            //return <Redirect to="/" />
        }
    };

    const signInForm = () => {
        return (
            <div className="row">
                <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1 text-left">
                    <form>
                        <div className="form-group">
                            <label className="text-light"> Username <span className="text-warning">*</span></label>
                            <Input className="form-control"
                                disableUnderline={true}
                                type="text"
                                value={username} onChange={handleChange("username")}
                                placeholder="Password"
                            />
                        </div>
                        <div className="form-group">
                            <label className="text-light"> Password <span className="text-warning">*</span></label>
                            <Input className="form-control"
                                disableUnderline={true}
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                onChange={handleChange("password")}
                                value={password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleShowHidePassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? (<Visibility />) : (<VisibilityOff />)}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>

                        <button className="btn btn-success btn-block rounded " onClick={onSubmit}> Submit </button>
                        <button className="btn btn-info btn-block rounded " onClick={onReset}>Reset </button>
                    </form>
                </div>
            </div>
        );
    };

    const loadingMessage = () => (
        loading && (
            <div className="alert alert-info">
                <h2>Loading Admin Dashboard...</h2>
            </div>
        )
    )

    const errorMessage = () => (
        <div className="row">
            <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1 text-center">
                <div className="alert alert-danger"
                    style={{ display: error ? "" : "none" }}
                >
                    {error}
                </div>
            </div>
        </div>
    )

    return (
        <Base title="Admin Sign In" description="Login as an Admin">
            {signInForm()}
            <br />
            {performRedirect()}
            {LoadingScreen(loading)}
            {errorMessage()}
            <br/>
            <ToastifyMessage/> 
        </Base>
    );
}

export default AdminSignin;