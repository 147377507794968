import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams } from 'react-router-dom';
import { getlogsheet, gettrucks, gettrailers, getmerchants, updatelogsheet, droplogsheetphoto } from '../helper/adminapicall';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

const EditLogSheet = () => {

    const {logsheetId} = useParams();
    const {admin, token} = isAuthenticated();

    const [values, setValues] = useState({
        truckNum: "",
        truckNo: "",
        otherTruckNo: "",
        trailerNum: "",
        trailerNo: "",
        otherTrailerNo: "",
        date: "",
        timeIn: "",
        timeOut: "",
        downTime: "",
        startOdometer: "",
        endOdometer: "",
        reimbursement: false,
        nightOut: false,
        nightOutPurpose: "",
        numberOfLoads: "",
        pickUpcity: "",
        deliveryCity: "",
        ratePerHour: "",
        deductions: "",
        deductionNotes: "",
        otherReimbursementCompany: "",
        reimbursementCompany: "",
        reimbursementComp: "",
        reimbursementDate: "",
        reimbursementPurpose: "",
        reimbursementLocation: "",
        reimbursementAmount: "",
        photo1: "",
        photo2: "",
        photo3: "",
        photo4: "",
        photo1Name: "",
        photo2Name: "",
        photo3Name: "",
        photo4Name: "",
        pickup: "",
        partners: "",
        trucks: "",
        trailers: "",
        loading: false,
        loadingModal: "",
        isLoaded: "",
        error: '',
        UpdatedLogSheet: '',
        formData: '',
    });

    const {
        truckNum,
        truckNo,
        otherTruckNo,
        trailerNum,
        trailerNo,
        otherTrailerNo,
        date,
        timeIn,
        timeOut,
        downTime,
        pickUpCity,
        deliveryCity,
        startOdometer,
        endOdometer,
        photo1,
        photo2,
        photo3,
        photo4,
        photo1Name,
        photo2Name,
        photo3Name,
        photo4Name,
        reimbursement,
        nightOut,
        nightOutPurpose,
        numberOfLoads,
        ratePerHour,
        deductions,
        deductionNotes,
        otherReimbursementCompany,
        reimbursementCompany,
        reimbursementComp,
        reimbursementDate,
        reimbursementPurpose,
        reimbursementLocation,
        reimbursementAmount,
        trucks,
        trailers,
        partners,
        loading,
        loadingModal,
        isLoaded,
        error,
        UpdatedLogSheet,
        formData,
    } = values;

    const ref1 = React.useRef();
    const ref2 = React.useRef();
    const ref3 = React.useRef();
    const ref4 = React.useRef();

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        gettrucks(admin._id, token).then(data1 => {
            if (data1.error) {
                setValues({ ...values, error: data1,  loading: "", isLoaded: ""});
            } else {
                gettrailers(admin._id, token).then(data2 => {
                    if (data2.error) {
                        setValues({ ...values, error: data2,  loading: "", isLoaded: ""});
                    } else {
                        getmerchants(admin._id, token).then(data3 => {
                            if (data3.error) {
                                setValues({ ...values, error: data3,  loading: "", isLoaded: ""});
                            } else {
                                getlogsheet(logsheetId, admin._id, token).then(data4 => {
                                    if (data4.error) {
                                        setValues({ ...values, error: data4,  loading: "", isLoaded: ""});
                                    } else {
                                        const { truckNo, otherTruckNo, trailerNo, otherTrailerNo, date, timeIn, timeOut, downTime, pickUpCity, deliveryCity, startOdometer, endOdometer, photo1, photo2, photo3, photo4,
                                            photo1Name, photo2Name, photo3Name, photo4Name, reimbursement, nightOut, nightOutPurpose, numberOfLoads, ratePerHour, deductions, deductionNotes, reimbursementDate, reimbursementPurpose, reimbursementLocation, reimbursementAmount } = data4;
                                        setValues({
                                            ...values,
                                            trucks: data1?data1:[],
                                            trailers: data2?data2:[],
                                            partners: data3?data3:[],
                                            truckNum: (truckNo ? truckNo.truckNumber : ''),
                                            trailerNum: (trailerNo ? trailerNo.trailerNumber : ''),
                                            date: (date ? date.substring(0, 10) : ""),

                                            otherTruckNo: (otherTruckNo ? otherTruckNo : ""),
                                            truckNo:(truckNo ? truckNo.truckNumber : (otherTruckNo ? "other" : "")),
                                            otherTrailerNo: (otherTrailerNo ? otherTrailerNo : ""),
                                            trailerNo:(trailerNo ? trailerNo.trailerNumber : (otherTrailerNo ? "other" : "")),
                                            
                                            timeIn,
                                            timeOut,
                                            downTime,
                                            startOdometer,
                                            endOdometer,
                                            reimbursement,
                                            nightOut,
                                            nightOutPurpose,
                                            numberOfLoads,
                                            pickUpCity,
                                            deliveryCity,
                                            ratePerHour,
                                            deductions,
                                            deductionNotes,
                                            reimbursementDate: (reimbursementDate ? reimbursementDate.substring(0,10) : ""),
                                            reimbursementPurpose,
                                            reimbursementLocation,
                                            reimbursementAmount,
                                            photo1,
                                            photo2,
                                            photo3,
                                            photo4,
                                            photo1Name,
                                            photo2Name,
                                            photo3Name,
                                            photo4Name,
                                            loading: "",
                                            isLoaded: true,
                                            formData: new FormData()                                            
                                        });
                                    }
                                })
                                .catch(err => {
                                    console.log('Error preloading the time card:', err);
                                    setValues({ ...values, error: "Error preloading the time card", loading: "" });
                                    toastifyMessage("error", 'Error preloading the time card');
                                });
                            }
                        })
                        .catch(err => {
                            console.log('Error preloading the time card:', err);
                            setValues({ ...values, error: "Error preloading the time card", loading: "" });
                            toastifyMessage("error", 'Error preloading the time card');
                        });
                    }
                })
                .catch(err => {
                    console.log('Error preloading the time card:', err);
                    setValues({ ...values, error: "Error preloading the time card", loading: "" });
                    toastifyMessage("error", 'Error preloading the time card');
                });
            }
        })
        .catch(err => {
            console.log('Error preloading the time card:', err);
            setValues({ ...values, error: "Error preloading the time card", loading: "" });
            toastifyMessage("error", 'Error preloading the time card');
        });
    };



    useEffect(() => {
        preload();
    }, []);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const successMessage = () => (
        <div className="alert alert-success mt-3" style={{ display: UpdatedLogSheet ? '' : 'none' }}>
            <h4>{UpdatedLogSheet}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3" style={{ display: error ? '' : 'none' }}>
            <h4>{error}</h4>
        </div>
    );

    const handleChange = (name) => (event) => {
        const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4")) ? event.target.files[0] : event.target.value
             
        if ((name === "reimbursement") && (value === "false" || value === false)) {
            setValues({
              ...values,
              reimbursementDate: "",
              reimbursementPurpose: "",
              reimbursementLocation: "",
              reimbursementAmount: "",
              reimbursementComp: "",
              [name]: value
            })
            formData.set("reimbursementDate", "")
            formData.set("reimbursementPurpose", "")
            formData.set("reimbursementLocation", "")
            formData.set("reimbursementAmount", "")
            formData.set(name, value);      
          }

          else if ((name === "nightOut") && (value === "false" || value === false)) {
            setValues({
              ...values,
              nightOutPurpose: "",
              [name]: value
            })
            formData.delete("nightOutPurpose")
            formData.set(name, value)
          }
      
          else if(((name === "trailerNo") || (name === "truckNo")) && (value !== "other")){
            if(name==="trailerNo"){
                setValues({ ...values, [name]: value, otherTrailerNo: "" })
                formData.set(name, value)
                if(formData.get("otherTrailerNo") || formData.get("otherTrailerNo")===""){
                    formData.delete("otherTrailerNo")
                }
            }
      
            if(name==="truckNo"){
                setValues({ ...values, [name]: value, otherTruckNo: "" })
                formData.set(name, value)
                if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
                    formData.delete("otherTruckNo")
                }
            }
        }
        
        else{
            setValues({ ...values, [name]: value })
            formData.set(name, value)
        }
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", UpdatedLogSheet: '', loading: true });

        const requiredFields = [truckNo, trailerNo, date, timeIn, timeOut, ((downTime || downTime===0)?downTime.toString():downTime), pickUpCity, deliveryCity, ratePerHour, ((startOdometer || startOdometer===0)?startOdometer.toString():startOdometer), ((endOdometer || endOdometer===0)?endOdometer.toString():endOdometer)];
        const isReimbursementFilled = reimbursementDate && reimbursementPurpose && reimbursementLocation && reimbursementAmount;
        if (
            requiredFields.some(field => !field) ||
            (reimbursement && (reimbursement===true || reimbursement==="true") && !isReimbursementFilled) ||
            reimbursement === '' ||
            nightOut === ''){
            setValues({ ...values, error: 'Please fill all required fields', UpdatedLogSheet: '', loading: "" });
            toastifyMessage("warning", 'All the fields marked with * are mandatory');

            if(truckNo === "other" && (otherTruckNo === "")){ 
                setValues({ ...values, error: 'Enter valid truck number', loading: "", UpdatedLogSheet: '' });
                toastifyMessage("warning", 'Enter valid truck number');
                return;
              }
          
              if(trailerNo === "other" && (otherTrailerNo === "")){ 
                setValues({ ...values, error: 'Enter valid trailer number', loading: "", UpdatedLogSheet: '' });
                toastifyMessage("warning", 'Enter valid trailer number');
                return;
              }
              
        } else {
            setValues({ ...values, error: '', loading: true, UpdatedLogSheet: "" });
            updatelogsheet(logsheetId, formData, admin._id, token)
                .then((data) => {
                    if (data.error) {
                        setValues({ ...values, error: data.error, UpdatedLogSheet: '' });
                        toastifyMessage("error", 'Error while updating the time card');
                    } else {
                        setValues({
                            ...values,
                            ...data,
                            date: (date ? date.substring(0, 10) : ""),
                            loading: "",
                            error: "",
                            photo1: (data && data.photo1 && data.photo1.data) ? data.photo1 : "",
                            photo2: (data && data.photo2 && data.photo2.data) ? data.photo2 : "",
                            photo3: (data && data.photo3 && data.photo3.data) ? data.photo3 : "",
                            photo4: (data && data.photo4 && data.photo4.data) ? data.photo4 : "",
                            photo1Name: (data && data.photo1Name) ? data.photo1Name : "",
                            photo2Name: (data && data.photo2Name) ? data.photo2Name : "",
                            photo3Name: (data && data.photo3Name) ? data.photo3Name : "",
                            photo4Name: (data && data.photo4Name) ? data.photo4Name : "",
                            UpdatedLogSheet: 'Time card updated successfully',
                            formData: new FormData()
                        });
                        ref1.current.value = "";
                        ref2.current.value = "";
                        ref3.current.value = "";
                        ref4.current.value = "";
                        toastifyMessage("success", 'Time card updated successfully');
                    }
                })
                .catch(err => {
                    console.log('Error updating the time card:', err);
                    setValues({ ...values, error: "Error updating the time card", loading: "" });
                    toastifyMessage("error", 'Error updating the time card');
                });
        }
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the photo?");
    const [deletePhotoSheetId, setDeletePhotoSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    const dropPhoto = (type) => {
        setValues({...values, error: "", loading: true, loadingModal: true, UpdatedLogSheet: ""})

        droplogsheetphoto(logsheetId, {type}, admin._id, token)
        .then(data => {
          if(data.error){
                setValues({...values, error:data.error, loading: "", loadingModal: "", UpdatedLogSheet: ""})
                toastifyMessage("error", "Error deleting the photo");
          }else{
              setValues({
                  ...values,
                  ...data,
                  date: (date ? date.substring(0, 10) : ""),
                  loading: false,
                  loadingModal: false,
                  error: "",
                  photo1Name: (data && data.photo1Name) ? data.photo1Name : "",
                  photo2Name: (data && data.photo2Name) ? data.photo2Name : "",
                  photo3Name: (data && data.photo3Name) ? data.photo3Name : "",
                  photo4Name: (data && data.photo4Name) ? data.photo4Name : "",
                  UpdatedLogSheet: "Photo deleted successfully",
              })
              ref1.current.value = "";
              ref2.current.value = "";
              ref3.current.value = "";
              ref4.current.value = "";
              toastifyMessage("success", "Photo deleted successfully");
              handleCancelDeletion();      
          }
      })
      .catch(err => {
        console.log('Error deleting the photo:', err);
        setValues({ ...values, error: "Error deleting the photo", loadingModal: "" });
        toastifyMessage("error", 'Error deleting the photo');
        });  
    }

    const updateLogSheetForm = () => (
        <form form className="mt-4">
            <div className="form-group">
                <label className="text-light">Truck <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('truckNo')}
                    className="form-control"
                >
                    {trucks &&
                        trucks.map((truck, index) => (
                            <option key={index} value={truck._id}  selected={truck.truckNumber===truckNum}>
                                {truck.truckNumber}
                            </option>
                        ))}
                        <option value="other" selected={truckNo==="other" || truckNo===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck Number"
                    value={otherTruckNo} />
            </div>

            <div className="form-group">
                <label className="text-light">Trailer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('trailerNo')}
                    className="form-control"
                >
                    {trailers &&
                        trailers.map((trailer, index) => (
                            <option key={index} value={trailer._id} selected={trailer.trailerNumber===trailerNum}>
                                {trailer.trailerNumber}
                            </option>
                        ))}
                        <option value="other" selected={trailerNo==="other" || trailerNo===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( trailerNo && (trailerNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Trailer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTrailerNo')}
                    className="form-control"
                    placeholder="Trailer Number"
                    value={otherTrailerNo} />
            </div>

            <div className="form-group">
                <label className="text-light">Start City <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange("pickUpCity")}
                    value={pickUpCity}
                    name={pickUpCity}
                    placeholder={pickUpCity}
                />
            </div>
            <div className="form-group">
                <label className="text-light">Finish City <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange("deliveryCity")}
                    value={deliveryCity}
                    name={deliveryCity}
                    placeholder={deliveryCity}
                />
            </div>
            <div className="form-group">
                <label className="text-light">Date <span className="text-warning">*</span></label>
                <input
                    type="date"
                    className="form-control"
                    onChange={handleChange("date")}
                    value={(date).substring(0,10)}
                    name={date}
                />
            </div>
            <div className="form-group">
                <label className="text-light">Time In <span className="text-warning">*</span></label>
                <input
                    type="time"
                    className="form-control"
                    onChange={handleChange("timeIn")}
                    value={timeIn}
                    name={timeIn}
                    placeholder={timeIn}
                />
            </div>
            <div className="form-group">
                <label className="text-light">Time Out <span className="text-warning">*</span></label>
                <input
                    type="time"
                    className="form-control"
                    onChange={handleChange("timeOut")}
                    value={timeOut}
                    name={timeOut}
                    placeholder={timeOut}
                />
            </div>
            <div className="form-group">
                <label className="text-light">Down Time (in minutes) </label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("downTime")}
                    value={downTime}
                    name={downTime}
                    placeholder={downTime}
                />
            </div>
            <div className="form-group">
                <label className="text-light">Deductions (in hours) </label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("deductions")}
                    value={deductions}
                    name={deductions}
                    placeholder={deductions}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Deduction Notes </label>
                <textarea
                            onChange={handleChange("deductionNotes")}
                            name={deductionNotes}
                            className="form-control"
                            type="string"
                            placeholder="Notes"
                            value={deductionNotes}
                />
            </div>
            <div className="form-group">
                <label className="text-light">Start Odometer <span className="text-warning">*</span></label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("startOdometer")}
                    value={startOdometer}
                    name={startOdometer}
                    placeholder={startOdometer}
                />
            </div>
            <div className="form-group">
                <label className="text-light">End Odometer <span className="text-warning">*</span></label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("endOdometer")}
                    value={endOdometer}
                    name={endOdometer}
                    placeholder={endOdometer}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Number of Loads </label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("numberOfLoads")}
                    value={numberOfLoads}
                    name={numberOfLoads}
                    placeholder={numberOfLoads}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Rate Per Hour <span className="text-warning">*</span></label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("ratePerHour")}
                    value={ratePerHour}
                    name={ratePerHour}
                    placeholder={ratePerHour}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Night out <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("nightOut")}
                    value={nightOut}
                    className="form-control"
                >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                </select>
            </div>
            <div className="form-group" style={{ display: ( nightOut && (nightOut === true || nightOut === "true")) ? "" : "none" }}>
                <label className="text-light"> Night out Purpose </label>
                <textarea
                            onChange={handleChange("nightOutPurpose")}
                            name={nightOutPurpose}
                            className="form-control"
                            type="string"
                            placeholder="Purpose of night out"
                            value={nightOutPurpose}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Reimbursement <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("reimbursement")}
                    className="form-control"
                    value={reimbursement}
                >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                </select>
            </div>
            <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
                        <label className="text-light"> Reimbursement Date <span className="text-warning">*</span></label>
                        <input
                            onChange={handleChange("reimbursementDate")}
                            type="date"
                            name={reimbursementDate}
                            className="form-control"
                            value={reimbursementDate ? (reimbursementDate).substring(0,10) : ""}
                        />
            </div>
            <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
                        <label className="text-light"> Reimbursement Location <span className="text-warning">*</span></label>
                        <input
                            type='text'
                            onChange={handleChange("reimbursementLocation")}
                            name={reimbursementLocation}
                            className="form-control"
                            placeholder="Ex. Brampton, ON"
                            value={reimbursementLocation}
                        />
            </div>
            <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
                        <label className="text-light"> Reimbursement Purpose <span className="text-warning">*</span></label>
                        <textarea
                            onChange={handleChange("reimbursementPurpose")}
                            name={reimbursementPurpose}
                            className="form-control"
                            type="string"
                            placeholder="Purpose of reimbursement"
                            value={reimbursementPurpose}
                        />
            </div>
            <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
                        <label className="text-light"> Reimbursement Amount <span className="text-warning">*</span></label>
                        <input
                            type="number"
                            onChange={handleChange("reimbursementAmount")}
                            name={reimbursementAmount}
                            className="form-control"
                            placeholder="Ex. 150"
                            value={reimbursementAmount}
                        />
            </div>

            { ((photo1Name || (photo1 && photo1.data)) || (photo2Name || (photo2 && photo2.data)) || (photo3Name ||(photo3 && photo3.data)) || (photo4Name || (photo4 && photo4.data))) &&
                <div className="form-group">
                    <label className="text-light"> Trip Photos <span className="text-warning"> &nbsp;&nbsp;&nbsp; </span></label>
                    
                    { (photo1Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={logsheetId} photoID={photo1Name} caption={"Time Card"} type={'photo1'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo1")}
                                        type="file"
                                        name="photo1"
                                        accept="image/*"
                                        placeholder="Choose an image for the time card"
                                        ref={ref1}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo1Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }

                    { (photo1 && photo1.data) &&
                        <ImageCard id={logsheetId} caption={"Time Card"} type={'photo1'} />
                    }

                    { (photo2Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={logsheetId} photoID={photo2Name} caption={"Time Card"} type={'photo2'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo2")}
                                        type="file"
                                        name="photo2"
                                        accept="image/*"
                                        placeholder="Choose an image for the time card"
                                        ref={ref2}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo2Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }

                    { (photo2 && photo2.data) &&
                        <ImageCard id={logsheetId} caption={"Time Card"} type={'photo2'} />
                    }

                    { (photo3Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={logsheetId} photoID={photo3Name} caption={"Time Card"} type={'photo3'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo3")}
                                        type="file"
                                        name="photo3"
                                        accept="image/*"
                                        placeholder="Choose an image for the time card"
                                        ref={ref3}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo3Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    } 

                    { (photo3 && photo3.data) &&
                        <ImageCard id={logsheetId} caption={"Time Card"} type={'photo3'} />
                    } 

                    { (photo4Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={logsheetId} photoID={photo4Name} caption={"Time Card"} type={'photo4'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("photo4")}
                                        type="file"
                                        name="photo4"
                                        accept="image/*"
                                        placeholder="Choose an image for the time card"
                                        ref={ref4}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('photo4Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }    

                    { (photo4 && photo4.data) &&
                        <ImageCard id={logsheetId} caption={"Time Card"} type={'photo4'} />
                    }            

                </div>
            }
           
           {
                (!photo1Name || !photo2Name || !photo3Name || !photo4Name)
                &&
                <span className="text-white"> Add Trip Photos &nbsp;&nbsp;&nbsp;</span>
           }

            {
                !photo1Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo1")}
                            type="file"
                            name="photo1"
                            accept="image/*"
                            placeholder="Choose an Image for the Details"
                            ref={ref1}
                        />
                    </label>
                </div>
            }

            {
                !photo2Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo2")}
                            type="file"
                            name="photo2"
                            accept="image/*"
                            placeholder="Choose an Image for the Details"
                            ref={ref2}
                        />
                    </label>
                </div>
            }

            {
                !photo3Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo3")}
                            type="file"
                            name="photo3"
                            accept="image/*"
                            placeholder="Choose an Image for the Details"
                            ref={ref3}
                        />
                    </label>
                </div>
            }

            {
                !photo4Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("photo4")}
                            type="file"
                            name="photo4"
                            accept="image/*"
                            placeholder="Choose an Image for the Details"
                            ref={ref4}
                        />
                    </label>
                </div>
            }


            <button type="submit" onClick={onSubmit} className="btn btn-success mb-4">
                Update Time Card
            </button>
        </form >
    );

    return (
        <Base title="Time Card Dashboard" description="Edit the time card" className="container bg-success p-4">
            <Link to="/admin/dashboard" className="btn brn-md btn-dark mb-3">
                Admin Home
            </Link>

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-2">
                    {isLoaded && updateLogSheetForm()}
                    {successMessage()}
                    {warningMessage()}
                    {LoadingScreen(loading)}
                </div>
            </div>
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={dropPhoto} id={deletePhotoSheetId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/>
        </Base>
    );
};

export default EditLogSheet;
