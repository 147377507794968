import React, {useState, useEffect} from 'react'
import Base from "../../core/Base"
import { isAuthenticated } from "../../auth/helper/adminIndex"
import {Link,useParams} from "react-router-dom";
import {getcommodity, updatecommodity} from "../helper/adminapicall"
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function UpdateCommodity() {

    const {admin, token} = isAuthenticated()
    const {commodityId} = useParams()

    const [values, setValues] = useState({
        commodityName: "",
        error: false,
        updatedCommodity: "",
        loading: false,
        isLoaded: "",
        didRedirect: false
    });
    
    const { commodityName, error, updatedCommodity, loading, isLoaded, didRedirect } = values

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getcommodity(commodityId, admin._id, token).then(data => {
          if(data.error){
            setValues({ ...values, loading: "", isLoaded: "", error: data.error })
          } else{
            setValues({
                ...values,
                ...data,
                error: "",
                loading: "",
                isLoaded: true
            });
            }
        })
        .catch(err => {
            console.log('Error preloading the commodity:', err);
            setValues({ ...values, error: "Error preloading the commodity", loading: "" });
            toastifyMessage("error", 'Error preloading the commodity');
        });    
      }
  
      useEffect(() => {
        preload()
      },[])


    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, updatedCommodity: "", loading: true})

        // Field validations
        const requiredFields = [commodityName];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", updatedCommodity: ""});
            return;
        }

        updatecommodity(commodityId, token, admin._id, {commodityName})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", updatedCommodity: ""})
                    toastifyMessage("error", 'Error while updating the commodity');
                }
                else{
                    setValues({
                        ...values,
                        ...data,                       
                        loading: "",
                        error: "",
                        updatedCommodity: "Commodity updated succesfully"
                    });
                    toastifyMessage("success", 'Commodity updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the commodity:', err);
                setValues({ ...values, error: "Error updating the commodity", loading: "" });
                toastifyMessage("error", 'Error updating the commodity');
            });

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: updatedCommodity ? "" : "none" }}
        >
            <h4>{updatedCommodity}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );

    const updateCommodityForm = () => (
        <form className="mt-4"> 
            <div className="form-group">
                <p className="text-dark"> Enter the Commodity Name <span className="text-info">*</span></p>
                <input
                className="form-control"
                onChange={handleChange("commodityName")}
                type="text"
                name="commodityName"
                value={commodityName}
                placeholder={commodityName}
                autoFocus
                required
                />
                <button 
                onClick ={onSubmit}
                className="btn btn-outline-info"> Update Commodity </button>
            </div>
        </form>
    )

    return (
        <Base
        title="Commodity Dashboard"
        description="Edit the Commodity Name"
        className="container bg-info p-4"
        >

            {returnToAdminDashboard()}

            <div className="row bg-white text-dark rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                {isLoaded && updateCommodityForm()}
                {successMessage()}
                {warningMessage()}
                
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage/>
        </Base>
    )
}
