import {API} from "../../backend"
// API is basically path

// Backend for Signin
export const dsignin = driver => {
    return fetch(`${API}/driversignin`, {
        method: "POST",
        headers: {
            "Accept" : "application/json",
            "Content-Type" : "application/json"
        },
        body: JSON.stringify(driver)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const dChangePassword = (driverPassword, driverId, token) => {
    return fetch(`${API}/driver/password/change/${driverId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(driverPassword)
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};


export const dauthenticate = (data, next) => {
    if(typeof window !== "undefined") {
        localStorage.setItem("eSession", JSON.stringify(data))
        next();
    }
}

// Backend for Signout
export const dsignout = next => {
    if(typeof window !== "undefined") {
        localStorage.removeItem("eSession")
        next();

        return fetch(`${API}/driversignout`, {
          method: "GET"  
        })
        .then(response => console.log("Signout Success"))
        .catch(err => console.log(err))
    }
    
};

export const disAuthenticated = () => {
    if(typeof window == "undefined") {
        return false
    }
    if(localStorage.getItem("eSession")) {
        return JSON.parse(localStorage.getItem("eSession"));
    } else {
        return false;
    }
};