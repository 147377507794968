import React, {useState} from "react";
import Base from "../../core/Base";
import {Link} from "react-router-dom";
import {createadmin} from "../helper/adminapicall";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import { toast } from 'react-toastify';

export default function AddAdmin() {

    const {admin, token} = isAuthenticated()
    const [values, setValues] = useState({
        username: "",
        adminName: "",
        password: "",
        createdAdmin: "",
        showPassword: false,
        loading: false,
        error: ""
    });

    const {username, adminName, password, createdAdmin, showPassword, error, loading} = values

    // Password for handling the state change
    const handleChange = name => event => {
        setValues({...values, error: "", [name]: event.target.value})
    }

    // Method for showing or hidng the password
    const handleShowHidePassword = () => {
        setValues({...values, showPassword: !showPassword})
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const onSubmit = event => {
        event.preventDefault()
        setValues({...values, error: false, loading: true, createdAdmin: false})

         // Field validations
         const requiredFields = [username, adminName, password];
         if (requiredFields.some(field => !field)) {
             setValues({ ...values, error: 'Name, Username & Password are mandatory', loading: false, createdAdmin: false});
             toast.warning('Name, Username & Password are mandatory', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
             return;
         }

        createadmin(admin._id, token, {username, adminName, password})
        .then(data => {
            if(data.error){
                setValues({...values, error: data.error, loading: false, createdAdmin: ""})
                toast.error((data.error?data.error:'Error while onboarding the admin'), {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                    });
                
            } else{
                setValues({
                    ...values,
                    username: "",
                    adminName: "",
                    password: "",
                    createdAdmin: "Admin onboarded successfully",
                    error: "",
                    loading: false
                });
                toast.success('Signup successful', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                    });
            }
        })
        .catch(err => {
          console.log('Error onboarding the admin:', err);
          setValues({ ...values, error: "Error onboarding the admin", loading: "" });
          toastifyMessage("error", 'Error onboarding the admin');
        });
    };


    const onReset = event => {
        event.preventDefault()
        setValues({
                    ...values,
                    username: "",
                    adminName: "",
                    password: "",
                    error: "",
                    success: ""
                });
        };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
          style={{ display: createdAdmin ? "" : "none" }}
        >
          <h4>{createdAdmin}</h4>
        </div>
      )
    
      const warningMessage = () => (
        <div className="alert alert-danger mt-3"
          style={{ display: error ? "" : "none" }}
        >
          <h4>{error} </h4>
        </div>
      )

      const onboardAdminForm = () => (
        <form className="mt-4">
          <div className="form-group">
            <label className="text-light"> Name <span className="text-warning">*</span> </label>
            <Input 
                className="form-control"
                disableUnderline={true}
                type="text"
                value={adminName} 
                onChange={handleChange("adminName")}
                placeholder="Name"
            />
          </div>
    
          <div className="form-group">
            <label className="text-light"> Username <span className="text-warning">*</span></label>
            <Input 
                className="form-control"
                disableUnderline={true}
                type="text"
                value={username} 
                onChange={handleChange("username")}
                placeholder="Username"
            />
          </div>
    
          <div className="form-group">
            <label className="text-light"> Password <span className="text-warning">*</span></label>
            <Input 
                className="form-control"
                disableUnderline={true}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={handleChange("password")}
                value={password}
                endAdornment={
                            <InputAdornment position="end">
                                <IconButton 
                                    onClick={handleShowHidePassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                        {showPassword ? (<Visibility />) : (<VisibilityOff />)}
                                </IconButton>
                            </InputAdornment>
                            }
            />
          </div>         
    
          <button onClick={onSubmit} className="btn btn-success btn-block rounded"> Onboard Admin </button>        
          <button onClick={onReset} className="btn btn-info btn-block rounded mb-4"> Reset </button>
        </form>
      );
    
    return (
        <Base
          title="Admin Dashboard"
          description="Onboard a new admin"
          className="container bg-success p-4"
        >
          <Link to="/admin/dashboard" className="btn brn-md btn-dark mb-3">
            Admin Home
          </Link>
    
          <div className="row bg-dark test-white rounded center">
            <div className="col-md-8 offset-md-1 col-sm-8 offset-sm-2 col-10 offset-1 ">
              {onboardAdminForm()}
              {successMessage()}
              {warningMessage()}
              {LoadingScreen(loading)}
              <ToastifyMessage/>
            </div>
          </div>
        </Base>
      )
}