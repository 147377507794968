import React from 'react'
import { Link } from "react-router-dom"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PhotoSharpIcon from '@mui/icons-material/PhotoSharp';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import Paper from '@mui/material/Paper';
import moment from 'moment';
import "./TableView.css";

export default function FuelLogSheetTableView({fuelSheets, editURL, isAggregated, isAdmin, isDriver, isDriverNameRequired=false, setDeleteFuelSheetId, handleShowDeleteMessage}) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    // Common Logic for both driver & admin
    // Retrieve Truck Number
    const getTruckNo = (fuelSheet) => {
        let truckNumber = "";
        if (fuelSheet && fuelSheet.truckNo && (isAggregated && (isAggregated===true || isAggregated==='true') && fuelSheet.truckNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(fuelSheet.truckNo[0]){
              truckNumber = fuelSheet.truckNo[0].truckNumber
            }
          }
          else{
            truckNumber = fuelSheet.truckNo.truckNumber
          }
        }
        else if(fuelSheet && fuelSheet.otherTruckNo){
          truckNumber = fuelSheet.otherTruckNo
        }
        return truckNumber;
    }

    // Retrieve Credit Card
    const getCreditCard = (fuelSheet) => {
        let cardNumber = "";
        if (fuelSheet && fuelSheet.cardNo && (isAggregated && (isAggregated===true || isAggregated==='true') && fuelSheet.cardNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(fuelSheet.cardNo[0]){
              cardNumber = fuelSheet.cardNo[0].cardNo
            }
          }
          else{
            cardNumber = fuelSheet.cardNo.cardNo
          }
        }
        else if(fuelSheet && fuelSheet.otherCardNo){
            cardNumber = fuelSheet.otherCardNo
        }
        return cardNumber;
    }

    // Retrieve Formatted Date
    const getDate = (inputDate) => {
      let sheetDate = "";
      if (inputDate){
        sheetDate = moment((inputDate.substring(0,10)), 'YYYY-MM-DD').format('D MMMM YYYY');
      }
      return sheetDate;
    }

    // Retrieve Driver Name
    const getDriverName = (sheet) => {
      let driverName = "";
      if (sheet && sheet.driverNo && (isAggregated && (isAggregated===true || isAggregated==='true') && sheet.driverNo[0])){
        if(isAggregated && (isAggregated===true || isAggregated==='true')){
          if(sheet.driverNo[0]){
            let firstName = sheet.driverNo[0].firstName || "";
            let lastName = sheet.driverNo[0].lastName || "";
            driverName = firstName + " " + lastName;
          }
        }
        else{
          let firstName = sheet.driverNo.firstName || "";
            let lastName = sheet.driverNo.lastName || "";
            driverName = firstName + " " + lastName;
        }
      }
      return driverName;
    }

     // Show Actions option to the driver only if the sheet is within the current week (last 7 days)
     const ifEditAllowed = (inputDate) => {
      let isOlderThanAWeek = false;
      if (inputDate){
            // Calculate the date 7 days ago from now
            const sevenDaysAgo = moment().subtract(7, 'days');
            console.log(sevenDaysAgo,inputDate)
            // Check if the input date is before the date 7 days ago
            isOlderThanAWeek = moment((inputDate.substring(0,10))).isBefore(sevenDaysAgo);
      }
      return !isOlderThanAWeek;
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
      <div className="table-container">
          <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 200px)' }}>
              <Table stickyHeader className="mui-table" size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="sticky-header-cell">Refuelling Date</StyledTableCell>

                    {isDriverNameRequired && <StyledTableCell className="sticky-header-cell" align="center">Driver</StyledTableCell>}

                    <StyledTableCell className="sticky-header-cell" align="center">Card Number</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Truck</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Odometer</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">DEF (in Litres)</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Fuel (in Litres)</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Location</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Photos</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fuelSheets && fuelSheets.map((fuelSheet, index) => (
                    <StyledTableRow key={index}>         
                      <StyledTableCell component="th" scope="row">{fuelSheet.refuellingDate ? getDate(fuelSheet.refuellingDate) : ""}</StyledTableCell>

                      {isDriverNameRequired && <StyledTableCell align="center">{getDriverName(fuelSheet)}</StyledTableCell>}

                      <StyledTableCell align="center">{getCreditCard(fuelSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getTruckNo(fuelSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{(fuelSheet.odometer || fuelSheet.odometer===0) ? fuelSheet.odometer : ""}</StyledTableCell>
                      <StyledTableCell align="center">{(fuelSheet.def ||fuelSheet.def===0) ? fuelSheet.def : ""}</StyledTableCell>
                      <StyledTableCell align="center">{(fuelSheet.fuel || fuelSheet.fuel===0) ? fuelSheet.fuel : ""}</StyledTableCell>
                      <StyledTableCell align="center">{fuelSheet.location ? fuelSheet.location : ""}</StyledTableCell>
                      <StyledTableCell align="center">
                          <Stack direction="row" alignItems="center" spacing={1}>
                              { (fuelSheet.fuelPhoto1Name) &&
                                  <ImageCardS3 id={fuelSheet._id} photoID={fuelSheet.fuelPhoto1Name} caption={"Fuel Sheet"} type={'fuelPhoto1'} />
                              }
                              { (fuelSheet.fuelPhoto1 && fuelSheet.fuelPhoto1.data) &&
                                  <ImageCard id={fuelSheet._id} caption={"Fuel Sheet"} type={'fuelPhoto1'} />
                              }
                              { (fuelSheet.fuelPhoto2Name) &&
                                <ImageCardS3 id={fuelSheet._id} photoID={fuelSheet.fuelPhoto2Name} caption={"Fuel Sheet"} type={'fuelPhoto2'} />
                              }
                              { (fuelSheet.fuelPhoto2 && fuelSheet.fuelPhoto2.data) &&
                                <ImageCard id={fuelSheet._id} caption={"Fuel Sheet"} type={'fuelPhoto2'} />
                              }
                          </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {
                          isAdmin && 
                          <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton aria-label="delete" size="large" color="error" onMouseDown={handleMouseDownPassword}
                                onClick={()=>{
                                  setDeleteFuelSheetId(fuelSheet._id);
                                  handleShowDeleteMessage();
                                  }}>
                                      <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                              
                                <Link to={editURL + `${fuelSheet._id}`}>
                                      <IconButton aria-label="edit" size="large" color="success" onMouseDown={handleMouseDownPassword}>
                                          <EditIcon fontSize="inherit" />
                                      </IconButton>
                                </Link>
                          </Stack>
                        }

                        {
                          isDriver && (ifEditAllowed(fuelSheet.refuellingDate) ?
                          <Stack direction="row" alignItems="center" spacing={1}>                              
                                <Link to={editURL + `${fuelSheet._id}`}>
                                      <IconButton aria-label="edit" size="large" color="success" onMouseDown={handleMouseDownPassword}>
                                          <EditIcon fontSize="inherit" />
                                      </IconButton>
                                </Link>
                          </Stack>
                          :
                          <span className="badge bg-danger" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}> Not Editable </span>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
      </div>
    ) 
} 
