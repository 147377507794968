// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    width: 100%;
    /* height: calc(100vh - 200px); */
    overflow: auto;
  }

  .mui-table {
    min-width: 650px;
  }

  .sticky-header-cell {
    position: sticky !important;
    top: 0;
    z-index: 2;
    background-color: #000 !important;
  }`, "",{"version":3,"sources":["webpack://./src/main/core/helper/TableView.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iCAAiC;IACjC,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,2BAA2B;IAC3B,MAAM;IACN,UAAU;IACV,iCAAiC;EACnC","sourcesContent":[".table-container {\n    width: 100%;\n    /* height: calc(100vh - 200px); */\n    overflow: auto;\n  }\n\n  .mui-table {\n    min-width: 650px;\n  }\n\n  .sticky-header-cell {\n    position: sticky !important;\n    top: 0;\n    z-index: 2;\n    background-color: #000 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
