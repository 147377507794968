import moment from 'moment';

// helperFunctions.js
export const getTruckNo = (logSheet, isAggregated) => {
    let truckNumber = "";
    if (logSheet && logSheet.truckNo && (isAggregated && (isAggregated===true || isAggregated==='true') && logSheet.truckNo[0])){
      if(isAggregated && (isAggregated===true || isAggregated==='true')){
        if(logSheet.truckNo[0]){
          truckNumber = logSheet.truckNo[0].truckNumber
        }
      }
      else{
        truckNumber = logSheet.truckNo.truckNumber
      }
    }
    else if(logSheet && logSheet.otherTruckNo){
      truckNumber = logSheet.otherTruckNo
    }
    return truckNumber;
};

export const getTrailerNo = (logSheet, isAggregated) => {
    let trailerNumber = "";
        if (logSheet && logSheet.trailerNo && (isAggregated && (isAggregated===true || isAggregated==='true') && logSheet.trailerNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(logSheet.trailerNo[0]){
              trailerNumber = logSheet.trailerNo[0].trailerNumber
            }
          }
          else{
            trailerNumber = logSheet.trailerNo.trailerNumber
          }
        }
        else if(logSheet && logSheet.otherTrailerNo){
          trailerNumber = logSheet.otherTrailerNo
        }
        return trailerNumber;
};

export const getReimbursementCompany = (logSheet, isAggregated) => {
    let reimbursementCompany = "-";
    if(logSheet && logSheet.reimbursement && logSheet.reimbursementCompany && (isAggregated && (isAggregated===true || isAggregated==='true') && logSheet.reimbursementCompany[0])){
        if(isAggregated && (isAggregated===true || isAggregated==='true')){
          if(logSheet.reimbursementCompany[0]){
            reimbursementCompany = logSheet.reimbursementCompany[0].partnerName
          }
        }
        else{
          reimbursementCompany = logSheet.reimbursementCompany[0].partnerName
        }
      }     
      else if(logSheet && logSheet.otherReimbursementCompany){
        reimbursementCompany = logSheet.otherReimbursementCompany
      }
    return reimbursementCompany;
};


export const getTruckNo_2 = (tripSheet, isAggregated) => {
    let truckNumber = "";
    if (tripSheet && tripSheet.truckNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.truckNo[0])){
      if(isAggregated && (isAggregated===true || isAggregated==='true')){
        if(tripSheet.truckNo[0]){
          truckNumber = tripSheet.truckNo[0].truckNumber
        }
      }
      else{
        truckNumber = tripSheet.truckNo.truckNumber
      }
    }
    else if(tripSheet && tripSheet.otherTruckNo){
      truckNumber = tripSheet.otherTruckNo
    }
    return truckNumber;
}

export const getTrailerNo_2 = (tripSheet, isAggregated) => {
    let trailerNumber = "";
    if (tripSheet && tripSheet.trailerNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.trailerNo[0])){
      if(isAggregated && (isAggregated===true || isAggregated==='true')){
        if(tripSheet.trailerNo[0]){
          trailerNumber = tripSheet.trailerNo[0].trailerNumber
        }
      }
      else{
        trailerNumber = tripSheet.trailerNo.trailerNumber
      }
    }
    else if(tripSheet && tripSheet.otherTrailerNo){
      trailerNumber = tripSheet.otherTrailerNo
    }
    return trailerNumber;
}

export const getPickupCompany_2 = (tripSheet, isAggregated) => {
    let pickupCompany = "";
        if (tripSheet && tripSheet.pickUpCompany && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.pickUpCompany[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.pickUpCompany[0]){
              pickupCompany = tripSheet.pickUpCompany[0].partnerName
            }
          }
          else{
            pickupCompany = tripSheet.pickUpCompany.partnerName
          }
        }
        else if(tripSheet && tripSheet.otherPickUpCompany){
          pickupCompany = tripSheet.otherPickUpCompany
        }
        return pickupCompany;
}

export const getDeliveryCompany_2 = (tripSheet, isAggregated) => {
    let deliveryCompany = "";
        if (tripSheet && tripSheet.deliveryCompany && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.deliveryCompany[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.deliveryCompany[0]){
              deliveryCompany = tripSheet.deliveryCompany[0].partnerName
            }
          }
          else{
            deliveryCompany = tripSheet.deliveryCompany.partnerName
          }
        }
        else if(tripSheet && tripSheet.otherDeliveryCompany){
          deliveryCompany = tripSheet.otherDeliveryCompany
        }
        return deliveryCompany;
}

// Retrieve pickup location
export const getPickupLocation = (tripSheet) => {
  let pickUpLocation = "";
  if (tripSheet && tripSheet.pickUpLocation){
    pickUpLocation = tripSheet.pickUpLocation
  }
  else if(tripSheet && tripSheet.otherPickUpLocation){
    pickUpLocation = tripSheet.otherPickUpLocation
  }
  return pickUpLocation;
}

// Retrieve delivery location
export const getDeliveryLocation = (tripSheet) => {
  let deliveryLocation = "";
  if (tripSheet && tripSheet.deliveryLocation){
      deliveryLocation = tripSheet.deliveryLocation
  }
  else if(tripSheet && tripSheet.otherDeliveryLocation){
    deliveryLocation = tripSheet.otherDeliveryLocation
  }
  return deliveryLocation;
}

 // Retrieve pickup company name
 export const getPickupCommodity = (tripSheet, isAggregated) => {
  let pickUpCommodity = "";
  if (tripSheet && tripSheet.pickUpCommodity && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.pickUpCommodity[0])){
    if(isAggregated && (isAggregated===true || isAggregated==='true')){
      if(tripSheet.pickUpCommodity[0]){
        pickUpCommodity = tripSheet.pickUpCommodity[0].commodityName
      }
    }
    else{
      pickUpCommodity = tripSheet.pickUpCommodity.commodityName
    }
  }
  else if(tripSheet && tripSheet.otherPickUpCommodity){
    pickUpCommodity = tripSheet.otherPickUpCommodity
  }
  return pickUpCommodity;
}

// Retrieve Driver Number
  export const getDriverName = (tripSheet, isAggregated) => {
    let driverName = "";
        if (tripSheet && tripSheet.driverNo && (isAggregated && (isAggregated===true || isAggregated==='true') && tripSheet.driverNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(tripSheet.driverNo[0]){
              let firstName = tripSheet.driverNo[0].firstName || "";
              let lastName = tripSheet.driverNo[0].lastName || "";
              driverName = firstName + " " + lastName;
            }
          }
          else{
            let firstName = tripSheet.driverNo.firstName || "";
              let lastName = tripSheet.driverNo.lastName || "";
              driverName = firstName + " " + lastName;
          }
        }
        return driverName;
    }

export const getCreditCard = (fuelSheet, isAggregated) => {
    let cardNumber = "";
    if (fuelSheet && fuelSheet.cardNo && (isAggregated && (isAggregated===true || isAggregated==='true') && fuelSheet.cardNo[0])){
      if(isAggregated && (isAggregated===true || isAggregated==='true')){
        if(fuelSheet.cardNo[0]){
          cardNumber = fuelSheet.cardNo[0].cardNo
        }
      }
      else{
        cardNumber = fuelSheet.cardNo.cardNo
      }
    }
    else if(fuelSheet && fuelSheet.otherCardNo){
        cardNumber = fuelSheet.otherCardNo
    }
    return cardNumber;
}

export const getTruckNo_3 = (fuelSheet, isAggregated) => {
    let truckNumber = "";
    if (fuelSheet && fuelSheet.truckNo && (isAggregated && (isAggregated===true || isAggregated==='true') && fuelSheet.truckNo[0])){
      if(isAggregated && (isAggregated===true || isAggregated==='true')){
        if(fuelSheet.truckNo[0]){
          truckNumber = fuelSheet.truckNo[0].truckNumber
        }
      }
      else{
        truckNumber = fuelSheet.truckNo.truckNumber
      }
    }
    else if(fuelSheet && fuelSheet.otherTruckNo){
      truckNumber = fuelSheet.otherTruckNo
    }
    return truckNumber;
}

// Retrieve Formatted Date
export const getDate = (inputDate) => {
  let sheetDate = "";
  if (inputDate){
    sheetDate = moment((inputDate.substring(0,10)), 'YYYY-MM-DD').format('D MMMM YYYY');
  }
  return sheetDate;
}

// Retrieve Day of the week
export const getDayOfTheWeek = (logSheet) => {
  let dayOfWeek = "NA";
  if (logSheet && logSheet.date){
    const sheetDate = moment(((logSheet.date).substring(0,10)), 'YYYY-MM-DD');
    dayOfWeek = sheetDate.format('dddd');
  }
  return dayOfWeek;
}

// Retrieve Billable Total Hours
export const getBillableTotalHours = (logSheet) => {

  // Returns empty string if either time in or time out is empy/null 
  if(!logSheet.timeIn || !logSheet.timeOut){
    return "";
  }

  // Parse the times with moment
  const startTime = moment(logSheet.timeIn, 'HH:mm');
  let endTime = moment(logSheet.timeOut, 'HH:mm');

  // If end time is before start time, add 1 day to the end time
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }

  // Calculate the duration
  const duration = moment.duration(endTime.diff(startTime));

  // Get the total hours in decimal format
  const totalHours = duration.asHours() - (logSheet.deductions?logSheet.deductions:0);
  return totalHours.toFixed(2);
}

// Retrieve Billable Total Hours for log sheet
export const getLoadsSubTotal = (logSheet, currency=false) => {

    // Returns empty string if either time in or time out is empy/null 
    if(!logSheet.timeIn || !logSheet.timeOut || !logSheet.ratePerHour){
      return "";
    }

    // Parse the times with moment
    const startTime = moment(logSheet.timeIn, 'HH:mm');
    let endTime = moment(logSheet.timeOut, 'HH:mm');

    // If end time is before start time, add 1 day to the end time
    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }

    // Calculate the duration
    const duration = moment.duration(endTime.diff(startTime));

    // Get the total hours and load sub total
    const totalHours = duration.asHours() - (logSheet.deductions?logSheet.deductions:0);
    const loadSubTotal = (totalHours * logSheet.ratePerHour).toFixed(2);
    if(currency){
      return ('$' + loadSubTotal);
    }
    return loadSubTotal;
}

// Retrieve Driver Name
export const getSheetDriverName = (sheet, isAggregated) => {
  let driverName = "";
  if (sheet && sheet.driverNo && (isAggregated && (isAggregated===true || isAggregated==='true') && sheet.driverNo[0])){
    if(isAggregated && (isAggregated===true || isAggregated==='true')){
      if(sheet.driverNo[0]){
        let firstName = sheet.driverNo[0].firstName || "";
        let lastName = sheet.driverNo[0].lastName || "";
        driverName = firstName + " " + lastName;
      }
    }
    else{
      let firstName = sheet.driverNo.firstName || "";
        let lastName = sheet.driverNo.lastName || "";
        driverName = firstName + " " + lastName;
    }
  }
  return driverName;
}

// Retrive waiting period
export const getWaitingPeriod = (tripSheet, period) => {

      let startTime = 0;
      let endTime = 0;

      // Pickup
      if(period === 0){
            if(!tripSheet.pickUpTimeIn || !tripSheet.pickUpTimeOut){
              return 0;
            }
          
            // Parse the times with moment
            startTime = moment(tripSheet.pickUpTimeIn, 'HH:mm');
            endTime = moment(tripSheet.pickUpTimeOut, 'HH:mm');
      }
    
      // Delivery
      else if(period === 1){
            if(!tripSheet.deliveryTimeIn || !tripSheet.deliveryTimeOut){
              return 0;
            }
          
            // Parse the times with moment
            startTime = moment(tripSheet.deliveryTimeIn, 'HH:mm');
            endTime = moment(tripSheet.deliveryTimeOut, 'HH:mm');
      }
    
      // If end time is before start time, add 1 day to the end time
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }
    
      // Calculate the duration
      const duration = moment.duration(endTime.diff(startTime));
    
      // Get the total waiting period in minutes
      const totalPeriod = duration.asMinutes();
      return totalPeriod.toFixed(0);
}

// Retrieve total waiting period
export const getTotalWaitingPeriod = (tripSheet) => {
      let waitingTime = 0;
      let startTime = 0;
      let endTime = 0;
      let duration = 0;

      // Pickup
      if(!tripSheet.pickUpTimeIn || !tripSheet.pickUpTimeOut){
        waitingTime += 0;
      }
      else{
        startTime = moment(tripSheet.pickUpTimeIn, 'HH:mm');
        endTime = moment(tripSheet.pickUpTimeOut, 'HH:mm');
        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
        }
        duration = moment.duration(endTime.diff(startTime));
        waitingTime += duration.asMinutes();
      }    
    
      // Delivery
      if(!tripSheet.deliveryTimeIn || !tripSheet.deliveryTimeOut){
          waitingTime += 0;
      }
      else{
        startTime = moment(tripSheet.deliveryTimeIn, 'HH:mm');
        endTime = moment(tripSheet.deliveryTimeOut, 'HH:mm');
        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
        }
        duration = moment.duration(endTime.diff(startTime));
        waitingTime += duration.asMinutes();
      }
    
      return waitingTime.toFixed(0);
}

// Retrieve Billable waiting period
export const getBillableWaitingPeriod = (tripSheet) => {

      // Waive off the waiting time if 'isWaitingTimeWaivedOff' is set to true
      if(tripSheet && tripSheet.isWaitingTimeWaivedOff)
          return 0;
    
      let pickupWaitingTime = 0;
      let deliveryWaitingTime = 0;
      let startTime = 0;
      let endTime = 0;
      let duration = 0;
    
      // Pickup
      if(!tripSheet.pickUpTimeIn || !tripSheet.pickUpTimeOut){
          pickupWaitingTime = 0;
      }
      else{
        startTime = moment(tripSheet.pickUpTimeIn, 'HH:mm');
        endTime = moment(tripSheet.pickUpTimeOut, 'HH:mm');
        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
        }
        duration = moment.duration(endTime.diff(startTime));
        pickupWaitingTime = duration.asMinutes();
        
        // Waive off the waiting time if less than 1 hour
        if(pickupWaitingTime <= 60)
            pickupWaitingTime = 0;
        else{
          pickupWaitingTime = pickupWaitingTime - 60;
          // Calculate the nearest lower quarter of an hour - 15 minutes
          pickupWaitingTime = Math.floor(pickupWaitingTime / 15) * 15;
        }
      }
    
      // Delivery
      if(!tripSheet.deliveryTimeIn || !tripSheet.deliveryTimeOut){
        deliveryWaitingTime = 0;
      }
      else{
        startTime = moment(tripSheet.deliveryTimeIn, 'HH:mm');
        endTime = moment(tripSheet.deliveryTimeOut, 'HH:mm');
        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
        }
        duration = moment.duration(endTime.diff(startTime));
        deliveryWaitingTime = duration.asMinutes();
      
        // Waive off the waiting time if less than 1 hour
        if(deliveryWaitingTime <= 60)
            deliveryWaitingTime = 0;
        else{
          deliveryWaitingTime = deliveryWaitingTime - 60;
          // Calculate the nearest lower quarter of an hour - 15 minutes
          deliveryWaitingTime = Math.floor(deliveryWaitingTime / 15) * 15;
        }
      }
      return (pickupWaitingTime + deliveryWaitingTime).toFixed(0);
}

// Retrieve Net units
export const getNetUnit = (tripSheet) => {
  let netUnit = "";
  if(tripSheet.typeOfLoad && (tripSheet.typeOfLoad === 311 || tripSheet.typeOfLoad === 321)){
        if(tripSheet.inBoundWeight)
            netUnit = (tripSheet.inBoundWeight/2000).toFixed(2);
  }
  else if(tripSheet.outBoundWeight){
        netUnit = (tripSheet.outBoundWeight/2000).toFixed(2);
  }
  return netUnit;
}

// Retrieve Rate Per Unit
export const getRatePerUnit = (tripSheet) => {
let ratePerUnit = tripSheet.ifFlatRate ? "FLAT RATE" : ((tripSheet.ratePerUnit || tripSheet.ratePerUnit===0) ? `$${tripSheet.ratePerUnit}` : "")
return ratePerUnit;
}

// Retrieve Load subtotal for trip sheet
export const getLoadSubTotal = (tripSheet, currencyRequired=false) => {
let loadSubTotal = "";
if(!tripSheet.ifFlatRate){
  if(tripSheet.ratePerUnit && tripSheet.inBoundWeight && (tripSheet.outBoundWeight || (tripSheet.typeOfLoad && (tripSheet.typeOfLoad === 311 || tripSheet.typeOfLoad === 321)))){
          loadSubTotal = (getNetUnit(tripSheet) * tripSheet.ratePerUnit).toFixed(2);
          if(currencyRequired)
                loadSubTotal = "$" + loadSubTotal;
    }
  }
  else if(tripSheet.ifFlatRate){
      if(tripSheet.loadSubTotal){
          loadSubTotal = (tripSheet.loadSubTotal).toFixed(2);
          if(currencyRequired)
              loadSubTotal = "$" + loadSubTotal;
      }
  }
  return loadSubTotal;
}

// Retrieve Fuel Amount
export const getFuelAmount = (tripSheet, currencyRequired=false) => {
    let fuelAmount = "";
    if(getLoadSubTotal(tripSheet) && tripSheet.fuelRate){
          fuelAmount = (getLoadSubTotal(tripSheet) * tripSheet.fuelRate / 100).toFixed(2);
          if(currencyRequired)
              fuelAmount = "$" + fuelAmount;
    }
    return fuelAmount;
}