import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getcommodities, deletecommodity } from '../helper/adminapicall';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function ManageCommodities() {
    const { admin, token } = isAuthenticated();
    const [commodities, setCommodities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState("");
    const [error, setError] = useState('');

    // Commodity Search
    const [commoditySearch, setCommoditySearch] = useState("");
    const [filteredCommodities, setFilteredCommodities] = useState([]);

    useEffect(() => {
        preload();
    }, []);

    const preload = () => {
        setLoading(true);
        setError('');
        getcommodities(admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error fetching the commodities. Please try again later.');
                } else {
                    setCommodities(data);
                    setFilteredCommodities(data);
                    if(data.length===0){
                        toastifyMessage("warning", 'No commodity found');
                    }
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching the commodities:', err);
                setError('Error fetching the commodities');
                setLoading(false);
                toastifyMessage("error", 'Error fetching the commodities');
            });
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the commodity?");
    const [deleteCommodityId, setDeleteCommodityId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false)
    const handleShowDeleteMessage = () => setShowDeleteMessage(true)

    const deleteThisCommodity = (commodityId) => {
        setLoadingModal(true);
        deletecommodity(commodityId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error deleting the commodity');
                } else {
                    toastifyMessage("success", 'Commodity deleted successfully');

                    // To close the modal
                    handleCancelDeletion();
                    setLoadingModal("");

                    // Reload the values
                    setCommodities(commodities.filter(commodity => (commodity._id !== commodityId)));
                    setFilteredCommodities(commodities.filter(commodity => (commodity._id !== commodityId)))
                }
            })
            .catch(err => {
                console.log('Error deleting the commodity:', err);
                setError('Error deleting the commodity');
                setLoading(false);
                toastifyMessage("error", 'Error deleting the commodity');
            });
    };

    const handleCommoditySearch = (event) => {
        setCommoditySearch(event.target.value);
        const searchTerm = (event.target.value).toLowerCase();
        if(searchTerm==="" || !searchTerm){
            setFilteredCommodities(commodities);
            return;
        }
        const filtered = commodities.filter(commodity =>
            (commodity.commodityName).toLowerCase().includes(searchTerm)
        );
        setFilteredCommodities(filtered);
    };

    return (
        <Base title="Commodity Dashboard" description="Manage/Edit/Delete commodities">
            <Link className="btn btn-info rounded" to="/admin/dashboard">
                Admin Home
            </Link>
            <div className="container">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>
                    Commodities
                </h2>
                {loading ? (
                    <LoadingScreen show={loading} />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for a commodity"
                                value={commoditySearch}
                                onChange={handleCommoditySearch}
                            />
                        </div>
                        <div>
                            {filteredCommodities.map((commodity, index) => (
                                <div key={index} className="card text-center mb-3">
                                    <div className="card-body">
                                        <h3 className="card-title text-left" style={{ fontFamily: 'Englebert', color: 'black' }}>
                                            {commodity.commodityName}
                                        </h3>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-success rounded mb-2"
                                                    to={`/admin/update/commodity/${commodity._id}`}
                                                >
                                                    Update
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeleteCommodityId(commodity._id)
                                                            handleShowDeleteMessage()
                                                        }    
                                                    }
                                                    className="btn btn-danger rounded mb-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisCommodity} id={deleteCommodityId} message={deleteMessage} loadingModal={loadingModal}  />
            <ToastifyMessage/> 
        </Base>
    );
}