import React, { useState, useEffect } from 'react'
import Base from "../../core/Base"
import { isAuthenticated } from "../../auth/helper/adminIndex"
import { Link, useParams } from "react-router-dom";
import { getlocation, updatelocation } from "../helper/adminapicall"
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toastifyMessage } from '../../core/helper/Toast';

export default function UpdateLocation() {

    const { admin, token } = isAuthenticated();
    const { locationId } = useParams();

    const [values, setValues] = useState({
        location: "",
        error: false,
        updatedLocation: "",
        loading: false,
        isLoaded: "",
        didRedirect: false
    });

    const { location, error, updatedLocation, loading, isLoaded, didRedirect } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" })
        getlocation(locationId, admin._id, token).then(data => {
            if (data.error) {
                setValues({ ...values, loading: "", isLoaded: "", error: data.error })
            } else {
                setValues({
                    ...values,
                    ...data,
                    error: "",
                    loading: "",
                    isLoaded: true
                });
            }
        })
        .catch(err => {
            console.log('Error preloading the location:', err);
            setValues({ ...values, error: "Error preloading the location", loading: "" });
            toastifyMessage("error", 'Error preloading the location');
        });
    }

    useEffect(() => {
        preload()
    }, [])

    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
                className="btn btn-sm btn-dark mb-3 ml-3"
                to="/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: false, updatedLocation: "", loading: true })

        // Field validations
        const requiredFields = [location];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", updatedLocation: "" });
            return;
        }

        updatelocation(locationId, token, admin._id, { location })
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", updatedLocation: "" })
                    toastifyMessage("error", 'Error while updating the location');
                } else {
                    setValues({
                        ...values,
                        ...data,
                        loading: "",
                        error: "",
                        updatedLocation: "Location updated successfully"
                    });
                    toastifyMessage("success", 'Location updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the location:', err);
                setValues({ ...values, error: "Error updating the location", loading: "" });
                toastifyMessage("error", 'Error updating the location');
            });
    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: updatedLocation ? "" : "none" }}
        >
            <h4>{updatedLocation}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );

    const updateLocationForm = () => (
        <form className="mt-4"> 
            <div className="form-group">
                <p className="text-dark"> Enter the Location Name <span className="text-info">*</span></p>
                <input
                    className="form-control"
                    onChange={handleChange("location")}
                    type="text"
                    name="location"
                    value={location}
                    placeholder={location}
                    autoFocus
                    required
                />
                <button 
                    onClick={onSubmit}
                    className="btn btn-outline-info mt-3"
                > 
                    Update Location 
                </button>
            </div>
        </form>
    )

    return (
        <Base
            title="Location Dashboard"
            description="Edit the Location Name"
            className="container bg-info p-4"
        >
            {returnToAdminDashboard()}

            <div className="row bg-white text-dark rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                    {isLoaded && updateLocationForm()}
                    {successMessage()}
                    {warningMessage()}
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    )
}
