import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { gettrucks, deletetruck } from '../helper/adminapicall';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function ManageTrucks() {
    const { admin, token } = isAuthenticated();
    const [trucks, setTrucks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState("");
    const [error, setError] = useState('');

    // Truck Search
    const [truckSearch, setTruckSearch] = useState("");
    const [filteredTrucks, setFilteredTrucks] = useState([]);

    useEffect(() => {
        preload();
    }, []);

    const preload = () => {
        setLoading(true);
        setError('');
        gettrucks(admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error fetching the trucks. Please try again later.');
                } else {
                    setTrucks(data);
                    setFilteredTrucks(data);
                    if(data.length===0){
                        toastifyMessage("warning", 'No trucks found');
                        }
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching the trucks:', err);
                setError('Error fetching the trucks');
                setLoading(false);
                toastifyMessage("error", 'Error fetching the trucks');
            });
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the truck?");
    const [deleteTruckId, setDeleteTruckId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false)
    const handleShowDeleteMessage = () => setShowDeleteMessage(true)

    const deleteThisTruck = (truckId) => {
        setLoadingModal(true);
        deletetruck(truckId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error deleting the truck');
                } else {
                    toastifyMessage("success", 'Truck deleted successfully');
                    
                    // To close the modal
                    handleCancelDeletion();
                    setLoadingModal("");
                
                    // Reload the values
                    setTrucks(trucks.filter(truck => (truck._id !== truckId)));
                    setFilteredTrucks(trucks.filter(truck => (truck._id !== truckId)));
                }
            })
            .catch(err => {
                console.log('Error deleting the truck:', err);
                setError('Error deleting the truck');
                setLoading(false);
                toastifyMessage("error", 'Error deleting the truck');
            });
    };

    // For View Truck Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [truckData, setTruckData] = useState(null);

    const viewTruckModal = () => (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Truck Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Number:</b> {truckData && truckData.truckNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>VIN:</b> {truckData && truckData.vehicleIdentificationNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Plate:</b> {truckData && truckData.plateNumber}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Province:</b> {truckData && truckData.province}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const handleTruckSearch = (event) => {
        setTruckSearch(event.target.value);
        const searchTerm = (event.target.value).toLowerCase();
        if(searchTerm==="" || !searchTerm){
            setFilteredTrucks(trucks);
            return;
        }
        const filtered = trucks.filter(truck =>
            (truck.truckNumber).toLowerCase().includes(searchTerm)
        );
        setFilteredTrucks(filtered);
    };

    return (
        <Base title="Truck Dashboard" description="Manage/Edit/Delete trucks">
            <Link className="btn btn-info rounded mb-3" to="/admin/dashboard">
                Admin Home
            </Link>
            <div className="container">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>
                    Trucks
                </h2>
                {loading ? (
                    <LoadingScreen show={loading} />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for a truck"
                                value={truckSearch}
                                onChange={handleTruckSearch}
                            />
                        </div>
                        <div>
                            {filteredTrucks.map((truck, index) => (
                                <div key={index} className="card text-center mb-3">
                                    <div className="card-body">
                                        <h3 className="card-title text-left" style={{ fontFamily: 'Englebert', color: 'black' }}>
                                            {truck.truckNumber}
                                        </h3>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-success rounded mb-2"
                                                    to={`/admin/update/truck/${truck._id}`}
                                                >
                                                    Update
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeleteTruckId(truck._id)
                                                            handleShowDeleteMessage()
                                                        }    
                                                    }
                                                    className="btn btn-danger rounded mb-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => {
                                                        setTruckData(truck);
                                                        handleShow();
                                                    }}
                                                    className="btn btn-info rounded mb-2"
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {viewTruckModal()}
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTruck} id={deleteTruckId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/> 
        </Base>
    );
}
