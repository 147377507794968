import React, {useState} from 'react'
import Base from "../../core/Base"
import { isAuthenticated } from "../../auth/helper/adminIndex"
import {Link} from "react-router-dom";
import {createcommodity} from "../helper/adminapicall"
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toast } from 'react-toastify';
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddCommodity() {

    const {admin, token} = isAuthenticated()

    const [values, setValues] = useState({
        commodityName: "",
        error: false,
        createdCommodity: "",
        loading: false,
        didRedirect: false
    });

    const { commodityName, error, createdCommodity, loading, didRedirect } = values

    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, createdCommodity: "", loading: true})

        // Field validations
        const requiredFields = [commodityName];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'Commodity name is mandatory', loading: "", createdCommodity: ""});
            toast.warning('Commodity name is mandatory', {
               position: "top-right",
               autoClose: 3000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light"
               });
            return;
        }

        createcommodity(admin._id, token, {commodityName})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", createdCommodity: "" })
                    toast.error('Error while adding the commodity', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }
                else{
                    setValues({
                        ...values,
                        commodityName: "",                       
                        loading: "",
                        error: "",
                        createdCommodity: "Commodity added succesfully"
                    });
                    toast.success('Commodity added successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }
            })
            .catch(err => {
                console.log('Error onboarding the commodity:', err);
                setValues({ ...values, error: "Error onboarding the commodity", loading: "" });
                toastifyMessage("error", 'Error onboarding the commodity');
              });

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: createdCommodity ? "" : "none" }}
        >
            <h4>{createdCommodity}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );

    const createCommodityForm = () => (
        <>
            <form className="mt-4">
                <div className="form-group">
                    <label className="text-light"> Enter the Commodity Name <span className="text-info">*</span></label>
                    <input type="text"
                    className="form-control"
                    onChange={handleChange("commodityName")}
                    value={commodityName}
                    placeholder="For Ex. Sugar"
                    autoFocus
                    required
                    />
                </div>
                    <button 
                        onClick ={onSubmit}
                        className="btn btn-outline-info"> Add Commodity 
                    </button>
            </form>
            {LoadingScreen(loading)}
        </>
    )

    return (
        <Base
        title="Commodity Dashboard"
        description="Add a new Commodity"
        className="container bg-info p-4"
        >
           {returnToAdminDashboard()}
            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                    {createCommodityForm()}
                    {successMessage()}
                    {warningMessage()}
                
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    )
}
