import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import Paper from '@mui/material/Paper';
import moment from 'moment';
import LoaderScreen from './LoaderScreen';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { flipVerificationStatusForTheTripCard } from '../../admin/helper/adminapicall';
import "./TableView.css";
import ToastifyMessage from './ToastifyMessage';
import {toastifyMessage} from './Toast';

export default function LogSheetTableView({logSheets, editURL, isAggregated, isAdmin, isDriver, isDriverNameRequired=false, isBillingFeatureRequired=false, setDeleteLogSheetId, handleShowDeleteMessage, isReimbursementRequired, flipSheetStatus}) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    // Common Logic for both driver & admin
    // Retrieve Truck Number
    const getTruckNo = (logSheet) => {
        let truckNumber = "";
        if (logSheet && logSheet.truckNo && (isAggregated && (isAggregated===true || isAggregated==='true') && logSheet.truckNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(logSheet.truckNo[0]){
              truckNumber = logSheet.truckNo[0].truckNumber
            }
          }
          else{
            truckNumber = logSheet.truckNo.truckNumber
          }
        }
        else if(logSheet && logSheet.otherTruckNo){
          truckNumber = logSheet.otherTruckNo
        }
        return truckNumber;
    }

    // Retrieve Trailer Number
    const getTrailerNo = (logSheet) => {
        let trailerNumber = "";
        if (logSheet && logSheet.trailerNo && (isAggregated && (isAggregated===true || isAggregated==='true') && logSheet.trailerNo[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(logSheet.trailerNo[0]){
              trailerNumber = logSheet.trailerNo[0].trailerNumber
            }
          }
          else{
            trailerNumber = logSheet.trailerNo.trailerNumber
          }
        }
        else if(logSheet && logSheet.otherTrailerNo){
          trailerNumber = logSheet.otherTrailerNo
        }
        return trailerNumber;
    }

    // Retrieve Formatted Date
    const getDate = (inputDate) => {
      let sheetDate = "";
      if (inputDate){
        sheetDate = moment((inputDate.substring(0,10)), 'YYYY-MM-DD').format('D MMMM YYYY');
      }
      return sheetDate;
    }

    // Retrieve Day of the week
    const getDayOfTheWeek = (logSheet) => {
      let dayOfWeek = "NA";
      if (logSheet && logSheet.date){
        const sheetDate = moment(((logSheet.date).substring(0,10)), 'YYYY-MM-DD');
        dayOfWeek = sheetDate.format('dddd');
      }
      return dayOfWeek;
    }

    // Retrieve Billable Total Hours
    const getBillableTotalHours = (logSheet) => {

      // Returns empty string if either time in or time out is empy/null 
      if(!logSheet.timeIn || !logSheet.timeOut){
        return "";
      }

      // Parse the times with moment
      const startTime = moment(logSheet.timeIn, 'HH:mm');
      let endTime = moment(logSheet.timeOut, 'HH:mm');

      // If end time is before start time, add 1 day to the end time
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }

      // Calculate the duration
      const duration = moment.duration(endTime.diff(startTime));

      // Get the total hours in decimal format after subtracting any deductions available
      const totalHours = duration.asHours() - (logSheet.deductions?logSheet.deductions:0);
      return totalHours.toFixed(2);
    }

    // Retrieve Billable Total Hours
    const getLoadsSubTotal = (logSheet) => {

      // Returns empty string if either time in or time out is empy/null 
      if(!logSheet.timeIn || !logSheet.timeOut || !logSheet.ratePerHour){
        return "";
      }

      // Parse the times with moment
      const startTime = moment(logSheet.timeIn, 'HH:mm');
      let endTime = moment(logSheet.timeOut, 'HH:mm');

      // If end time is before start time, add 1 day to the end time
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }

      // Calculate the duration
      const duration = moment.duration(endTime.diff(startTime));

      // Get the total hours and load sub total
      const totalHours = duration.asHours().toFixed(2) - (logSheet.deductions?logSheet.deductions:0)
      const loadSubTotal = (totalHours * logSheet.ratePerHour).toFixed(2);
      return ('$' + loadSubTotal);
    }

    // Retrieve Driver Name
    const getDriverName = (logSheet) => {
      let driverName = "";
      if (logSheet && logSheet.driverNo && (isAggregated && (isAggregated===true || isAggregated==='true') && logSheet.driverNo[0])){
        if(isAggregated && (isAggregated===true || isAggregated==='true')){
          if(logSheet.driverNo[0]){
            let firstName = logSheet.driverNo[0].firstName || "";
            let lastName = logSheet.driverNo[0].lastName || "";
            driverName = firstName + " " + lastName;
          }
        }
        else{
          let firstName = logSheet.driverNo.firstName || "";
            let lastName = logSheet.driverNo.lastName || "";
            driverName = firstName + " " + lastName;
        }
      }
      return driverName;
    }

    // Retrieve Reimbursement Details
    const getReimbursementCompany = (logSheet) => {
      let reimbursementCompany = "-";
      if(logSheet && logSheet.reimbursement && logSheet.reimbursementCompany && (isAggregated && (isAggregated===true || isAggregated==='true') && logSheet.reimbursementCompany[0])){
          if(isAggregated && (isAggregated===true || isAggregated==='true')){
            if(logSheet.reimbursementCompany[0]){
              reimbursementCompany = logSheet.reimbursementCompany[0].partnerName
            }
          }
          else{
            reimbursementCompany = logSheet.reimbursementCompany[0].partnerName
          }
        }     
        else if(logSheet && logSheet.otherReimbursementCompany){
          reimbursementCompany = logSheet.otherReimbursementCompany
        }
      return reimbursementCompany;
    }

    // Show Actions option to the driver only if the sheet is within the current week (last 7 days)
    const ifEditAllowed = (inputDate) => {
      let isOlderThanAWeek = false;
      if (inputDate){
            // Calculate the date 7 days ago from now
            const sevenDaysAgo = moment().subtract(7, 'days');
            // Check if the input date is before the date 7 days ago
            isOlderThanAWeek = moment((inputDate.substring(0,10))).isBefore(sevenDaysAgo);
      }
      return !isOlderThanAWeek;
    }

     // State variables for admin
     const {admin, token} = isAuthenticated();
     const [values, setValues] = useState({
       error: false,
       updatedTripCard: "",
       loading: false,
       loadingModal: false
     });
     
     const { error, updatedTripCard, loading, loadingModal, formData } = values

    // Flip approval status for the trip card
    const onFlipVerificationStatus = (logsheetid) => {
      setValues({ ...values, error: "", loading: true, updatedTripCard: "" });
  
      flipVerificationStatusForTheTripCard(logsheetid, admin._id, token)
        .then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: ""});
            } else {
                setValues({
                    ...values,
                    ...data,
                    loading: "",
                    error: "",
                    updatedTripCard: "Verfication status updated successfully for the trip card"
                });
  
                // Flip the value in the invoice
                flipSheetStatus(logsheetid, data.isTripCardVerified);
                toastifyMessage("success", 'Verfication status updated successfully for the trip card');
            }
        })
        .catch(err => {
          console.log('Error updating the status of the trip card:', err);
          setValues({ ...values, error: "Error updating the status of the trip card", loading: "" });
          toastifyMessage("error", 'Error updating the status of the trip card');
      });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
      <>
        <div className="table-container">
          <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 200px)' }}>
              <Table stickyHeader className="mui-table" size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="sticky-header-cell">Date</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Day</StyledTableCell>
      
                    {isDriverNameRequired && <StyledTableCell className="sticky-header-cell" align="center">Driver</StyledTableCell>}
      
                    <StyledTableCell className="sticky-header-cell" align="center">Truck</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Trailer</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Start City</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Finish City</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Start Odometer</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">End Odometer</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Down time</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Billable Start Time</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Billable End Time</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Deductions (in hour)</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Notes</StyledTableCell>
                    { isBillingFeatureRequired && <StyledTableCell className="sticky-header-cell" align="center">Billable Total Hours</StyledTableCell> }
                    { isBillingFeatureRequired && <StyledTableCell className="sticky-header-cell" align="center">Rate (in $)</StyledTableCell> }
                    <StyledTableCell className="sticky-header-cell" align="center">Number of Loads Delivered</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Night out</StyledTableCell>
                    { isBillingFeatureRequired && <StyledTableCell className="sticky-header-cell" align="centers">Night out Subtotal</StyledTableCell> }
                    { isBillingFeatureRequired && <StyledTableCell className="sticky-header-cell" align="centers">Subtotal</StyledTableCell> }
      
                    <StyledTableCell className="sticky-header-cell" align="center">Reimbursement</StyledTableCell>
                    { isReimbursementRequired && <StyledTableCell className="sticky-header-cell" align="center">Reimbursement Date</StyledTableCell> }
                    { isReimbursementRequired && <StyledTableCell className="sticky-header-cell" align="center">Reimbursement Purpose</StyledTableCell> }
                    { isReimbursementRequired && <StyledTableCell className="sticky-header-cell" align="center">Reimbursement Location</StyledTableCell> }
                    { isReimbursementRequired && <StyledTableCell className="sticky-header-cell" align="center">Reimbursement Amount</StyledTableCell> }
      
                    <StyledTableCell className="sticky-header-cell" align="center">Photos</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logSheets && logSheets.map((logSheet, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">{logSheet.date ? getDate(logSheet.date) : ""}</StyledTableCell>
                      <StyledTableCell align="center">{getDayOfTheWeek(logSheet)}</StyledTableCell>
                  
                      {isDriverNameRequired && <StyledTableCell align="center">{getDriverName(logSheet)}</StyledTableCell>}
                  
                      <StyledTableCell align="center">{getTruckNo(logSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{getTrailerNo(logSheet)}</StyledTableCell>
                      <StyledTableCell align="center">{logSheet.pickUpCity ? logSheet.pickUpCity : ""}</StyledTableCell>
                      <StyledTableCell align="center">{logSheet.deliveryCity ? logSheet.deliveryCity : ""}</StyledTableCell>
                      <StyledTableCell align="center">{logSheet.startOdometer ? logSheet.startOdometer : ""}</StyledTableCell>
                      <StyledTableCell align="center">{logSheet.endOdometer ? logSheet.endOdometer : ""}</StyledTableCell>
                      <StyledTableCell align="center">{(logSheet.downTime || logSheet.downTime===0 ) ? logSheet.downTime : ""}</StyledTableCell>
                      <StyledTableCell align="center">{logSheet.timeIn ? logSheet.timeIn : ""}</StyledTableCell>
                      <StyledTableCell align="center">{logSheet.timeOut ? logSheet.timeOut : ""}</StyledTableCell>
                      <StyledTableCell align="center">{(logSheet.deductions || logSheet.deductions===0 ) ? logSheet.deductions : ""}</StyledTableCell>
                      <StyledTableCell align="center">{logSheet.deductionNotes ? logSheet.deductionNotes : "-"}</StyledTableCell>
                  
                      { isBillingFeatureRequired && <StyledTableCell align="center">{getBillableTotalHours(logSheet)}</StyledTableCell> }
                      { isBillingFeatureRequired && <StyledTableCell align="center">{logSheet.ratePerHour ? ('$' + logSheet.ratePerHour.toFixed(2)) : "-"}</StyledTableCell> }
                      
                      <StyledTableCell align="center">{logSheet.numberOfLoads ? logSheet.numberOfLoads : "-"}</StyledTableCell>
                      <StyledTableCell align="center">
                        {
                          logSheet.nightOut ? 
                            (
                              logSheet.nightOutPurpose ? 
                              (
                                <>
                                  Yes <br />
                                  {logSheet.nightOutPurpose}
                                </>
                              ) : 'Yes'): 
                          'No'
                        }
                      </StyledTableCell>
                      
                      { isBillingFeatureRequired && <StyledTableCell align="center">{(logSheet.nightOut && logSheet.nightOutRate) ? ('$' + logSheet.nightOutRate.toFixed(2)) : "-" }</StyledTableCell> }
                      { isBillingFeatureRequired && <StyledTableCell align="center">{getLoadsSubTotal(logSheet)}</StyledTableCell> }
                      
                      <StyledTableCell align="center">{logSheet.reimbursement ? 'Yes' : 'No'}</StyledTableCell>
                      { isReimbursementRequired &&  <StyledTableCell align="center">{(logSheet.reimbursement && logSheet.reimbursementDate) ? getDate(logSheet.reimbursementDate) : "-"}</StyledTableCell> }
                      { isReimbursementRequired &&  <StyledTableCell align="center">{(logSheet.reimbursement && logSheet.reimbursementPurpose) ? (logSheet.reimbursementPurpose) : "-"}</StyledTableCell> }
                      { isReimbursementRequired &&  <StyledTableCell align="center">{(logSheet.reimbursement && logSheet.reimbursementLocation) ? (logSheet.reimbursementLocation) : "-"}</StyledTableCell> }
                      { isReimbursementRequired &&  <StyledTableCell align="center">{(logSheet.reimbursement && (logSheet.reimbursementAmount || logSheet.reimbursementAmount===0)) ? (logSheet.reimbursementAmount) : "-"}</StyledTableCell> }
                      
                      <StyledTableCell align="center">
                          <Stack direction="row" alignItems="center" spacing={1}>
                              { (logSheet.photo1Name) &&
                                  <ImageCardS3 id={logSheet._id} photoID={logSheet.photo1Name} caption={"Log Sheet"} type={'photo1'} />
                              }
                              { ((logSheet.photo1 && logSheet.photo1.data)) &&
                                  <ImageCard id={logSheet._id} caption={"Log Sheet"} type={'photo1'} />
                              }
                              { (logSheet.photo2Name) &&
                                  <ImageCardS3 id={logSheet._id} photoID={logSheet.photo2Name} caption={"Log Sheet"} type={'photo2'} />
                              }
                              { ((logSheet.photo2 && logSheet.photo2.data)) &&
                                  <ImageCard id={logSheet._id} caption={"Log Sheet"} type={'photo2'} />
                              }
                              { (logSheet.photo3Name) &&
                                  <ImageCardS3 id={logSheet._id} photoID={logSheet.photo3Name} caption={"Log Sheet"} type={'photo3'} />
                              }
                              { ((logSheet.photo3 && logSheet.photo3.data)) &&
                                  <ImageCard id={logSheet._id} caption={"Log Sheet"} type={'photo3'} />
                              }
                              { (logSheet.photo4Name) &&
                                  <ImageCardS3 id={logSheet._id} photoID={logSheet.photo4Name} caption={"Log Sheet"} type={'photo4'} />
                              }
                              { ((logSheet.photo4 && logSheet.photo4.data)) &&
                                  <ImageCard id={logSheet._id} caption={"Log Sheet"} type={'photo4'} />
                              }                            
                          </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                          {
                            isAdmin &&
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton aria-label="delete" size="large" color="error" onMouseDown={handleMouseDownPassword}
                                onClick={()=>{
                                  setDeleteLogSheetId(logSheet._id);
                                  handleShowDeleteMessage();
                                  }}>
                                      <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                                          
                              <Link to={editURL+`${logSheet._id}`}>
                                <IconButton aria-label="edit" size="large" color="success" onMouseDown={handleMouseDownPassword}>
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Link>
                                
                              <IconButton size="medium" color="success" onMouseDown={handleMouseDownPassword} onClick={() => {onFlipVerificationStatus(logSheet._id) }}>
                                      { logSheet && logSheet.isTripCardVerified ? 
                                          <span className="badge bg-success" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}> Approved </span> :
                                          <span className="badge bg-danger" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}> Approve </span>
                                      }
                              </IconButton>
                            </Stack>
                          }
  
                          {
                            isDriver && ( (!logSheet.isTripCardVerified && ifEditAllowed(logSheet.date)) ?
                            <Stack direction="row" alignItems="center" spacing={1}>                            
                              <Link to={editURL+`${logSheet._id}`}>
                                <IconButton aria-label="edit" size="large" color="success" onMouseDown={handleMouseDownPassword}>
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Link>
                            </Stack>
                            :
                            <span className="badge bg-danger" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}> Not Editable </span>
                          )}
  
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </div>
      {LoaderScreen(loading)}
      <ToastifyMessage/> 
      </>
    )
}