import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getcards, deletecard } from '../helper/adminapicall';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function ManageCards() {
    const { admin, token } = isAuthenticated();
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [loadingModal, setLoadingModal] = useState("");
    const [show, setShow] = useState(false);
    const [cardData, setCardData] = useState(null);

    // Fuel Card Search
    const [cardSearch, setCardSearch] = useState("");
    const [filteredCards, setFilteredCards] = useState([]);

    useEffect(() => {
        preload();
    }, []);

    const handleClose = () => setShow(false); // Define handleClose function
    const handleShow = () => setShow(true); // Define handleShow function

    const preload = () => {
        setLoading(true);
        setError('');
        getcards(admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error fetching the cards. Please try again later.');
                } else {
                    setCards(data);
                    setFilteredCards(data);
                    if(data.length===0){
                        toastifyMessage("warning", 'No fuel card found');
                    }
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching the cards:', err);
                setError('Error fetching the cards');
                setLoading(false);
                toastifyMessage("error", 'Error fetching the cards');
            });
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the fuel card?");
    const [deleteFuelCardId, setDeleteFuelCardId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false)
    const handleShowDeleteMessage = () => setShowDeleteMessage(true)

    const deleteThisCard = (cardId) => {
        setLoadingModal(true);
        deletecard(cardId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error deleting the fuel card');
                } else {
                    toastifyMessage("success", 'Fuel card deleted successfully');
                    // To close the modal
                    handleCancelDeletion();
                    setLoadingModal("");
                
                    // Reload the values
                    setCards(cards.filter(card => (card._id !== cardId)));
                    setFilteredCards(cards.filter(card => (card._id !== cardId)));
                }
            })
            .catch(err => {
                console.log('Error deleting the card:', err);
                setError('Error deleting the card');
                setLoading(false);
                toastifyMessage("error", 'Error deleting the card');
            });
    };

    const viewCardModal = () => (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Fuel Card Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Fuel Card Number:</b> {cardData && cardData.cardNo}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Fuel Supplier:</b> {cardData && cardData.fuelSupplier}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const handleCardSearch = (event) => {
        setCardSearch(event.target.value);
        const searchTerm = (event.target.value).toLowerCase();
        if(searchTerm==="" || !searchTerm){
            setFilteredCards(cards);
            return;
        }
        const filtered = cards.filter(card =>
            (card.cardNo).toLowerCase().includes(searchTerm)
        );
        setFilteredCards(filtered);
    };

    return (
        <Base title="Fuel Card Dashboard" description="Manage/Edit/Delete cards">
            <Link className="btn btn-info rounded" to="/admin/dashboard">
                Admin Home
            </Link>
            <div className="container">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>
                    Fuel Cards
                </h2>
                {loading ? (
                    <LoadingScreen show={loading} />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for a fuel card"
                                value={cardSearch}
                                onChange={handleCardSearch}
                            />
                        </div>
                        <div>
                            {filteredCards.map((card, index) => (
                                <div key={index} className="card text-center mb-3">
                                    <div className="card-body">
                                        <h3 className="card-title text-left" style={{ fontFamily: 'Englebert', color: 'black' }}>
                                            {card.cardNo}
                                        </h3>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-success rounded mb-2"
                                                    to={`/admin/update/card/${card._id}`}
                                                >
                                                    Update
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeleteFuelCardId(card._id)
                                                            handleShowDeleteMessage()
                                                        }    
                                                    }
                                                    className="btn btn-danger rounded mb-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => {
                                                        setCardData(card);
                                                        handleShow();
                                                    }}
                                                    className="btn btn-info rounded mb-2"
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {viewCardModal()}
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisCard} id={deleteFuelCardId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/> 
        </Base>
    );
}
