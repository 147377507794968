import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getallfuelsheets, deletefuellogsheet, fetchImageFromS3 } from '../helper/adminapicall';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import PreparingDownload from '../../core/helper/PreparingDownload';
import LoadingScreen from '../../core/helper/LoadingScreen';
import FuelLogSheetTableView from '../../core/helper/FuelLogSheetTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toastifyMessage } from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import '../AdminSideSheet.css';
import { getTruckNo_3, getCreditCard, getDate, getSheetDriverName } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";


export default function ViewFuelSheets() {
    const { admin, token } = isAuthenticated();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        fuelSheets: []
    });

    const { startDate, endDate, error, loading, loadingModal, isLoaded, fuelSheets } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getallfuelsheets(admin._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
                toastifyMessage("error", "Error fetching the fuel sheets");
            } else {
                const updatedValues = {
                    ...values,
                    fuelSheets: data,
                    loading: "",
                    isLoaded: true
                };

                setValues(updatedValues);
                if (data.length === 0) {
                    toastifyMessage("warning", "No fuel sheet found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the fuel sheets:', err);
            setValues({ ...values, error: "Error loading the fuel sheets", loading: "" });
            toastifyMessage("error", 'Error loading the fuel sheets');
        });
    };

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const systemDate = new Date();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Fuel Sheet`, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`Date: ${moment(systemDate).format('MMMM D, YYYY')}`, 10, 30);
        doc.text(`Report Duration: ${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 10, 35);

        const tableColumns = ["Sr. No.", "Refuelling Date", "Driver", "Card Number", "Truck",  "Odometer", "DEF (in Litres)", "Fuel (in Litres)", "Location"];

        const tableRows = fuelSheets.map((fuelSheet, index) => {
            const row = [
                index + 1,
                fuelSheet.refuellingDate ? getDate(fuelSheet.refuellingDate) : "" || "",
                getSheetDriverName(fuelSheet, true) || "",
                getCreditCard(fuelSheet, true),
                getTruckNo_3(fuelSheet, true),
                (fuelSheet.odometer || fuelSheet.odometer===0) ? fuelSheet.odometer : "",
                (fuelSheet.def || fuelSheet.def===0) ? fuelSheet.def : "",
                (fuelSheet.fuel || fuelSheet.fuel===0) ? fuelSheet.fuel : "",
                fuelSheet.location || "",

            ];
            return row;
        });

        doc.autoTable({
            startY: 45,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 9,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        // Add the footer strip
        let finalY = doc.previousAutoTable.finalY + 10;

        const stripY = finalY + 50;
        const stripHeight = 5;

        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }

        const filename = "Fuel_sheets.pdf";
        doc.save(filename);

    };

    const downloadImagesPDF = async () => {
        // Loading animation
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();

        const doc = new jsPDF('landscape');
        let startY = 20;
        let lastPageHeight = 0;
        let hasImage = false;
        let countOfImages = 0;
    
        for (const [index, fuelSheet] of fuelSheets.entries()) {
            const imagesData = [];
            let imagesCount = 0;
    
            if (isS3IntegrationEnabled) {
                // Check for fuelPhoto1
                if (fuelSheet && fuelSheet.fuelPhoto1Name) {
                    const image1 = await fetchImageFromS3(fuelSheet._id, 'fuelPhoto1');
                    if (image1) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image1 });
                    }
                }
    
                // Check for fuelPhoto2
                if (fuelSheet && fuelSheet.fuelPhoto2Name) {
                    const image2 = await fetchImageFromS3(fuelSheet._id, 'fuelPhoto2');
                    if (image2) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image2 });
                    }
                }
                console.log(imagesData);
            }
    
            if (isMongoDBImageReadEnabled) {
                // Check for fuelPhoto1
                if (fuelSheet.fuelPhoto1 && fuelSheet.fuelPhoto1.data) {
                    imagesCount += 1;
                    const imgData = `data:${fuelSheet.fuelPhoto1.contentType};base64,${fuelSheet.fuelPhoto1.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
    
                // Check for fuelPhoto2
                if (fuelSheet.fuelPhoto2 && fuelSheet.fuelPhoto2.data) {
                    imagesCount += 1;
                    const imgData = `data:${fuelSheet.fuelPhoto2.contentType};base64,${fuelSheet.fuelPhoto2.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            }
    
            if (imagesData.length > 0) {
                hasImage = true;
                countOfImages += imagesData.length;
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Fuel Sheet ${index + 1}`, 10, startY);
                startY += 10;
    
                for (const imageData of imagesData) {
                    const imgProps = doc.getImageProperties(isS3IntegrationEnabled ? imageData.url : imageData.data);
                    const imgWidth = 150;
                    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
                    doc.text(imageData.label, 10, startY);
                    doc.addImage((isS3IntegrationEnabled ? imageData.url : imageData.data), 'JPEG', 10, startY + 10, imgWidth, imgHeight);
    
                    startY += imgHeight + 20;
    
                    if (startY + imgHeight > doc.internal.pageSize.getHeight() - 20) {
                        doc.addPage();
                        startY = 20;
                    }
                }
    
                startY += 20;
                if (startY === 40) {
                    startY = 20;
                }
                lastPageHeight = startY;
            }
        }
    
        const pageHeight = doc.internal.pageSize.getHeight();
        const lastPageNumber = doc.internal.getNumberOfPages();
        if ((lastPageHeight < pageHeight - 20) && (lastPageNumber > countOfImages)) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }
    
        // Close the loading animation before saving the pdf
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });
        
        if (!hasImage) {
            toastifyMessage("warning", "No images exist for the fuel sheets");
            return;
        }
    
        const filename = "Fuel_sheet_photos.pdf";
        doc.save(filename);
    };
    
    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Sheet
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    // Only required for Admin
    // For fuel sheet table
    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the fuel sheet?");
    const [deleteFuelSheetId, setDeleteFuelSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Fuel Log Sheet using the Admin privilege
    const deleteThisFuelSheet = (fuelLogSheetId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletefuellogsheet(fuelLogSheetId, admin._id, token)
          .then(data=> {
              if(data.error){
                setValues({ ...values, error: data, loadingModal: "" })
                toastifyMessage("error", "Error deleting the fuel sheet");
              }
              else{
                toastifyMessage("success", "Fuel Sheet deleted successfully");

                // To close the modal & Reload the values
                handleCancelDeletion();
                setValues({ ...values, error: "", loadingModal: "", fuelSheets: (fuelSheets.filter(sheet => (sheet._id !== fuelLogSheetId)))})            
              }
          })
          .catch(err => {
            console.log('Error deleting the fuel sheet:', err);
            setValues({ ...values, error: "Error deleting the fuel sheet", loadingModal: "" });
            toastifyMessage("error", 'Error deleting the fuel sheet');
        });
    }

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Fuel Sheet Dashboard" description="View/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="fuel-log-sheet-dashboard">
                <div className="fuel-log-sheet-header">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>View Fuel Sheets</h2>
                    {dateFilterForm()}
                </div>
                    
                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the fuel sheets</h4>}
                    {isLoaded && !error && fuelSheets.length > 0 && (  
                        <div id="fuel-log-sheet-table">
                            <FuelLogSheetTableView
                                fuelSheets={fuelSheets}
                                editURL={`/admin/update/driver/fuellogsheet/`}
                                isAggregated={true}
                                isAdmin={true} isDriverNameRequired={true}
                                setDeleteFuelSheetId={setDeleteFuelSheetId}
                                handleShowDeleteMessage={handleShowDeleteMessage}
                            />
                        </div>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisFuelSheet} id={deleteFuelSheetId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage />
        </Base>
    )
}