import React, { useState, useEffect } from 'react';
import Base from "../core/Base";
import {pChangePassword,  pisAuthenticated } from "../auth/helper/partnerIndex";
import { Link, Redirect } from "react-router-dom";
import { getpartner } from "./helper/partnerapicall";
import { Modal, Button, Container, Row, Col} from 'react-bootstrap';
import ToastifyMessage from '../core/helper/ToastifyMessage';
import {toastifyMessage} from '../core/helper/Toast';
import LightLoaderScreen from '../core/helper/LightLoaderScreen';

export default function PartnerDashboard() {
    const { partner, token } = pisAuthenticated();
    const [partnerData, setPartnerData] = useState({});

    // Reset Password Fields
    const [values, setValues] = useState({
        password: "",
        newPassword: "",
        cnfNewPassword: "",
        loading: "",
        loadingModal: "",
        error: ""
     });

    const { password, newPassword, cnfNewPassword, loading, loadingModal, error } = values;
    
    const performRedirect = () => {  
        if (!partner) {
            return (
                <Redirect to="/" />
            )
        }
    }
        
    useEffect(() => {
        const partnerId = (partner && partner._id) ? partner._id : "";
        getpartner(partnerId, token).then(data => {
            if (data.error) {
                console.error("Error loading the customer details:", data.error);
            } else {
                setPartnerData(data);
            }
        })
        .catch(err => {
            console.log('Error loading the customer details:', err);
            setValues({ ...values, error: "Error loading the customer details", loading: "" });
            toastifyMessage("error", 'Error loading the customer details');
          }); 
    }, []);

     // For Reset Password Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value})
    }

     const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loadingModal: true});

        // Field validations
        const requiredFields = [password, newPassword, cnfNewPassword ];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All fields are mandatory', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'All fields are mandatory');
            return;
        }

        if (requiredFields.some(field => (field.length<6))) {
            setValues({ ...values, error: 'Password should be atleast 6 characters', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'Password should be atleast 6 characters');
            return;
        }

        // Password validation
        if(newPassword !== cnfNewPassword) {
            setValues({ ...values, error: 'Passwords do not match', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'Passwords do not match');
            return;
          }

          // Password validation
        if(newPassword === password) {
            setValues({ ...values, error: 'New & existing password cannot be same', loading: "", loadingModal: ""});
            toastifyMessage("warning", 'New & existing password cannot be same');
            return;
          }

        // Reset password
        pChangePassword({password, newPassword}, partner._id, token)
        .then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", loadingModal: ""});
                toastifyMessage("error", (data.error?data.error:"Error resetting the password"));
            } else {
                setValues({
                    ...values,
                    password: "",
                    newPassword: "",
                    cnfNewPassword: "",
                    loading: "",
                    loadingModal: "",
                    error: ""
                })
                // To close the modal
                handleClose();

                toastifyMessage("success", "Password updated successfully");
                }
            }
        )
        .catch(err => {
            console.log('Error resetting the password:', err);
            setValues({ ...values, error: "Error resetting the password", loading: "", loadingModal: "" });
            toastifyMessage("error", 'Error resetting the password');
          });        
      };

    const ResetPasswordForm = () => (
        <>
            <form className="mt-2">
                <div className="form-group">
                    <label className="text-dark"> Old Password <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("password")}
                        type="password"
                        value={password}
                        name="password"
                        placeholder="Old Password"
                        autoFocus
                    />
                </div>
                <div className="form-group">
                    <label className="text-dark"> New Password <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("newPassword")}
                        type="password"
                        value={newPassword}
                        name="newPassword"
                        placeholder="New Password"
                        autoFocus
                    />
                </div>
                <div className="form-group">
                    <label className="text-dark"> Confirm Password <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("cnfNewPassword")}
                        type="text"
                        value={cnfNewPassword}
                        name="cnfNewPassword"
                        placeholder="Confirm Password"
                        autoFocus
                    />
                </div>
                <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                    Reset Password
                </button>
            </form>
            {LightLoaderScreen(loadingModal)}
        </>
    )
  
    const resetPasswordModal = () => (
      <Modal show={show} onHide={handleClose}
             size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      Reset Password
                  </Modal.Title>
              </Modal.Header>
              
              <Modal.Body>
                  {ResetPasswordForm()}
              </Modal.Body>
    
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                      Close
                  </Button>
              </Modal.Footer>
  
          </Modal>
    )

    const partnerLeftSide = () => {
        return (
            <div className="card bg-dark text-white">
                <h4 className="card-header">Customer Navigation</h4>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item bg-dark p-0">
                        <Link className="btn btn-dark w-100 text-left" to={`/partner/${partner._id}/view/invoice?partnerName=${partner.partnerName}`}>
                            View Invoice
                        </Link>
                    </li>
                </ul>
            </div>
        );
    };    

    const partnerRightSide = () => {
        return (
            <div className="card" style={{ backgroundColor: '#5cb85c', color: 'white' }}>
                <h4 className="card-header" style={{ backgroundColor: '#4cae4c' }}>Customer Info</h4>
                <ul className="list-group">
                    <li className="list-group-item" style={{ backgroundColor: '#5cb85c' }}>
                        <span className="badge bg-success mr-2">
                            Customer Name:
                        </span> {(partner && partner.partnerName) ? partner.partnerName : ""}
                    </li>
                    <li className="list-group-item" style={{ backgroundColor: '#5cb85c' }}>
                        <span className="badge bg-success mr-2">
                            Username:
                        </span> {partner && partner.username ? partner.username : (partnerData && partnerData.username ? partnerData.username : "")}
                    </li>
                    <li className="list-group-item" style={{ backgroundColor: '#5cb85c' }}>
                        <span 
                            className="badge bg-dark mr-2"
                            onClick={
                                () => {handleShow()} 
                            } 
                        >
                            Reset Password
                        </span>
                    </li>
                    <li className="list-group-item" style={{ backgroundColor: '#5cb85c' }}>
                        <span className="badge bg-danger mr-2">
                            Customer Privileges Granted Successfully
                        </span>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <Base 
            title="Customer Dashboard" 
            description="Manage your Profile" 
            className="container-fluid py-1 px-4" 
            style={{ backgroundColor: '#5cb85c' }}>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-3">
                    {partnerLeftSide()}
                </div>
                <div className="col-12 col-sm-6 col-md-9">
                    {partnerRightSide()}
                </div>
            </div>
            {resetPasswordModal()}
            {performRedirect()}
            <ToastifyMessage/> 
        </Base>
    );
}