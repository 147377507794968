import React, { Fragment } from 'react'
import { Link, withRouter } from "react-router-dom"
import { signout, isAuthenticated } from "../auth/helper/adminIndex"
import { msignout, pisAuthenticated } from "../auth/helper/partnerIndex"
import { dsignout, disAuthenticated } from "../auth/helper/driverIndex"
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'

const currentTab = (history, path) => {
    if (history.location.pathname === path) {
        return { color: "#2ecc72" }
    }
    else {
        return { color: "#FFFFFF" }
    }
};


const MyNavbar = ({ history }) => (

    <div className="border border-left-0 border-right-0 border-top-0 border-white">
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link>
                        <Link style={currentTab(history, "/")}
                            className="nav-link" to="/">
                            Home
                        </Link>
                    </Nav.Link>


                    {isAuthenticated() && isAuthenticated().admin &&
                        <Nav.Link>
                            <Link style={currentTab(history, "/admin/dashboard")}
                                className="nav-link" to="/admin/dashboard">
                                Admin Dashboard
                            </Link>
                        </Nav.Link>
                    }

                    {pisAuthenticated() && pisAuthenticated().partner &&
                        <Nav.Link>
                            <Link style={currentTab(history, "/partner/dashboard")}
                                className="nav-link" to="/partner/dashboard">
                                Customer Dashboard
                            </Link>
                        </Nav.Link>
                    }

                    {disAuthenticated() && disAuthenticated().driver &&
                        <Nav.Link>
                            <Link style={currentTab(history, "/driver/dashboard")}
                                className="nav-link" to="/driver/dashboard">
                                Driver Dashboard
                            </Link>
                        </Nav.Link>
                    }


                    {!isAuthenticated() && !pisAuthenticated() && !disAuthenticated() && (
                        <Fragment>
                            <NavDropdown title={<span className="text-white">Admin</span>} id="basic-nav-dropdown" className="nav-link">
                                <NavDropdown.Item>
                                    <Link style={currentTab(history, "/adminsignin")}
                                        className="nav-link text-dark" to="/adminsignin">
                                        Admin Signin
                                    </Link>
                                </NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title={<span className="text-white">Driver</span>} id="basic-nav-dropdown" className="nav-link">
                                <NavDropdown.Item>
                                    <Link style={currentTab(history, "/driverSignin")}
                                        className="nav-link text-dark" to="/driverSignin">
                                        Driver Signin
                                    </Link>
                                </NavDropdown.Item>
                            </NavDropdown>


                            <NavDropdown title={<span className="text-white">Customer</span>} id="basic-nav-dropdown" className="nav-link">
                                <NavDropdown.Item>
                                    <Link style={currentTab(history, "/partnerSignin")}
                                        className="nav-link text-dark" to="/partnerSignin">
                                        Customer Signin
                                    </Link>
                                </NavDropdown.Item>

                            </NavDropdown>
                        </Fragment>
                    )}

                    {(isAuthenticated().admin || pisAuthenticated().partner || disAuthenticated().driver) && (
                        <Nav.Link>
                            <span
                                className="nav-link text-warning"
                                onClick={() => {
                                    signout(() => {
                                        history.push("/")
                                    })
                                }}
                            >
                                Signout
                            </span>
                        </Nav.Link>
                    )}

                </Nav>

            </Navbar.Collapse>
        </Navbar>
    </div>
)
export default withRouter(MyNavbar);