import { API } from "../../backend";
import axios from 'axios';

// get a partner
export const getpartner = (partnerId, token) => {
    return fetch(`${API}/partner/${partnerId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {

            return response.json();
        })
        .catch(err => console.log(err));
};


// View all trip sheets
export const gettripsheets = ( partnerId, filterDate, token) => {
    return fetch(`${API}/partner/tripSheet/${partnerId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
};

  // Fetch image from S3
  export const fetchImageFromS3 = async (sheetid, path) => {
    const imageurl = sheetid && path ? `${API}/${path}/${sheetid}` : "";
    try {
        const response = await axios.get(imageurl);
        return response.data;
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};