// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-filter-form {
    justify-content: center;
    margin-bottom: 1.5rem;
}

.date-filter-container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    width: 50%;
}

.date-filter-form {
    display: flex;
    align-items: center;
}

.date-filter-row {
    display: flex;
}

.date-input {
    margin-right: 5rem;
    margin-left: 1rem;
}

.submit-btn {
    padding: 0.5rem 1rem;
}

.download-btn {
    margin-left: 70px;
}

.date-filter-container {
    justify-content: center;
}

@media (max-width: 838px) {
    .trip-dashboard {
        align-items: center;
    }

    .date-filter-container {
        width: 100%;
        justify-content: center;
    }

    .date-filter-row {
        flex-wrap: wrap;
    }

    .date-input,
    .submit-btn {
        margin: 0.5rem;
    }

    .submit-btn {
        width: calc(100% - 2 * 0.5rem);
    }

    .download-btn {
        margin: 0.5rem;
        width: calc(100% - 2 * 0.5rem);
    }
}`, "",{"version":3,"sources":["webpack://./src/main/driver/DriverSideSheet.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,WAAW;QACX,uBAAuB;IAC3B;;IAEA;QACI,eAAe;IACnB;;IAEA;;QAEI,cAAc;IAClB;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,cAAc;QACd,8BAA8B;IAClC;AACJ","sourcesContent":[".date-filter-form {\n    justify-content: center;\n    margin-bottom: 1.5rem;\n}\n\n.date-filter-container {\n    margin-bottom: 2rem;\n    display: flex;\n    justify-content: center;\n    width: 50%;\n}\n\n.date-filter-form {\n    display: flex;\n    align-items: center;\n}\n\n.date-filter-row {\n    display: flex;\n}\n\n.date-input {\n    margin-right: 5rem;\n    margin-left: 1rem;\n}\n\n.submit-btn {\n    padding: 0.5rem 1rem;\n}\n\n.download-btn {\n    margin-left: 70px;\n}\n\n.date-filter-container {\n    justify-content: center;\n}\n\n@media (max-width: 838px) {\n    .trip-dashboard {\n        align-items: center;\n    }\n\n    .date-filter-container {\n        width: 100%;\n        justify-content: center;\n    }\n\n    .date-filter-row {\n        flex-wrap: wrap;\n    }\n\n    .date-input,\n    .submit-btn {\n        margin: 0.5rem;\n    }\n\n    .submit-btn {\n        width: calc(100% - 2 * 0.5rem);\n    }\n\n    .download-btn {\n        margin: 0.5rem;\n        width: calc(100% - 2 * 0.5rem);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
