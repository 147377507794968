import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { gettripsheetsfordriver, deletetripsheet, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import TripSheetTableView from '../../core/helper/TripSheetTableView';
import PreparingDownload from '../../core/helper/PreparingDownload';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../AdminSideSheet.css';
import { getTruckNo_2, getTrailerNo_2, getPickupCompany_2, getDeliveryLocation, getPickupLocation, getPickupCommodity, getDate } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";

export default function ViewDriverTripSheets() {

    const { admin, token } = isAuthenticated();
    const { driverId } = useParams();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    // For date filters : end date is the current system date
    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        tripSheets: [],
        firstName: "",
        lastName: ""
    });

    const { startDate, endDate, loading, loadingModal, error, isLoaded, tripSheets, firstName, lastName } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" })
        gettripsheetsfordriver(admin._id, driverId, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data, loading: "", isLoaded: "" })
                toastifyMessage("error", "Error fetching the trip sheets");
            } else {
                const updatedValues = {
                    ...values,
                    tripSheets: data,
                    loading: "",
                    isLoaded: true
                };
                if (data[0] && data[0].driverNo[0]) {
                    updatedValues.firstName = data[0].driverNo[0].firstName;
                    updatedValues.lastName = data[0].driverNo[0].lastName;
                }
                setValues(updatedValues);
                if(data.length===0){
                    toastifyMessage("warning", "No trip sheet found");
                    }
            }
        })
        .catch(err => {
            console.log('Error loading the trip sheets:', err);
            setValues({ ...values, error: "Error loading the trip sheets", loading: "" });
            toastifyMessage("error", 'Error loading the trip sheets');
        });
    }

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Trip Sheet`, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`Driver: ${firstName} ${lastName}`, 10, 30);
        doc.text(`Report Duration: ${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 10, 40);

        const tableColumns = ["Sr. No.", "Pickup Date", "Pickup Scale In", "Pickup Scale Out", "Customer", "Pickup Location", "Ticket #", "Inbound Weight", "Delivery Date", "Delivery Scale In", "Delivery Scale Out", "Drop Location", "Ticket #", "Outbound Weight", "Deductions", "Commodity","Truck", "Trailer", "Status"];

        const tableRows = tripSheets.map((tripSheet, index) => {
            const row = [
                index + 1,
                tripSheet.date ? getDate(tripSheet.date) : "",
                tripSheet.pickUpTimeIn || "",
                tripSheet.pickUpTimeOut || "",
                getPickupCompany_2(tripSheet, true) || "",
                getPickupLocation(tripSheet) || "",
                tripSheet.ticketNumberAtShipping || "",
                (tripSheet.inBoundWeight || tripSheet.inBoundWeight===0) ? tripSheet.inBoundWeight : "",
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.receivingDate ? getDate(tripSheet.receivingDate) : ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.deliveryTimeIn || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.deliveryTimeOut || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (getDeliveryLocation(tripSheet) || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.ticketNumberAtReceiving || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : ((tripSheet.outBoundWeight || tripSheet.outBoundWeight===0) ? tripSheet.outBoundWeight : ""),
                (tripSheet.deductions || tripSheet.deductions===0) ? tripSheet.deductions : "",
                getPickupCommodity(tripSheet, true) || "",
                getTruckNo_2(tripSheet, true) || "",
                getTrailerNo_2(tripSheet, true) || "",
                tripSheet.isDeliveryIncomplete ?
                    "Rejected" :  
                    (
                        (tripSheet.typeOfLoad && tripSheet.typeOfLoad === 311) ? "No Load" :
                        (tripSheet.typeOfLoad && tripSheet.typeOfLoad === 321) ? "Yard Work" :
                        "Completed"
                    )
            ];
            return row;
        });

        doc.autoTable({
            startY: 50,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        // Add the footer strip
        let finalY = doc.previousAutoTable.finalY + 10;

        const stripY = finalY + 50;
        const stripHeight = 5;

        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }
        
        const filename = (firstName && lastName) ? ('trip_sheets_' + (firstName?firstName:"") + (lastName?lastName:"") + '.pdf') : "trip_sheets.pdf";
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        // Loading animation
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();
        const doc = new jsPDF('landscape');
        
        let startY = 20;
        let lastPageHeight = 0;
        let hasImage = false;
        let countOfImages = 0;
    
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Driver: ${firstName} ${lastName}`, doc.internal.pageSize.getWidth() / 2, 15, { align: 'center' });

        for (const [index, tripSheet] of tripSheets.entries()) {
            const imagesData = [];
            let imagesCount = 0;

            if(isS3IntegrationEnabled){
                    // Check for tripPhoto1
                if (tripSheet && tripSheet.photo1Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto1');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for tripPhoto2
                if (tripSheet && tripSheet.photo2Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto2');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }

                // Check for tripPhoto3
                if (tripSheet && tripSheet.photo3Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto3');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for tripPhoto4
                if (tripSheet && tripSheet.photo4Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto4');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }

                // Check for tripPhoto5
                if (tripSheet && tripSheet.photo5Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto5');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for tripPhoto6
                if (tripSheet && tripSheet.photo6Name) {
                    const image = await fetchImageFromS3(tripSheet._id, 'tripPhoto6');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            }

            if(isMongoDBImageReadEnabled){
                // Check for tripPhoto1
                if (tripSheet.photo1 && tripSheet.photo1.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo1.contentType};base64,${tripSheet.photo1.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for tripPhoto2
                if (tripSheet.photo2 && tripSheet.photo2.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo2.contentType};base64,${tripSheet.photo2.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }

                // Check for tripPhoto3
                if (tripSheet.photo3 && tripSheet.photo3.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo3.contentType};base64,${tripSheet.photo3.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for tripPhoto4
                if (tripSheet.photo4 && tripSheet.photo4.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo4.contentType};base64,${tripSheet.photo4.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }

                // Check for tripPhoto5
                if (tripSheet.photo5 && tripSheet.photo5.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo5.contentType};base64,${tripSheet.photo5.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for tripPhoto6
                if (tripSheet.photo6 && tripSheet.photo6.data) {
                    imagesCount += 1;
                    const imgData = `data:${tripSheet.photo6.contentType};base64,${tripSheet.photo6.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            }
    
            if (imagesData.length > 0) {
                hasImage = true;
                countOfImages += imagesData.length;
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Trip Sheet ${index + 1}`, 10, startY);
                startY += 10; 
    
                imagesData.forEach((imageData, imgIndex) => {
                    const imgProps = doc.getImageProperties(isS3IntegrationEnabled ? imageData.url : imageData.data);
                    const imgWidth = 150;
                    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
                    doc.text(imageData.label, 10, startY);
                    doc.addImage((isS3IntegrationEnabled ? imageData.url : imageData.data), 'JPEG', 10, startY + 10, imgWidth, imgHeight);
    
                    startY += imgHeight + 20; 
    
                    if (startY + imgHeight > doc.internal.pageSize.getHeight() - 20) {
                        doc.addPage();
                        startY = 20;
                    }
                });
    
                startY += 20;
                if(startY === 40){
                    startY = 20;
                }
                lastPageHeight = startY;
            }
        };
    
        const pageHeight = doc.internal.pageSize.getHeight();
        const lastPageNumber = doc.internal.getNumberOfPages();
        if ((lastPageHeight < pageHeight - 20) && (lastPageNumber > countOfImages)) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }

         // Close the loading animation before saving the pdf
         hideLoadingModal();
         setValues({ ...values, loadingModal: false });
         
        if(!hasImage){
            toastifyMessage("warning", "No images exist for the trip sheets");
            return;
        }
    
        const filename = (firstName && lastName) ? ('trip_sheet_photos_' + (firstName?firstName:"") + (lastName?lastName:"") + '.pdf') : "trip_sheet_photos.pdf";
        doc.save(filename);
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Sheet
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the trip sheet?");
    const [deleteTripSheetId, setDeleteTripSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Trip Sheet using the Admin privilege
    const deleteThisTripSheet = (tripSheetId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletetripsheet(tripSheetId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, loadingModal: "" });
                    toastifyMessage("error", 'Error deleting the trip sheet');
                } else {
                    toastifyMessage("success", "Trip Sheet deleted successfully");
         
                    // To close the modal & reload the values
                    handleCancelDeletion();
                    setValues({ ...values, error: "", loadingModal: "", tripSheets: (tripSheets.filter(sheet => (sheet._id !== tripSheetId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the trip sheet:', err);
                setValues({ ...values, error: "Error deleting the trip sheet", loadingModal: "" });
                toastifyMessage("error", 'Error deleting the trip sheet');
            });
    };

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Trip Sheets Dashboard" description="View/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="trip-dashboard">
                <div className="trip-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}> {(isLoaded && tripSheets[0] && tripSheets[0].driverNo) ? "Driver - " + tripSheets[0].driverNo[0].firstName + " " + tripSheets[0].driverNo[0].lastName : "Trip Sheet"} </h2>
                    {dateFilterForm()}
                </div>

                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the trip sheets</h4>}
                    {isLoaded && !error && tripSheets.length > 0 && (
                        <div id="trip-sheet-table">
                            <TripSheetTableView tripSheets={tripSheets} editURL={`/admin/update/driver/tripsheet/`} isAggregated={true} isAdmin={true} isDriver={false} setDeleteTripSheetId={setDeleteTripSheetId} handleShowDeleteMessage={handleShowDeleteMessage} />
                        </div>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTripSheet} id={deleteTripSheetId} message={deleteMessage} loadingModal={loadingModal}/>
            <ToastifyMessage />
        </Base>
    )
}
