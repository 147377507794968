import React from 'react';
import MyNavbar from "./Navbar";
import { FiMail } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import logo from "../images/ttms_logo_1.png";

const Base = ({
    title = "My Title",
    description = "My description",
    className = "bg-dark text-white pt-4",
    imageURL = logo,
    isHomePage = false,
    children
}) => {
    return (
        <div>
            <MyNavbar />
                <div className="container-fluid">
                  <div className="jumbotron bg-dark text-white text-center">
                    {isHomePage && (
                      <div className="row justify-content-center">
                        <div className="col-6 col-md-4">
                          <img
                            className="img-fluid mb-4"
                            src={imageURL}
                            alt="Triple Trans Mill Services Ltd."
                            title="Triple Trans Mill Services Ltd."
                          />
                        </div>
                      </div>
                    )}

                    {!isHomePage && (
                      <h2 className="display-4" style={{ fontFamily: 'Englebert' }}>
                        {title}
                      </h2>
                    )}

                    <h4 className="text-info" style={{ fontFamily: 'Englebert' }}>
                      {description}
                    </h4>
                  </div>
                  <div className={className}>{children}</div>
                </div>

                <br />
                <footer className="footer bg-dark mt-auto py-3" style={{ fontFamily: 'Helvetica' }}>
                    <div className="container-fluid bg-success text-white text-center py-2">
                        <h4>If you got any questions, feel free to reach out!</h4>
                        <a href="mailto:dispatch@tripletrans.ca">
                            <button className="btn btn-info btn-lg rounded">
                                <FiMail />
                                &nbsp; Contact Us
                            </button>
                        </a>
                    </div>
                    <div className="container text-center mt-2">
                        <span className="text-muted">Developed with <FaHeart color="red"/> by <span className="text-white">Developreneurs Tech Limited</span> </span>
                    </div>
                </footer>
        </div>
    )
}

export default Base;