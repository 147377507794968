import React from 'react'
import { Modal, Button } from "react-bootstrap";
import LightLoaderScreen from './LightLoaderScreen';
 
export default function PreparingDownload({ showModal, hideModal, isLoading=false }) {
    return (
        <Modal show={showModal} onHide={hideModal}>
        {LightLoaderScreen(isLoading)}
      </Modal>
    )
}
