import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import Home from "./core/Home"
import AdminRoute from "./auth/helper/AdminRoute"
import PartnerRoute from "./auth/helper/PartnerRoute"
import DriverRoute from "./auth/helper/DriverRoute"

// Sign in Routes
import AdminSignin from "./admin/AdminSignin"
import DriverSignin from "./driver/DriverSignin"
import PartnerSignin from "./partner/PartnerSignin"

// Dashboard Routes
import AdminDashboard from "./admin/AdminDashBoard"
import DriverDashboard from "./driver/DriverDashBoard"
import PartnerDashboard from "./partner/PartnerDashBoard"

// Admin Insert Routes
import AddTruck from "./admin/paths/AddTruck"
import AddTrailer from "./admin/paths/AddTrailer"
import AddCard from "./admin/paths/AddCard"
import AddCommodity from "./admin/paths/AddCommodity"
import AddLocation from "./admin/paths/AddLocation"
import AddDriver from "./admin/paths/AddDriver"
import AddPartner from "./admin/paths/AddPartner"
import AddAdmin from "./admin/paths/AddAdmin"
import AddLocationMetrics from "./admin/paths/AddLocationMetrics"
import AddLogSheetAdmin from './admin/paths/AddLogSheetAdmin'
import AddFuelSheetAdmin from './admin/paths/AddFuelSheetAdmin'
import AddTripSheetAdmin from './admin/paths/AddTripSheetAdmin'

// Admin Manage Routes
import ManageTrucks from "./admin/paths/ManageTrucks"
import ManageTrailers from "./admin/paths/ManageTrailers"
import ManageCards from "./admin/paths/ManageCards"
import ManageCommodities from "./admin/paths/ManageCommodities"
import ManageLocations from "./admin/paths/ManageLocations"
import ManageDrivers from "./admin/paths/ManageDrivers"
import ManagePartners from "./admin/paths/ManagePartners"
import ManageAdmins from "./admin/paths/ManageAdmins"

// Admin Update Routes
import UpdateTruck from "./admin/paths/UpdateTruck"
import UpdateTrailer from "./admin/paths/UpdateTrailer"
import UpdateCommodity from "./admin/paths/UpdateCommodity"
import UpdateLocation from "./admin/paths/UpdateLocation"
import UpdateCard from "./admin/paths/UpdateCard"
import UpdatePartner from "./admin/paths/UpdatePartner"
import UpdateDriver from "./admin/paths/UpdateDriver"
import UpdateAdmin from "./admin/paths/UpdateAdmin"
import ViewDriverFuelLogSheets from './admin/paths/ViewDriverFuelLogSheets'
import EditFuelLogSheet from './admin/paths/EditFuelLogSheet'
import ViewDriverLogSheets from './admin/paths/ViewDriverLogSheets'
import EditLogSheet from './admin/paths/EditLogSheet'
import ViewDriverTripSheets from './admin/paths/ViewDriverTripSheets'
import EditTripSheet from './admin/paths/EditTripSheet'
import ViewClientInvoice from './admin/paths/ViewClientInvoice'
import EditTripSheetForPartner from './admin/paths/EditTripSheetForPartner'
import ViewLoads from './admin/paths/ViewLoads'
import ViewFuelSheets from './admin/paths/ViewFuelSheets'
import ViewTripCards from './admin/paths/ViewTripCards'
import ViewUnverifiedTimeCards from './admin/paths/ViewUnverifiedTimeCards'
import ManageTripRates from './admin/paths/ManageTripRates'

// Util routes
import ViewAuditLogs from './admin/paths/ViewAuditLogs'
import UpdateNightOutRate from './admin/paths/UpdateNightOutRate'

// Driver Routes
import MainLog from "./driver/paths/MainLog"
import AddLogSheet from "./driver/paths/AddLogSheet"
import ManageLogSheets from "./driver/paths/ManageLogSheets"
import UpdateLogSheet from './driver/paths/UpdateLogSheet'
import AddFuelLogSheet from './driver/paths/AddFuelLogSheet'
import ManageFuelLogSheets from './driver/paths/ManageFuelLogSheets'
import UpdateFuelLogSheet from './driver/paths/UpdateFuelLogSheet'
import MainTrip from "./driver/paths/MainTrip"
import AddPickTripSheet from "./driver/paths/AddPickTripSheet"
import AddDropTripSheet from "./driver/paths/AddDropTripSheet"
import AddNoLoadSheet from "./driver/paths/AddNoLoadSheet"
import AddYardDropSheet from "./driver/paths/AddYardDropSheet"
import ManageTripSheets from "./driver/paths/ManageTripSheet"
import UpdateTripSheet from './driver/paths/UpdateTripSheet'
import DriverReset from './driver/DriverReset';

// Partner Routes
import PartnerReset from './partner/PartnerReset';
import ViewInvoice from './partner/path/ViewInvoice';


export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/adminsignin" component={AdminSignin} />
                <Route path="/driverSignin" component={DriverSignin} />
                <Route path="/driver/resetpassword" component={DriverReset} />
                <Route path="/partnerSignin" component={PartnerSignin} />
                <Route path="/partner/resetpassword" component={PartnerReset} />

                <AdminRoute path="/admin/dashboard" component={AdminDashboard} />
                <AdminRoute path="/admin/create/truck" component={AddTruck} />
                <AdminRoute path="/admin/trucks" component={ManageTrucks} />
                <AdminRoute exact path="/admin/update/truck/:truckId" component={UpdateTruck} />
                <AdminRoute path="/admin/create/trailer" component={AddTrailer} />
                <AdminRoute path="/admin/trailers" component={ManageTrailers} />
                <AdminRoute exact path="/admin/update/trailer/:trailerId" component={UpdateTrailer} />
                <AdminRoute path="/admin/create/card" component={AddCard} />
                <AdminRoute path="/admin/cards" component={ManageCards} />
                <AdminRoute exact path="/admin/update/card/:cardId" component={UpdateCard} />
                <AdminRoute path="/admin/create/commodity" component={AddCommodity} />
                <AdminRoute path="/admin/commodities" component={ManageCommodities} />
                <AdminRoute exact path="/admin/update/commodity/:commodityId" component={UpdateCommodity} />
                <AdminRoute path="/admin/create/location" component={AddLocation} />
                <AdminRoute path="/admin/locations" component={ManageLocations} />
                <AdminRoute exact path="/admin/update/location/:locationId" component={UpdateLocation} />
                <AdminRoute path="/admin/create/partner" component={AddPartner} />
                <AdminRoute path="/admin/partners" component={ManagePartners} />
                <AdminRoute exact path="/admin/update/partner/:partnerId" component={UpdatePartner} />
                <AdminRoute path="/admin/create/driver" component={AddDriver} />
                <AdminRoute path="/admin/drivers" component={ManageDrivers} />
                <AdminRoute path="/admin/create/admin" component={AddAdmin} />
                <AdminRoute path="/admin/admins" component={ManageAdmins} />
                <AdminRoute exact path="/admin/update/admin/:adminId" component={UpdateAdmin} />
                <AdminRoute exact path="/admin/update/driver/:driverId" component={UpdateDriver} />
                <AdminRoute exact path="/admin/driver/:driverId/view/fuelLogSheets" component={ViewDriverFuelLogSheets} />
                <AdminRoute exact path="/admin/update/driver/fuellogsheet/:fuellogsheetId" component={EditFuelLogSheet} />
                <AdminRoute exact path="/admin/create/fuelsheet" component={AddFuelSheetAdmin} />
                <AdminRoute exact path="/admin/driver/:driverId/view/logSheets" component={ViewDriverLogSheets} />
                <AdminRoute exact path="/admin/update/driver/logsheet/:logsheetId" component={EditLogSheet} />
                <AdminRoute exact path="/admin/create/timecard" component={AddLogSheetAdmin} />
                <AdminRoute exact path="/admin/driver/:driverId/view/tripSheets" component={ViewDriverTripSheets} />
                <AdminRoute exact path="/admin/update/driver/tripsheet/:tripsheetId" component={EditTripSheet} />
                <AdminRoute exact path="/admin/create/tripsheet" component={AddTripSheetAdmin} />
                <AdminRoute exact path="/admin/customer/:partnerId/view/invoice" component={ViewClientInvoice} />
                <AdminRoute exact path="/admin/customer/:partnerId/update/rates" component={AddLocationMetrics} />
                <AdminRoute exact path="/admin/customer/:partnerId/view/triprates" component={ManageTripRates} />
                <AdminRoute exact path="/admin/update/partner/tripsheet/:tripsheetId" component={EditTripSheetForPartner} />
                <AdminRoute exact path="/admin/view/loads" component={ViewLoads} />
                <AdminRoute exact path="/admin/view/timecards" component={ViewTripCards} />
                <AdminRoute exact path="/admin/view/unverified/timecards" component={ViewUnverifiedTimeCards} />
                <AdminRoute exact path="/admin/view/fuelsheets" component={ViewFuelSheets} />

                <AdminRoute exact path="/admin/view/audit" component={ViewAuditLogs} />
                <AdminRoute exact path="/admin/update/nightoutrate" component={UpdateNightOutRate} />

                <DriverRoute path="/driver/dashboard" component={DriverDashboard} />
                <DriverRoute path="/driver/main/logsheet" component={MainLog} />
                <DriverRoute path="/driver/main/tripsheet" component={MainTrip} />
                <DriverRoute path="/driver/create/logsheet" component={AddLogSheet} />
                <DriverRoute path="/driver/create/fuellogsheet" component={AddFuelLogSheet} />
                <DriverRoute path="/driver/create/tripsheet/pickup" component={AddPickTripSheet} />
                <DriverRoute path="/driver/create/tripsheet/noload" component={AddNoLoadSheet} />
                <DriverRoute path="/driver/create/tripsheet/yardwork" component={AddYardDropSheet} />
                <DriverRoute path="/driver/create/tripsheet/dropoff/:tripsheetId" component={AddDropTripSheet} />
                <DriverRoute path="/driver/logsheet" component={ManageLogSheets} />
                <DriverRoute path="/driver/fuellogsheet" component={ManageFuelLogSheets} />
                <DriverRoute path="/driver/tripsheet" component={ManageTripSheets} />
                <DriverRoute exact path="/driver/update/logsheet/:logsheetId" component={UpdateLogSheet} />
                <DriverRoute exact path="/driver/update/fuellogsheet/:fuellogsheetId" component={UpdateFuelLogSheet} />
                <DriverRoute exact path="/driver/update/tripsheet/:tripsheetId" component={UpdateTripSheet} />


                <PartnerRoute path="/partner/dashboard" component={PartnerDashboard} />
                <AdminRoute exact path="/partner/:partnerId/view/invoice" component={ViewInvoice} />

                <Route render={() => <Redirect to={{ pathname: "/" }} />} />

            </Switch>
        </Router>
    );
}

