import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getadmins, deleteadmin } from '../helper/adminapicall';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function ManageAdmins() {
    const { admin, token } = isAuthenticated();
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState("");
    const [error, setError] = useState('');

    // Admin Search
    const [adminSearch, setAdminSearch] = useState("");
    const [filteredAdmins, setFilteredAdmins] = useState([]);

    useEffect(() => {
        preload();
    }, []);

    const preload = () => {
        setLoading(true);
        setError('');
        getadmins(admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error fetching the admins. Please try again later.');
                } else {
                    setAdmins(data);
                    setFilteredAdmins(data);
                    if(data.length===0){
                        toastifyMessage("warning", 'No Admins found');
                        }
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching the admins:', err);
                setError('Error fetching the admins');
                setLoading(false);
                toastifyMessage("error", 'Error fetching the admins');
            });
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to offboard the Admin?");
    const [deleteAdminId, setDeleteAdminId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false)
    const handleShowDeleteMessage = () => setShowDeleteMessage(true)

    const deleteThisAdmin = (deletedAdminId) => {
        if(deleteAdminId === (admin && admin._id ? admin._id : "")){
            handleCancelDeletion();
            toastifyMessage("warning", 'Error deleting the current admin');
            return;
        }
        setLoadingModal(true);
        deleteadmin(deletedAdminId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error deleting the admin');
                } else {
                    toastifyMessage("success", 'Admin offboarded successfully');
                    
                    // To close the modal
                    handleCancelDeletion();
                    setLoadingModal("");
                
                    // Reload the values
                    setAdmins(admins.filter(admin => (admin._id !== deletedAdminId)));
                    setFilteredAdmins(admins.filter(admin => (admin._id !== deletedAdminId)));
                }
            })
            .catch(err => {
                console.log('Error deleting the admin:', err);
                setError('Error deleting the admin');
                setLoading(false);
                toastifyMessage("error", 'Error deleting the admin');
            });
    };

    // For View Admin Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [adminData, setAdminData] = useState(null);

    const viewAdminModal = () => (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Admin Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Admin Name:</b> {adminData && adminData.adminName}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            <b>Username:</b> {adminData && adminData.username}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const handleAdminSearch = (event) => {
        setAdminSearch(event.target.value);
        const searchTerm = (event.target.value).toLowerCase();
        if(searchTerm==="" || !searchTerm){
            setFilteredAdmins(admins);
            return;
        }
        const filtered = admins.filter(a =>
            (a.adminName).toLowerCase().includes(searchTerm)
        );
        setFilteredAdmins(filtered);
    };

    return (
        <Base title="Admin Dashboard" description="Manage/Edit/Delete admins">
            <Link className="btn btn-info rounded mb-3" to="/admin/dashboard">
                Admin Home
            </Link>
            <div className="container">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>
                    Admins
                </h2>
                {loading ? (
                    <LoadingScreen show={loading} />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for an admin"
                                value={adminSearch}
                                onChange={handleAdminSearch}
                            />
                        </div>
                        <div>
                            {filteredAdmins.map((a, index) => (
                                <div key={index} className="card text-center mb-3">
                                    <div className="card-body">
                                        <h3 className="card-title text-left" style={{ fontFamily: 'Englebert', color: 'black' }}>
                                            {a.adminName}
                                        </h3>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => {
                                                        setAdminData(a);
                                                        handleShow();
                                                    }}
                                                    className="btn btn-info rounded mb-2"
                                                >
                                                    View
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-success rounded mb-2"
                                                    to={`/admin/update/admin/${a._id}`}
                                                >
                                                    Update
                                                </Link>
                                            </div>
                                            { admin && admin._id && (admin._id !== a._id) &&
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeleteAdminId(a._id)
                                                            handleShowDeleteMessage()
                                                        }    
                                                    }
                                                    className="btn btn-danger rounded mb-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {viewAdminModal()}
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisAdmin} id={deleteAdminId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/> 
        </Base>
    );
}
