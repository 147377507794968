import React, { useState } from "react"
import Base from "../core/Base"
import { Link, Redirect } from "react-router-dom"
import { dsignin, dauthenticate, disAuthenticated } from "../auth/helper/driverIndex"
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import LoadingScreen from '../core/helper/LoadingScreen';
import ToastifyMessage from '../core/helper/ToastifyMessage';
import {toastifyMessage} from '../core/helper/Toast';

const DriverSignin = () => {

    const [values, setValues] = useState({
        username: "",
        password: "",
        showPassword: false,
        error: "",
        loading: false,
        didRedirect: false
    });

    const { username, password, showPassword, error, loading, didRedirect } = values
    const { driver } = disAuthenticated();


    // Password for handling the state change
    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value })
    }

    // Method for showing or hidng the password
    const handleShowHidePassword = () => {
        setValues({ ...values, showPassword: !showPassword })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = event => {
        event.preventDefault()
        setValues({ ...values, error: false, loading: true })

        // Field validations
        const requiredFields = [username, password];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'Username & Password are mandatory', loading: false});
               toastifyMessage("warning", "Username & Password are mandatory");
            return;
        }

        dsignin({ username, password })
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false })
                    toastifyMessage("error", "Login failed");
                } else {
                    dauthenticate(data, () => {
                        setValues({
                            ...values,
                            didRedirect: true
                        })
                    })
                }
            })
            .catch(err => {
                console.log('Error logging in as driver:', err);
                setValues({ ...values, error: "Error logging in as driver", loading: "" });
                toastifyMessage("error", 'Error logging in as driver');
              });  
    };

    const onReset = event => {
        event.preventDefault()
        setValues({
            ...values,
            username: "",
            password: "",
            error: "",
            success: false
        });
    };

    const performRedirect = () => {
        if (didRedirect) {
            if (driver) {
                return (
                    <Redirect to="/driver/dashboard" />
                )
            }

        }
        if (disAuthenticated) {
            return <redirect to="/" />
        }
    };

    const signIpForm = () => {
        return (
            <div className="row">
                <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1 text-left">
                    <form>
                        <div className="form-group">
                            <label className="text-light"> Username <span className="text-warning">*</span> </label>
                            <Input className="form-control"
                                disableUnderline={true}
                                type="text"
                                value={username} onChange={handleChange("username")}
                                placeholder="Password"
                            />
                        </div>
                        <div className="form-group">
                            <label className="text-light"> Password <span className="text-warning">*</span> </label>
                            <Input className="form-control"
                                disableUnderline={true}
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                onChange={handleChange("password")}
                                value={password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleShowHidePassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? (<Visibility />) : (<VisibilityOff />)}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <button className="btn btn-success btn-block mt-3 rounded" mt-3 onClick={onSubmit}>Submit</button>
                        <button className="btn btn-info btn-block rounded" onClick={onReset}>Reset </button>
                    </form>
                </div>
            </div>
        );
    };

    const loadingMessage = () => (
        loading && (
            <div className="alert alert-info">
                <h2>Loading Driver Dashboard...</h2>
            </div>
        )
    )

    const errorMessage = () => (
        <div className="row">
            <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1 text-center">
                <div className="alert alert-danger"
                    style={{ display: error ? "" : "none" }}
                >
                    {error}
                </div>
            </div>
        </div>
    )

    const forgotPassword = () => (
        <div className="row">
            <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1 text-center">
                <Link to="/driver/resetpassword">
                    <div className="alert alert-danger">
                        Forgot Password ?
                    </div>
                </Link>
            </div>
        </div>
    )

    return (
        <Base title="Driver Sign In" description="Login as a driver">
            {signIpForm()}
            <br />
            {performRedirect()}
            {forgotPassword()}
            {LoadingScreen(loading)}
            {errorMessage()}
            <br/>
            <ToastifyMessage/> 
        </Base>
    );
}

export default DriverSignin;