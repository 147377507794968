import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getallunverifiedtripcards, deletelogsheet, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LogSheetTableView from '../../core/helper/LogSheetTableView';
import PreparingDownload from '../../core/helper/PreparingDownload';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../AdminSideSheet.css';
import { getTruckNo, getTrailerNo, getDate, getDayOfTheWeek, getBillableTotalHours, getLoadsSubTotal, getSheetDriverName } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";

export default function ViewUnverifiedTimeCards() {
    const { admin, token } = isAuthenticated();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    const [values, setValues] = useState({
        startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        logSheets: [],
        isReimbursementRequired: false
    });

    const { startDate, endDate, loading, loadingModal, error, isLoaded, logSheets, isReimbursementRequired } = values;
    let reimbursementCounter = false;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getallunverifiedtripcards(admin._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
                toastifyMessage("error", 'Error fetching the time cards');
            } else {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].reimbursementLocation && data[i].reimbursementDate) {
                        reimbursementCounter = true;
                        break;
                    }
                }
                const updatedValues = {
                    ...values,
                    logSheets: data,
                    loading: "",
                    isLoaded: true,
                    isReimbursementRequired: reimbursementCounter
                };
                setValues(updatedValues);
                if(data.length===0){
                    toastifyMessage("warning", 'No time card found');
                 }
            }
        })
        .catch(err => {
            console.log('Error loading the time cards:', err);
            setValues({ ...values, error: "Error loading the time cards", loading: "" });
            toastifyMessage("error", 'Error loading the time cards');
        });
    };

    const flipSheetStatus = (id, status) => {
        // Update the logSheets array in the state
        setValues(prevValues => ({
           ...prevValues,
           logSheets: prevValues.logSheets.map(
               sheet => sheet._id === id ? { ...sheet, isTripCardVerified: status } : sheet
           )
       }));
   }

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const systemDate = new Date();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the table
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Time Card`, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`Date: ${moment(systemDate).format('MMMM D, YYYY')}`, 10, 30);
        doc.text(`Report Duration: ${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 10, 35);

        // Define table columns based on LogSheetTableView component
        const tableColumns = [
            'Sr. No.', 'Date', 'Day', 'Driver', 'Truck', 'Trailer', 'Start City', 'Finish City', 'Start Odometer', 'End Odometer', 'Down time', 'Billable Start Time', 'Billable End Time', 'Deduction (in hour)', 'Notes', 'Billable Total Hours', 'Rate (in $)', '# of Loads Delivered', 'Night Out', 'Night out Subtotal', 'Grand Subtotal', 'Reimbursement'
        ];
        if (isReimbursementRequired) {
            tableColumns.push('Reimbursement Date', 'Reimbursement Purpose', 'Reimbursement Location', 'Reimbursement Amount');
        }

        // Define table rows based on logSheets data
        const tableRows = logSheets.map((logSheet, index) => {
            const row = [
                index + 1,
                logSheet.date ? getDate(logSheet.date) : "",
                getDayOfTheWeek(logSheet),
                getSheetDriverName(logSheet, true) || "",
                getTruckNo(logSheet, true),
                getTrailerNo(logSheet, true),
                logSheet.pickUpCity || "",
                logSheet.deliveryCity || "",
                logSheet.startOdometer || "",
                logSheet.endOdometer || "",
                (logSheet.downTime || logSheet.downTime === 0) ? logSheet.downTime : "",
                logSheet.timeIn || "",
                logSheet.timeOut || "",
                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                logSheet.deductionNotes || "-",
                getBillableTotalHours(logSheet),
                logSheet.ratePerHour ? ('$' + logSheet.ratePerHour.toFixed(2)) : "-",
                logSheet.numberOfLoads || "-",
                logSheet.nightOut ? (logSheet.nightOutPurpose ? ('Yes\n' + logSheet.nightOutPurpose) : 'Yes'): 'No',
                (logSheet.nightOut && logSheet.nightOutRate) ? ('$' + logSheet.nightOutRate.toFixed(2)) : "-",
                getLoadsSubTotal(logSheet, true),
                logSheet.reimbursement ? 'Yes' : 'No'
            ];
            if (isReimbursementRequired) {
                row.push(
                    logSheet.reimbursement && logSheet.reimbursementDate ? getDate(logSheet.reimbursementDate) : "-",
                    logSheet.reimbursement && logSheet.reimbursementPurpose ? logSheet.reimbursementPurpose : "-",
                    logSheet.reimbursement && logSheet.reimbursementLocation ? logSheet.reimbursementLocation : "-",
                    logSheet.reimbursement && (logSheet.reimbursementAmount || logSheet.reimbursementAmount === 0) ? logSheet.reimbursementAmount : "-"
                );
            }

            return row;
        });

        doc.autoTable({
            startY: 45,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        // Add the footer strip
        let finalY = doc.previousAutoTable.finalY + 10;

        const stripY = finalY + 50;
        const stripHeight = 5;

        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }

        const filename = "Time_card_sheet.pdf";
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        // Loading animation
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();

        const doc = new jsPDF('landscape');
        let startY = 20;
        let lastPageHeight = 0;
        let hasImage = false;
        let countOfImages = 0;
    
         for (const [index, logSheet] of logSheets.entries()) {
            const imagesData = [];
            let imagesCount = 0;
    
            if(isS3IntegrationEnabled){
                // Check for logPhoto1
                if (logSheet && logSheet.photo1Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto1');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for logPhoto2
                if (logSheet && logSheet.photo2Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto2');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }

                // Check for logPhoto3
                if (logSheet && logSheet.photo3Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto3');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            
                // Check for logPhoto4
                if (logSheet && logSheet.photo4Name) {
                    const image = await fetchImageFromS3(logSheet._id, 'logPhoto4');
                    if (image) {
                        imagesCount++;
                        imagesData.push({ label: `Image ${imagesCount}`, url: image });
                    }
                }
            }

            if(isMongoDBImageReadEnabled){
                // Check for logPhoto1
                if (logSheet.photo1 && logSheet.photo1.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo1.contentType};base64,${logSheet.photo1.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for logPhoto2
                if (logSheet.photo2 && logSheet.photo2.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo2.contentType};base64,${logSheet.photo2.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }

                // Check for logPhoto3
                if (logSheet.photo3 && logSheet.photo3.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo3.contentType};base64,${logSheet.photo3.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            
                // Check for logPhoto4
                if (logSheet.photo4 && logSheet.photo4.data) {
                    imagesCount += 1;
                    const imgData = `data:${logSheet.photo4.contentType};base64,${logSheet.photo4.data.toString('base64')}`;
                    imagesData.push({ label: ('Image ' + imagesCount), data: imgData });
                }
            }
            
            if (imagesData.length > 0) {
                hasImage = true;
                countOfImages += imagesData.length;
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Time Card ${index + 1}`, 10, startY);
                startY += 10; 
    
                imagesData.forEach((imageData, imgIndex) => {
                    const imgProps = doc.getImageProperties(isS3IntegrationEnabled ? imageData.url : imageData.data);
                    const imgWidth = 150;
                    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
                    doc.text(imageData.label, 10, startY);
                    doc.addImage((isS3IntegrationEnabled ? imageData.url : imageData.data), 'JPEG', 10, startY + 10, imgWidth, imgHeight);
    
                    startY += imgHeight + 20; 
    
                    if (startY + imgHeight > doc.internal.pageSize.getHeight() - 20) {
                        doc.addPage();
                        startY = 20;
                    }
                });
    
                startY += 20;
                if(startY === 40){
                    startY = 20;
                }
                lastPageHeight = startY;
            }
        };
    
        const pageHeight = doc.internal.pageSize.getHeight();
        const lastPageNumber = doc.internal.getNumberOfPages();
        if ((lastPageHeight < pageHeight - 20) && (lastPageNumber > countOfImages)) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }

        // Close the loading animation before saving the pdf
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });

        if(!hasImage){
            toastifyMessage("warning", "No images exist for the time cards");
            return;
        }
    
        const filename = "Time_card_photos.pdf";
        doc.save(filename);
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Sheet
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the time card?");
    const [deleteLogSheetId, setDeleteLogSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete time card using the Admin privilege
    const deleteThisLogSheet = (logSheetId) => {
        setValues({ ...values, loadingModal: true, error: ""});
        deletelogsheet(logSheetId, admin._id, token)
        .then(data=> {
            if(data.error){
                setValues({ ...values, error: data, loadingModal: ""})
                toastifyMessage("error", 'Error deleting the time card');
            }
            else{
                toastifyMessage("success", 'Time Card deleted successfully');

                // To close the modal & reload the values
                handleCancelDeletion();
                setValues({ ...values, error: "", loadingModal: "", logSheets: (logSheets.filter(sheet => (sheet._id !== logSheetId)))})
            }
        })
        .catch(err => {
            console.log('Error deleting the time card:', err);
            setValues({ ...values, error: "Error deleting the time card", loadingModal: "" });
            toastifyMessage("error", 'Error deleting the time card');
        });
    }

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Time Card Dashboard" description="View/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="log-dashboard">
                <div className="log-header">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>Unverified Time Cards</h2>
                    {dateFilterForm()}
                </div>

                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the time cards</h4>}
                    {isLoaded && !error && logSheets.length > 0 && (
                        <div id="log-sheet-table">
                            <LogSheetTableView logSheets={logSheets} editURL={`/admin/update/driver/logsheet/`} isAggregated={true} isAdmin={true} isDriverNameRequired={true} isBillingFeatureRequired={true} setDeleteLogSheetId={setDeleteLogSheetId} handleShowDeleteMessage={handleShowDeleteMessage} isReimbursementRequired={isReimbursementRequired} flipSheetStatus={flipSheetStatus}/>
                        </div>
                    )}
                </div>
            </div>
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisLogSheet} id={deleteLogSheetId} message={deleteMessage} loadingModal={loadingModal} />
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    );
}